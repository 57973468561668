<template>
  <v-container
    fluid
    class="px-4 pt-0"
  >
    <v-row class="py-3">
      <v-col
        cols="6"
        class="pb-0"
      >
        <v-tabs
          v-model="selectedTab"
          @change="changeTab"
        >
          <v-tab> Single QR </v-tab>
          <v-tab> Batch </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <inventory-qr
      v-if="selectedTab === 0"
      qr-type="moving-qr"
    />
    <moving-batch v-if="selectedTab === 1" />
  </v-container>
</template>

<script>
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import MovingBatch from '@/views/Movements/MovingBatch';
import { mapState } from 'vuex';

export default {
  components: {
    InventoryQr,
    MovingBatch,
  },
  data: () => ({
    selectedTab: 0,
  }),
  computed: {
    ...mapState({
      movementTab: 'movementTab',
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.selectedTab = this.movementTab;
    },
    changeTab() {
      // Keep current tab in local storage
      this.$store.commit('storeMovementTab', this.selectedTab);
    },
  },
};
</script>

<style lang="scss" scoped>
.date-filter {
  display: flex;
  flex: 0 1 10rem;
}
</style>

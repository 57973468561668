<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
        />
      </v-col>
      <v-col
        class="text-end mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="openForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Caution / Danger Level
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footerProps"
            :items-per-page="itemsPerPage"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                class="mt-0 mb-0"
                small
                :color="item.item && item.item.classes ? item.item.classes.color : 'white'"
                text-color="white"
              >
                {{ item.item && item.item.classes ? item.item.classes.name : '' }}
              </v-chip>
            </template>
            <template #[`item.item_full_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.item_full_name }}
              </text-highlight>
            </template>
            <template #[`item.length_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.length_name }}
              </text-highlight>
            </template>
            <template #[`item.color_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.color_code ? item.color_code : item.color_name }}
              </text-highlight>
            </template>
            <template #[`item.uom_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.uom_name }}
              </text-highlight>
            </template>
            <template #[`item.material_property_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.material_property_name }}
              </text-highlight>
            </template>
            <template #[`item.finishing_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.finishing_name }}
              </text-highlight>
            </template>
            <template #[`item.manufacturer_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.manufacturer_name }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <inventory-caution-danger-level-form
      ref="form"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import InventoryCautionDangerLevelForm from '@/components/forms/InventoryCautionDangerLevelForm';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    TextHighlight,
    InventoryCautionDangerLevelForm,
  },
  data: () => ({
    loading: true,
    items: [],
    footerProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    itemsPerPage: 20,
    inventoryClassId: null,
  }),
  computed:
  {
    headers() {
      const returnHeaders = [];

      if (this.businessRulesGetBoolean('Inventory Classes')) {
        returnHeaders.push({
          text: 'Class', value: 'inventory_class', width: '5%', sortable: false,
        });
      }
      if (this.businessRulesGetBoolean('Unique Item Code')) returnHeaders.push({ text: 'Item Code', value: 'item_code', width: '7%' });
      if (this.businessRulesGetBoolean('Unique Item Code')) returnHeaders.push({ text: 'SKU Code', value: 'id' });
      returnHeaders.push({ text: 'Item Name', value: 'item_full_name', width: '25%' });
      returnHeaders.push({ text: 'Color', value: 'color_name' });
      returnHeaders.push({ text: 'Length', value: 'length_name' });
      returnHeaders.push({ text: 'UOM', value: 'uom_name' });
      if (this.businessRulesGetBoolean('Manufacturer')) returnHeaders.push({ text: 'Manufacturer', value: 'manufacturer_name' });
      if (this.businessRulesGetBoolean('Material Property')) returnHeaders.push({ text: 'Material Property', value: 'material_property_name' });
      if (this.businessRulesGetBoolean('Finishing')) returnHeaders.push({ text: 'Finishing', value: 'finishing_name' });
      returnHeaders.push({ text: 'Caution Level', value: 'caution_level' });
      returnHeaders.push({ text: 'Danger Level', value: 'danger_level' });
      returnHeaders.push({
        text: 'Actions', value: 'actions', align: 'right', width: '15%',
      });

      return returnHeaders;
    },
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
    inventoryClassId() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.get();
    },

    async get() {
      this.loading = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.dangerLevel.read(readModel);
      if (!res) return;
      this.items = res;
      this.loading = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.dangerLevel.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openForm() {
      this.$refs.form.open();
    },

    openEditForm(id) {
      this.$refs.form.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

  },
};
</script>
<style scoped>
</style>

<template>
  <v-container
    class="pa-0 pt-5"
    fluid
  >
    <v-row>
      <v-col
        v-if="businessRulesGetBoolean('Inventory Classes')"
        cols="1"
        class="pt-0 pb-0"
      >
        <inventory-class-select-element
          v-model="filterInventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        v-if="isHistory"
        cols="1"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterHistoryTypeId"
          label="Type"
          :items="historyTypes"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="isHistory"
        cols="1"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterHistoryModuleId"
          label="Module"
          :items="historyModules"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-if="isHistory"
        cols="1"
        class="pt-0 pb-0"
      >
        <v-menu
          ref="menu"
          v-model="dateMenu"
          :return-value.sync="filterDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              label="Date"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menu.save(filterDate)"
            />
          </template>
          <v-date-picker
            v-model="filterDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDate = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        v-if="isHistory"
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterItemId"
          label="Item"
          :items="filterItems"
          :item-text="item => `${item.material_id} ${item.name}`"
          item-value="id"
          hide-details="true"
          clearable
          :loading="loadingInventoryItem"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-else
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterItemId"
          label="Item Name"
          :items="filterItems"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="businessRulesGetBoolean('Manufacturer')"
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterManufacturerId"
          label="Manufacturer"
          :items="filterManufacturers"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="businessRulesGetBoolean('Material Property')"
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterMaterialPropertyId"
          label="Material Property"
          :items="filterMaterialProperties"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="isHistory"
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterColorId"
          label="Colour"
          :items="filterColors"
          item-text="code"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
          <template #item="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        v-else
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterColorId"
          label="Colour"
          :items="filterColors"
          :item-text="item => item.code ? item.code : item.name ? item.name : ''"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              {{ item.code }}: {{ item.name }}
            </span>
          </template>
          <template #item="{ item }">
            <span>
              {{ item.code }}: {{ item.name }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterLengthId"
          label="Length"
          :items="filterLengths"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="businessRulesGetBoolean('Finishing')"
        cols="2"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterFinishingId"
          label="Finishing"
          :items="filterFinishings"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        cols="1"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterUomId"
          label="UOM"
          :items="filterUoms"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="isHistory"
        cols="1"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterLocationId"
          label="Location"
          :items="locations"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        v-if="isHistory"
        cols="1"
        class="pt-0 pb-0"
      >
        <v-autocomplete
          v-model="filterShelfId"
          label="Shelf"
          :items="shelves"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          no-data-text="Select a Location First"
        />
      </v-col>
    </v-row>
    <v-row v-if="isHistory">
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="transactions-table"
            disable-sort
            :headers="headers"
            :items="transactions"
            :footer-props="transactionsTableFooterProps"
            :items-per-page="transactionsPerPage"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #item="{ item }">
              <tr
                v-if="item"
                :style="{ backgroundColor: item.history && item.history.history_type ? hexToRgb(item.history.history_type.color) : 'white', }"
              >
                <td
                  v-if="item.history"
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-chip
                    class="mt-0 mb-0"
                    small
                    :color="item.history.to_item && item.history.to_item.classes ? item.history.to_item.classes.color : 'white'"
                    text-color="white"
                  >
                    {{ item.history.to_item && item.history.to_item.classes ? item.history.to_item.classes.name : '' }}
                  </v-chip>
                </td>
                <td
                  v-if="item.history"
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-chip
                    class="mt-0 mb-0"
                    small
                    text-color="white"
                    :color="item.history.history_module_type ? item.history.history_module_type.color : 'white'"
                  >
                    {{ item.history.history_module_type ? item.history.history_module_type.name : '' }}
                  </v-chip>
                </td>
                <td
                  v-if="item.history"
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-chip
                    class="mt-0 mb-0"
                    small
                    :color="item.history.history_type ? item.history.history_type.color : 'white'"
                    text-color="white"
                  >
                    {{ item.history.history_type ? item.history.history_type.name : '' }}
                  </v-chip>
                </td>
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.inventory_tag && item.history.to_inventory_tag && item.history.inventory_tag !== item.history.to_inventory_tag">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <v-tooltip
                            top
                            color="black"
                          >
                            <template #activator="{ on }">
                              <span
                                :style="{color: item.history.history_record_type_id === history_record_type_id_upc ? '#0288D1' : ''}"
                                class="font-weight-bold"
                                v-on="on"
                              ><text-highlight :queries="search">{{ item.history.inventory_tag ? item.history.inventory_tag : '' }}</text-highlight></span>
                            </template>
                            <span
                              v-if="item.history.history_record_type_id === history_record_type_id_upc"
                            > UPC Code</span>
                            <span v-else>QR Code</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center">
                          <v-tooltip
                            top
                            color="black"
                          >
                            <template #activator="{ on }">
                              <span
                                :style="{color: item.history.history_record_type_id === history_record_type_id_upc ? '#0288D1' : ''}"
                                class="font-weight-bold"
                                v-on="on"
                              ><text-highlight :queries="search">{{ item.history.to_inventory_tag ? item.history.to_inventory_tag : '' }}</text-highlight></span>
                            </template>
                            <span
                              v-if="item.history.history_record_type_id === history_record_type_id_upc"
                            > UPC Code</span>
                            <span v-else>QR Code</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on }">
                          <span
                            :style="{color: item.history.history_record_type_id === history_record_type_id_upc ? '#0288D1' : ''}"
                            class="font-weight-bold"
                            v-on="on"
                          ><text-highlight :queries="search">{{ item.history.inventory_tag ? item.history.inventory_tag : '' }}</text-highlight></span>
                        </template>
                        <span
                          v-if="item.history.history_record_type_id === history_record_type_id_upc"
                        > UPC Code</span>
                        <span v-else>QR Code</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </td>
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_inventory_item_id && item.history.to_inventory_item_id && item.history.from_inventory_item_id !== item.history.to_inventory_item_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_item ? `${item.history.from_item.material_id} ${item.history.from_item.name}` : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_item ? `${item.history.to_item.material_id} ${item.history.to_item.name}` : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_item ? `${item.history.to_item.material_id} ${item.history.to_item.name}` : '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>
                <!-- COLOR -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_color_id && item.history.to_color_id && item.history.from_color_id !== item.history.to_color_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_color ? item.history.from_color.code : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          v-if="item.history.from_color_id && item.history.to_color_id && item.history.from_color_id !== item.history.to_color_id"
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_color ? item.history.to_color.code: '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_color ? item.history.to_color.code: '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>

                <!-- LOCATION -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_location_id && item.history.to_location_id && item.history.from_location_id !== item.history.to_location_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_location ? item.history.from_location.name : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_location ? item.history.to_location.name : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_location ? item.history.to_location.name: '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>
                <!-- LENGTH -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_length_id && item.history.to_length_id && item.history.from_length_id !== item.history.to_length_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_length ? item.history.from_length.name : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_length ? item.history.to_length.name: '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_length ? item.history.to_length.name: '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>
                <!-- UOM -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_uom_id && item.history.to_uom_id && item.history.from_uom_id !== item.history.to_uom_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_uom ? item.history.from_uom.name : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_uom ? item.history.to_uom.name: '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_uom ? item.history.to_uom.name: '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>
                <!-- SHELF -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_inventory_location_id && item.history.to_inventory_location_id && item.history.from_inventory_location_id !== item.history.to_inventory_location_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_inventory_location ? item.history.from_inventory_location.name : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_inventory_location ? item.history.to_inventory_location.name: '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_inventory_location ? item.history.to_inventory_location.name: '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>

                <!-- JOB -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_job_id && item.history.to_job_id && item.history.from_job_id !== item.history.to_job_id">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_job ? item.history.from_job.code : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_job ? item.history.to_job.code: '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_job ? item.history.to_job.code: '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>
                <!-- PO# -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <span v-if="item.history"><text-highlight :queries="search">{{ item.history.to_po_number ? item.history.to_po_number : '' }}</text-highlight></span>
                </td>
                <!-- QUANTITY -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <span v-if="item.history && item.history.quantity_added">{{ item.history.quantity_added }}</span>
                  <span
                    v-else-if="item.history.quantity_removed"
                    style="color: red;"
                  >-{{ item.history.quantity_removed }}</span>
                  <span v-else>0</span>
                </td>
                <!-- QUANTITY ON HAND -->
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <v-row v-if="item.history">
                    <v-col v-if="item.history.from_quantity_on_hand !== null && item.history.to_quantity_on_hand !== null && item.history.from_quantity_on_hand !== item.history.to_quantity_on_hand">
                      <v-row>
                        <v-col
                          class="pa-0 pt-3 text-center"
                        >
                          <text-highlight :queries="search">
                            {{ item.history.from_quantity_on_hand !== null ? item.history.from_quantity_on_hand : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          class="pa-0 text-center"
                        >
                          <span
                            style="color: red;"
                            class="font-weight-bold"
                          >↓</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pa-0 pb-3 text-center font-weight-bold">
                          <text-highlight :queries="search">
                            {{ item.history.to_quantity_on_hand != null ? item.history.to_quantity_on_hand : '' }}
                          </text-highlight>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-else
                      class="text-center"
                    >
                      <text-highlight :queries="search">
                        {{ item.history.to_quantity_on_hand != null ? item.history.to_quantity_on_hand : '' }}
                      </text-highlight>
                    </v-col>
                  </v-row>
                </td>
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <a
                    v-if="item.history && item.history.to_supplier_label"
                    @click="getSupplierLabel(item.history.inventory_tag)"
                  >
                    <v-icon
                      small
                      color="primary"
                    >
                      fa-image
                    </v-icon>
                  </a>
                </td>
                <td
                  class="text-center pa-0"
                  cols="auto"
                >
                  <span v-if="item.history"><text-highlight :queries="search">{{ item.history.user ? `${item.history.user.first_name} ${item.history.user.last_name}` : '' }}</text-highlight></span>
                </td>
                <td class="text-right">
                  {{ item.created_at }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="transactions-table"
            :headers="transactionsHeadersBom"
            :items="transactions"
            :footer-props="transactionsTableFooterProps"
            :items-per-page="transactionsPerPage"
            :loading="loading"
            :search="search"
            dense
            fixed-header
            height="70vh"
          >
            <template #item="{ item }">
              <tr
                :class="{
                  green: item.is_rma || item.is_return,
                  red: !item.is_rma || !item.is_return,
                  'lighten-5': true,
                }"
              >
                <td>
                  {{ item.bom_rma }}
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.unique_code ? item.unique_code : '-' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.item_name ? item.item_name : '-' }}
                  </text-highlight>
                </td>
                <td>
                  {{ item.manufacturer_name ? item.manufacturer_name : '-' }}
                </td>
                <td>
                  {{
                    item.material_property_name
                      ? item.material_property_name
                      : '-'
                  }}
                </td>
                <td>{{ item.length_name ? item.length_name : '-' }}</td>
                <td>{{ item.color_code ? item.color_code : ': ' }} {{ item.color_name ? item.color_name : '' }}</td>
                <td>{{ item.finishing_name ? item.finishing_name : '-' }}</td>
                <td>{{ item.uom_name }}</td>
                <td>
                  <template v-if="!item.is_rma">
                    -
                  </template>{{ item.quantity }}
                </td>
                <td class="text-right">
                  {{ item.created_at }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import TransactionsApi from '../../api/TransactionsApi';
import FinishingsApi from '../../api/FinishingsApi';
import ManufacturersApi from '../../api/ManufacturersApi';
import UomsApi from '../../api/UomsApi';
import MaterialPropertiesApi from '../../api/MaterialPropertiesApi';
import LengthsApi from '../../api/LengthsApi';
import PdfHelper from '../../helpers/PdfHelper';
import ExcelHelper from '../../helpers/ExcelHelper';
import * as FormatHelpers from '../../helpers/FormatHelpers';

export default {
  name: 'Transactions',
  components: {
    InventoryItemShowImageForm,
  },
  props: {
    inventoryClassIdProp: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    transactions: [],
    filterItems: [],
    filterManufacturers: [],
    filterMaterialProperties: [],
    filterLengths: [],
    filterColors: [],
    filterFinishings: [],
    filterUoms: [],
    locations: [],
    shelves: [],
    historyTypes: [],
    historyModules: [],
    jobs: [],
    filterItemId: null,
    filterManufacturerId: null,
    filterMaterialPropertyId: null,
    filterLengthId: null,
    filterColorId: null,
    filterFinishingId: null,
    filterUomId: null,
    filterFrom: null,
    filterTo: null,
    filterClientId: null,
    filterHistoryTypeId: null,
    filterHistoryModuleId: null,
    filterLocationId: null,
    filterShelfId: null,
    filterJobId: null,
    filterInventoryClassId: null,
    filterDate: null,
    transactionsTableFooterProps: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    transactionsPerPage: 20,
    search: '',
    loading: true,
    pagination: {
      itemsPerPage: 20,
      page: 1,
    },
    total: 0,
    history_record_type_id_upc: 2,
    dateMenu: false,
    isHistory: false,
    topSpaceHeight: 290,
    windowHeight: 1000,
    loadingInventoryItem: false,
  }),
  computed: {
    filters() {
      return {
        itemId: this.filterItemId,
        manufacturerId: this.filterManufacturerId,
        materialPropertyId: this.filterMaterialPropertyId,
        lengthId: this.filterLengthId,
        colorId: this.filterColorId,
        finishingId: this.filterFinishingId,
        uomId: this.filterUomId,
        from: this.filterFrom,
        to: this.filterTo,
        clientId: this.filterClientId,
      };
    },

    headers() {
      const transactionsHeaders = [];
      transactionsHeaders.push({
        text: 'Class', align: 'center', value: 'class', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Module', value: 'module', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Type', align: 'center', value: 'type', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'QR Code', value: 'history.inventory_tag', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Item Name', value: 'item_name', align: 'center', class: 'pl-1',
      });

      transactionsHeaders.push({
        text: 'Colour', value: 'history.to_color.code, history.from_color.code', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Location', value: 'location', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Length', value: 'length_name', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'UOM', value: 'uom_name', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Shelf', value: 'shelf', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Job', value: 'job', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'PO#', value: 'po_number', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: '+/- Qty', value: 'qty', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Qty On Hand', value: 'qty', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'SL', value: 'po_number', align: 'center', width: '3%', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Created By', value: 'created_by', align: 'center', class: 'pl-1',
      });
      transactionsHeaders.push({
        text: 'Created At', value: 'created_at', align: 'right', filterable: false, class: 'pl-1',
      });
      return transactionsHeaders;
    },
    transactionsHeadersBom() {
      const transactionsHeaders = [];
      transactionsHeaders.push({ text: 'BOM/RMA Code', value: 'bom_rma', filterable: false });
      transactionsHeaders.push({ text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' });
      transactionsHeaders.push({ text: 'Item Name', value: 'item_name' });
      transactionsHeaders.push({ text: 'Manufacturer', value: 'manufacturer_name', filterable: false });
      transactionsHeaders.push({
        text: 'Material Property',
        value: 'material_property_name',
        filterable: false,
      });
      transactionsHeaders.push({ text: 'Length', value: 'length_name', filterable: false });
      transactionsHeaders.push({ text: 'Colour', value: 'color_name', filterable: false });
      transactionsHeaders.push({ text: 'Finishing', value: 'finishing_name', filterable: false });
      transactionsHeaders.push({ text: 'UOM', value: 'uom_name', filterable: false });
      transactionsHeaders.push({ text: 'Quantity', value: 'quantity', filterable: false });
      transactionsHeaders.push({
        text: 'Created At', value: 'created_at', align: 'right', filterable: false,
      });
      return transactionsHeaders;
    },
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      transactionsState: (state) => state.transactionsState,
    }),
  },
  watch: {
    filterItemId(value) {
      const state = this.transactionsState;
      state.inventory_item_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterLengthId(value) {
      const state = this.transactionsState;
      state.length_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterColorId(value) {
      const state = this.transactionsState;
      state.color_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterUomId(value) {
      const state = this.transactionsState;
      state.uom_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterHistoryTypeId(value) {
      const state = this.transactionsState;
      state.history_type_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterHistoryModuleId(value) {
      const state = this.transactionsState;
      state.history_module_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterLocationId(value) {
      const state = this.transactionsState;
      state.location_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
      this.getShelves();
    },
    filterShelfId(value) {
      const state = this.transactionsState;
      state.shelf_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterJobId(value) {
      const state = this.transactionsState;
      state.job_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterInventoryClassId(value) {
      const state = this.transactionsState;
      state.class_id = value;
      this.$store.commit('storeTransactionsState', state);
      this.get();
      this.getItems();
    },
    filterDate(val) {
      this.$refs.menu.save(val);
      this.get();
    },
    pagination() {
      const state = this.transactionsState;
      state.perPage = this.pagination.itemsPerPage;
      this.$store.commit('storeTransactionsState', state);
      this.get();
    },
    filterManufacturerId() {
      this.get();
    },
    filterMaterialPropertyId() {
      this.get();
    },
    filterFinishingId() {
      this.get();
    },
  },
  created() {
    this.initialize();
    if (this.transactionsState) {
      if (this.transactionsState.perPage) { this.transactionsPerPage = this.transactionsState.perPage; }
      if (this.transactionsState.inventory_item_id) { this.filterItemId = this.transactionsState.inventory_item_id; }
      if (this.transactionsState.length_id) { this.filterLengthId = this.transactionsState.length_id; }
      if (this.transactionsState.color_id) { this.filterColorId = this.transactionsState.color_id; }
      if (this.transactionsState.uom_id) { this.filterUomId = this.transactionsState.uom_id; }
      if (this.transactionsState.history_type_id) { this.filterHistoryTypeId = this.transactionsState.history_type_id; }
      if (this.transactionsState.history_module_id) { this.filterHistoryModuleId = this.transactionsState.history_module_id; }
      if (this.transactionsState.location_id) { this.filterLocationId = this.transactionsState.location_id; }
      if (this.transactionsState.shelf_id) { this.filterShelfId = this.transactionsState.shelf_id; }
      if (this.transactionsState.job_id) { this.filterJobId = this.transactionsState.job_id; }
      if (this.transactionsState.class_id) { this.filterInventoryClassId = this.transactionsState.class_id; }
    }
    this.isHistory = this.businessRulesGetBoolean('History');
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    if (this.inventoryClassIdProp) {
      this.filterInventoryClassId = this.inventoryClassIdProp;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async initialize() {
      this.get();
      this.getItems();
      this.getManufacturers();
      this.getUoms();
      this.getColors();
      this.getFinishings();
      this.getMaterialProperties();
      this.getLengths();
      this.getShelves();
      this.getLocations();
      this.getHistoryTypes();
      this.getHistoryModules();
    },
    async get() {
      const bomBRValue = this.businessRulesGetBoolean('Bom/rma code');
      if (bomBRValue != null && bomBRValue !== 0) {
        this.loading = true;
        this.transactions = await TransactionsApi.getList(this.filters);
        this.loading = false;
      } else {
        if (this.inventoryClassId === null) return;
        this.loading = true;
        const readModel = {
          pagination: this.pagination,
          filters:
          {
            inventory_class_id: this.filterInventoryClassId,
            history_type_id_filter: this.filterHistoryTypeId,
            history_module_id_filter: this.filterHistoryModuleId,
            item_id_filter: this.filterItemId,
            color_id_filter: this.filterColorId,
            length_id_filter: this.filterLengthId,
            location_id_filter: this.filterLocationId,
            shelf_id_filter: this.filterShelfId,
            job_id_filter: this.filterJobId,
            date_filter: this.filterDate,
          },
          search: this.search,
        };
        const res = await TransactionsApi.readTransactions(readModel);

        const cancelCode = 3001;
        if (res === cancelCode) return;
        this.loading = false;
        if (!res || !res.data || res.data.length === 0) {
          this.transactions = [];
          this.total = 0;
          return;
        }
        this.transactions = res.data;
        this.total = res.total;
      }
    },

    async getItems() {
      this.loadingInventoryItem = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.filterInventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.filterItems = [];
        return;
      }
      this.filterItems = res.data;
    },
    async getManufacturers() {
      this.filterManufacturers = await ManufacturersApi.getList();
    },
    async getUoms() {
      this.filterUoms = await UomsApi.getList();
    },
    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.filterColors = await this.$api.color.read(submitModel);
    },
    async getFinishings() {
      this.filterFinishings = await FinishingsApi.getList();
    },
    async getMaterialProperties() {
      this.filterMaterialProperties = await MaterialPropertiesApi.getList();
    },
    async getLengths() {
      this.filterLengths = await LengthsApi.getList();
    },
    async getShelves() {
      if (!this.filterLocationId) return;
      this.$nextTick(async () => {
        const readModel = {
          filters:
        {
          location_id: this.filterLocationId,
        },
        };
        this.shelves = await this.$api.inventoryLocation.read(readModel);
      });
    },
    async getLocations() {
      this.locations = await this.$api.location.getList();
    },
    async getHistoryTypes() {
      this.historyTypes = await this.$api.historyType.getList();
    },
    async getHistoryModules() {
      this.historyModules = await this.$api.historyModule.getList();
    },

    async getSupplierLabel(tag) {
      const supplierLabel = await this.$api.inventoryTag.get(`supplier-label/${tag}`);
      if (!supplierLabel) return;
      this.openItemShowImageForm(supplierLabel.supplier_label, supplierLabel.supplier_label_mime_type, tag);
    },

    updateSearch(search) {
      this.search = search;
      if (this.isHistory) { this.get(); }
    },
    updateFilters(filters) {
      this.filterFrom = filters.from;
      this.filterTo = filters.to;
      this.get();
    },
    generateExcel() {
      const excelData = this.transactions.map((el) => ({
        'BOM/RMA Code': el.bom_rma,
        Type: el.transaction_type,
        'Unique Item Code': el.unique_code,
        'Material Id': el.material_id,
        'Item Name': el.item_name,
        'Inventory Tag': el.inventory_tag,
        Manufacturer: el.manufacturer_name,
        'Material Property': el.material_property_name,
        Length: el.length_name,
        Color: el.color_name,
        Finishing: el.finishing_name,
        UOM: el.uom_name,
        Quantity: el.quantity,
        Missing: el.missing,
        'Created At': el.created_at,
      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Transactions');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Transactions_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      this.transactionsPerPage = -1;
      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#transactions-table > div > table',
        'landscape',
        'Transactions',
        'transactions',
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Transactions_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .1)`;
        return rgba;
      }
      return null;
    },
  },
};
</script>
<style scoped>

</style>

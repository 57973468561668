var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"submit-btn-text":_vm.item.id ? 'Update' : 'Create',"max-width":1000}},[_c('template',{slot:"header"},[_c('span',[_vm._v(_vm._s(_vm.item.id ? 'Update' : 'Create')+" Request "+_vm._s(_vm.item.id ? ("#" + (_vm.item.id)) : ''))])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("Request Delivery Date"),_c('RedAsterisk')],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-date-picker',{attrs:{"full-width":""},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1)],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('inventory-class-select-element',{attrs:{"is-add-all":false,"is-required":true},model:{value:(_vm.item.inventory_class_id),callback:function ($$v) {_vm.$set(_vm.item, "inventory_class_id", $$v)},expression:"item.inventory_class_id"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.jobs,"item-text":function (item) { return ((item.code) + " - " + (item.name)); },"item-value":"id","clearable":"","no-data-text":!_vm.item.inventory_class_id ? 'Select an Inventory Class first' : 'No data available',"rules":[function (v) { return !!v || 'Job is required'; }]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.sub_class)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.sub_class ? item.sub_class.color : 'white'}},[_vm._v(" "+_vm._s(item && item.sub_class ? item.sub_class.name : '')+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [(item.sub_class)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.sub_class ? item.sub_class.color : 'white'}},[_vm._v(" "+_vm._s(item && item.sub_class ? item.sub_class.name : '')+" ")]):_vm._e(),_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}}]),model:{value:(_vm.item.job_id),callback:function ($$v) {_vm.$set(_vm.item, "job_id", $$v)},expression:"item.job_id"}},[_c('template',{slot:"label"},[_vm._v(" Job"),_c('RedAsterisk')],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('span',{staticStyle:{"color":"black","font-size":"16px"}},[_vm._v("Total Quantity Required: "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.getJobTotalWindows()))])])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-o"},[_c('v-select',{attrs:{"label":"Status","items":_vm.truckTypes,"item-text":"name","item-value":"id","hide-details":"true","rules":[function (v) { return !!v || 'Truck Type is required'; }]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.item.truck_type_id),callback:function ($$v) {_vm.$set(_vm.item, "truck_type_id", $$v)},expression:"item.truck_type_id"}},[_c('template',{slot:"label"},[_vm._v(" Truck Type"),_c('RedAsterisk')],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.installers,"item-text":"full_name","item-value":"id","clearable":"","rules":[function (v) { return !!v || 'Installer is required'; }]},model:{value:(_vm.item.installer_id),callback:function ($$v) {_vm.$set(_vm.item, "installer_id", $$v)},expression:"item.installer_id"}},[_c('template',{slot:"label"},[_vm._v(" Installer"),_c('RedAsterisk')],1)],2)],1)],1),(!_vm.item.id)?_c('v-row',[_c('v-col',{staticClass:"pt-4"},[_c('v-textarea',{attrs:{"outlined":"","rows":"3","label":"Note"},model:{value:(_vm.item.comment),callback:function ($$v) {_vm.$set(_vm.item, "comment", $$v)},expression:"item.comment"}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{staticClass:"pt-4"},[_c('span',{staticStyle:{"color":"black","font-size":"16px"}},[_vm._v("Notes")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-virtual-scroll',{staticStyle:{"border":"1px solid rgba(0 ,0 ,0, 0.2)","border-radius":"4px"},attrs:{"items":_vm.item.notes,"height":"200","item-height":"75"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"wrap-text",domProps:{"textContent":_vm._s(item.text)}})],1),_c('v-list-item-action',[_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(item.created_at)}}),_c('v-list-item-action-text',{domProps:{"textContent":_vm._s(((item.user.first_name ? item.user.first_name : '') + " " + (item.user.last_name ? item.user.last_name : '')))}})],1)],1),_c('v-divider')]}}])})],1)],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        class="pa-6"
        style="background-color: #FFFDE7; border: 1.5px solid #E8E8DD;"
      >
        <v-row align="center">
          <v-col
            cols="auto"
            class="pr-0 pt-2"
          >
            <v-btn
              color="primary"
              icon
              @click="isOpen = !isOpen"
            >
              <v-icon v-if="isOpen">
                mdi-chevron-up
              </v-icon>
              <v-icon v-else>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <span style="color: #000000; font-size: 16px; font-weight: bold;">OTHER (SHIPPING, HANDLING, ETC.) ({{ items.length }})</span>
          </v-col>
          <v-col
            v-if="!isItemOpen"
            class="text-end"
          >
            <v-btn
              color="primary"
              outlined
              @click="open()"
            >
              <v-icon
                small
                class="mr-1"
              >
                fas fa-plus
              </v-icon>
              New Other Charge
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-show="isOpen">
          <v-col>
            <v-row
              v-if="isItemOpen || items.length > 0"
            >
              <v-col
                cols="12"
                class=""
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    item-key="temp_id"
                  >
                    <template #[`item.part`]="{ item }">
                      {{ item.item_code }} - {{ item.item_name }}
                    </template>
                    <template #[`item.price`]="{ item }">
                      ${{ parseFloat(item.price) }}
                    </template>
                    <template #[`item.subtotal`]="{ item }">
                      ${{ parseFloat(item.price * item.quantity).toFixed(2) }}
                    </template>
                    <template #[`item.tax`]="{ item }">
                      ${{ parseFloat(item.price * item.quantity * tax).toFixed(2) }}
                    </template>
                    <template #[`item.total`]="{ item }">
                      ${{ parseFloat((item.price * item.quantity) + (item.price * item.quantity * tax)).toFixed(2) }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openEdit(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="softDelete(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">${{ parseFloat(getSubtotal()).toFixed(2) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">${{ parseFloat(getTotalTax()).toFixed(2) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">${{ parseFloat(getTotal()).toFixed(2) }}</span>
                        </th>
                        <th />
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isItemOpen">
              <v-col>
                <v-row>
                  <v-col class="pb-0">
                    <zig-zag bg-color="yellow" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <span style="font-size: 16px; color: #000000; font-weight: bold;"> {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'NEW' }} OTHER CHARGE</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    md="6"
                    sm="8"
                    class="pb-0"
                  >
                    <v-row>
                      <v-col class="pb-0">
                        <span style="color: #000000;">Part Type</span><RedAsterisk />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <v-autocomplete
                          v-model="item.inventory_item_id"
                          :items="inventoryItems"
                          item-value="id"
                          solo
                          :item-text="
                            (item) => item.material_id + ': ' + item.name
                          "
                        >
                          <!-- eslint-disable-next-line vue/no-template-shadow -->
                          <template #item="{ item }">
                            <span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                          </template>
                          <!-- eslint-disable-next-line vue/no-template-shadow -->
                          <template #selection="{ item }">
                            <span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="3"
                    md="3"
                    sm="6"
                    class="pb-0"
                  >
                    <v-row>
                      <v-col class="pb-0">
                        <span style="color: #000000;">Quantity</span><RedAsterisk />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <v-text-field
                          v-model="item.quantity"
                          placeholder="Enter Quantity"
                          solo
                          suffix="pcs"
                          type="number"
                          :rules="[(v) => !!v || 'Quantity is required']"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="3"
                    md="3"
                    sm="6"
                    class="pb-0"
                  >
                    <v-row>
                      <v-col class="pb-0">
                        <span style="color: #000000;">Unit Price</span><RedAsterisk />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1">
                        <v-text-field
                          v-model="item.price"
                          placeholder="Enter Unit Price"
                          solo
                          prefix="$"
                          type="number"
                          :rules="[(v) => !!v || 'Unit Price is required']"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-row>
                      <v-col
                        class="pb-0"
                      >
                        <span style="color: #000000;">Note</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="pt-0"
                      >
                        <v-textarea
                          v-model="item.note"
                          outlined
                          rows="3"
                          style="background-color: #FFFFFF;"
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="auto"
                    class="pr-0"
                  >
                    <v-btn
                      color="normal"
                      class="mr-2"
                      @click="close()"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="pl-2"
                  >
                    <v-btn
                      color="primary"
                      @click="addItem"
                    >
                      {{ items.some(x => x.temp_id === item.temp_id) ? 'UPDATE' : 'CREATE' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ZigZag from '@/components/elements/ZigZag';

export default {
  components: {
    ZigZag,
  },
  props: {

  },
  data: () => ({
    items: [],
    inventoryItems: [],
    item: null,
    itemDto: {
      id: 0,
      inventory_item_id: null,
      price: null,
      quantity: null,
      note: null,
    },
    isOpen: false,
    isItemOpen: false,
    tax: 0.13,
    headers: [
      { text: 'Part Type', value: 'part', width: '15%' },
      { text: 'Note', value: 'note', width: '20%' },
      {
        text: 'Unit Price', value: 'price', width: '10%', align: 'end',
      },
      {
        text: 'Quantity', value: 'quantity', width: '10%', align: 'end',
      },
      {
        text: 'Subtotal', value: 'subtotal', width: '10%', align: 'end',
      },
      {
        text: 'Tax', value: 'tax', width: '10%', align: 'end',
      },
      {
        text: 'Total', value: 'total', width: '10%', align: 'end',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      },
    ],
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.init();
    this.item = { ...this.itemDto };
    this.getInventoryItems();
  },
  methods: {
    init() {

    },

    async get(inventoryPoId) {
      const readModel = {
        filters:
        {
          inventory_po_id: inventoryPoId,
        },
      };

      const res = await this.$api.inventoryPoExpense.read(readModel);
      if (!res) {
        this.items = [];
        return;
      }
      this.items = res;
    },

    async getInventoryItems() {
      const miscellaneousItemId = 1210;
      const readModel = {
        filters:
        {
          id: miscellaneousItemId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
      const firstIndex = 0;
      this.item.inventory_item_id = res.data[firstIndex].id;
    },

    addItem() {
      const item = this.items.find((x) => x.temp_id === this.item.temp_id);
      if (item) {
        const index = this.items.findIndex((x) => x.temp_id === this.item.temp_id);
        this.items.splice(index, 1, { ...this.item });
      } else {
        const inventoryItem = this.inventoryItems.find((x) => x.id === this.item.inventory_item_id);
        if (inventoryItem) {
          this.item.item_code = inventoryItem.material_id;
          this.item.item_name = inventoryItem.name;
        }
        this.items.push({ ...this.item });
      }

      this.close();
    },

    open() {
      this.getInventoryItems();
      this.isOpen = true;
      this.isItemOpen = true;
      this.item = { ...this.itemDto };
      this.item.temp_id = Date.now();
    },

    close() {
      this.isItemOpen = false;
    },

    openEdit(item) {
      this.item = { ...item };
      this.isItemOpen = true;
    },

    softDelete(item) {
      const index = this.items.findIndex((x) => x.temp_id === item.temp_id);
      this.items.splice(index, 1);
    },

    getSubtotal() {
      let subtotal = 0;
      this.items.forEach((item) => {
        subtotal += item.price * item.quantity;
      });
      return subtotal;
    },

    getTotal() {
      let total = 0;
      this.items.forEach((item) => {
        total += (item.price * item.quantity) + (item.price * item.quantity * this.tax);
      });
      return total;
    },

    getTotalTax() {
      let totalTax = 0;
      this.items.forEach((item) => {
        totalTax += item.price * item.quantity * this.tax;
      });
      return totalTax;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #FFFFFF !important;
}

::v-deep .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  color: #000000;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot
{
  border: 1px solid rgba(0,0,0,0.4) !important;
}
</style>

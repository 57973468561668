var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.clients,"item-text":"name","item-value":"id","clearable":"","hide-details":""},model:{value:(_vm.customerFilter),callback:function ($$v) {_vm.customerFilter=$$v},expression:"customerFilter"}},[_c('template',{slot:"label"},[_vm._v(" Customer ")])],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.projectManagers,"item-text":"full_name","item-value":"id","clearable":"","hide-details":""},model:{value:(_vm.projectManagerFilter),callback:function ($$v) {_vm.projectManagerFilter=$$v},expression:"projectManagerFilter"}},[_c('template',{slot:"label"},[_vm._v(" Project Manager ")])],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.estimators,"item-text":"full_name","item-value":"id","clearable":"","hide-details":""},model:{value:(_vm.estimatorFilter),callback:function ($$v) {_vm.estimatorFilter=$$v},expression:"estimatorFilter"}},[_c('template',{slot:"label"},[_vm._v(" Estimator ")])],2)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu",attrs:{"return-value":_vm.filterDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.filterDate=$event},"update:return-value":function($event){_vm.filterDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Created Date","readonly":"","hide-details":"","clearable":""},on:{"change":function($event){return _vm.$refs.menu.save(_vm.filterDate)}},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filterDate),callback:function ($$v) {_vm.filterDate=$$v},expression:"filterDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.filterDate = null}}},[_vm._v(" Clear ")])],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"text-end mt-4"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Scope Of Project ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":20,"search":_vm.search,"expanded":_vm.expandedItems,"loading":_vm.loading,"show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expandedItems=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:("" + (_vm.itemRowBackground(item)))},[_c('td',{staticClass:"pl-1 text-end pr-1 pl-1",style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important; border-left: 1.5px solid #000000 !important;': '')))},[(item.sub_items)?_c('v-icon',{on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.expandedItems.includes(item) ? 'mdi-chevron-down' : 'mdi-chevron-up')+" ")]):_vm._e()],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.group_id)+" ")])],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.revision_number)+" ")])],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.project_name)+" ")])],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.client_name_short)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.client_name))])])],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.address_simple ? item.address_simple : ''))])]}}],null,true)},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.address_full ? item.address_full : '')+" ")])],1)],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.project_manager_name)+" ")])],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.estimator_name)+" ")])],1),_c('td',{style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': '')))},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at)+" ")])],1),_c('td',{staticClass:"text-end pr-1",style:(("" + (_vm.expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important; border-right: 1.5px solid #000000 !important;': '')))},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v(" PDF ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditForm(item.id)}}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v(" Delete ")])],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.sub_items && item.sub_items.length > 0)?_c('td',{staticClass:"pl-0 pr-0",staticStyle:{"border-bottom":"1.5px solid #000000 !important","border-right":"1.5px solid #000000 !important","border-left":"1.5px solid #000000 !important"},attrs:{"colspan":10}},[_c('v-container',{style:(("background-color: " + (item.index % 2 === 0 ? 'white' : '#E8F5E9') + " !important;")),attrs:{"fluid":""}},_vm._l((item.sub_items),function(sub_item,index){return _c('v-row',{key:sub_item.id,style:(("border-bottom: " + (index === item.sub_items.length - 1 ? 'none' : '1px solid #c1c1c1') + ";")),attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-1 pb-1 pl-1",style:(("width: " + (headers[0].width) + " !important;")),attrs:{"cols":"auto"}}),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[1].width) + " !important;")),attrs:{"cols":"auto"}},[_c('text-highlight',{staticStyle:{"font-size":"14px"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(sub_item.id)+" ")])],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[2].width) + " !important;")),attrs:{"cols":"auto"}},[_c('text-highlight',{staticStyle:{"font-size":"14px"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(sub_item.revision_number)+" ")])],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[3].width) + " !important;")),attrs:{"cols":"auto"}},[_c('text-highlight',{staticStyle:{"font-weight":"bold","font-size":"14px"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(sub_item.project_name)+" ")])],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[4].width) + " !important;")),attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"font-size":"14px"}},on),[_vm._v(" "+_vm._s(sub_item.client_name_short)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(sub_item.client_name))])])],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[5].width) + " !important;")),attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"font-size":"14px"}},on),[_vm._v(_vm._s(sub_item.address_simple ? sub_item.address_simple : ''))])]}}],null,true)},[_c('text-highlight',{staticStyle:{"font-size":"14px"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(sub_item.address_full ? sub_item.address_full : '')+" ")])],1)],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[6].width) + " !important;")),attrs:{"cols":"auto"}},[_c('text-highlight',{staticStyle:{"font-size":"14px"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(sub_item.project_manager_name)+" ")])],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[7].width) + " !important;")),attrs:{"cols":"auto"}},[_c('text-highlight',{staticStyle:{"font-size":"14px"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(sub_item.estimator_name)+" ")])],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-4",style:(("width: " + (headers[8].width) + " !important; font-size: 14px;")),attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(sub_item.created_at)+" ")]),_c('v-col',{staticClass:"pt-1 pb-1 pl-4 pr-1 text-end",style:(("width: " + (headers[9].width) + " !important;")),attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.downloadPdf(sub_item)}}},[_vm._v(" PDF ")]),_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openViewForm(sub_item.id)}}},[_vm._v(" View ")])],1)],1)}),1)],1):_vm._e()]}}])})],1)],1)],1),_c('confirm-form',{ref:"confirmForm"}),_c('scope-of-project-form',{ref:"scopeOfProjectForm",on:{"success":_vm.get}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
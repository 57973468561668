<template>
  <v-row v-if="parent && ((parent.type_id === typeIdCheckbox && parent.value) || (parent.type_id !== typeIdCheckbox))">
    <v-col
      v-for="element, index in parent.children"
      :key="element.id"
      :cols="element.type_id === typeIdH2 ? 12 : 'auto'"
      :class="parent.type_id === typeIdCheckbox && parent.value ? `pt-0 ${index === 0 ? 'pl-12' : ''}` : ''"
    >
      <v-divider
        v-if="element.type_id === typeIdH2 && index !== 0"
        style="max-width: 800px;"
      />
      <v-row v-if="element.type_id === typeIdH2">
        <v-col
          class="pt-8"
        >
          <h2 style="color: #000000;">
            {{ parent.order }}.{{ element.order }}. {{ element.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row v-else-if="element.type_id === typeIdCheckbox">
        <v-col
          v-if="(parent.type_id !== typeIdCheckbox) && index !== 0 && element.value"
          cols="auto"
          class="pb-0 pt-0 mt-3"
        >
          <v-divider
            vertical
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="element.value"
            :value="element.id"
            hide-details
            :disabled="disabled"
            class="mt-0 mb-0"
          >
            <template slot="label">
              <span style="font-size: 16px; color: rgba(0,0,0,0.8)">{{ element.name }}</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row v-else-if="element.type_id === typeIdTextField">
        <v-col>
          <v-text-field
            v-model="element.value"
            :label="element.name"
            outlined
            hide-details
            type="number"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <v-row v-else-if="element.type_id === typeIdTextArea">
        <v-col>
          <v-textarea
            v-model="element.value"
            :label="element.name"
            outlined
            hide-details
            rows="2"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
      <template v-if="element.children">
        <recursive-component
          :parent="element"
          :disabled="disabled"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'RecursiveComponent',
  components: {
  },
  props: {
    parent: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    typeIdCheckbox: 1,
    typeIdH1: 2,
    typeIdH2: 3,
    typeIdTextField: 4,
    typeIdTextArea: 5,
  }),
};
</script>

<style scoped>

::v-deep .theme--light.v-divider {
    border-color: #000000 !important;
    border: 1px solid #000000 !important;
}
</style>

<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            fixed-header
            :loading="loading"
            dense
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <template #[`item.class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'grey'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.location`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.location_name }}
              </text-highlight>
            </template>
            <template #[`item.project`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.project_name }}
              </text-highlight>
            </template>
            <template #[`item.reference`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.reference }}
              </text-highlight>
            </template>
            <template #[`item.work_order`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.work_order }}
              </text-highlight>
            </template>
            <template #[`item.total_windows`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.total_windows ? item.total_windows : 0 }}
              </text-highlight>
            </template>
            <template #[`item.total_awnings`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.total_awnings ? item.total_awnings : 0 }}
              </text-highlight>
            </template>
            <template #[`item.total_pation_doors`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.total_pation_doors ? item.total_pation_doors : 0 }}
              </text-highlight>
            </template>
            <template #[`item.total_bypass_windows`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.total_bypass_windows ? item.total_bypass_windows : 0 }}
              </text-highlight>
            </template>
            <template #[`item.total_su_items`]="{ item }">
              <span @click="open(item, typeIdSUItems)">
                <text-highlight
                  :queries="search"
                  style="font-weight: bold; cursor: pointer; color: #1976d2;"
                >
                  {{ item.total_su_items ? item.total_su_items : 0 }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.total_sp_items`]="{ item }">
              <span @click="open(item, typeIdSPItems)">
                <text-highlight
                  :queries="search"
                  style="font-weight: bold; cursor: pointer; color: #1976d2;"
                >
                  {{ item.total_sp_items ? item.total_sp_items : 0 }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.total_box_panels`]="{ item }">
              <span @click="open(item, typeIdBoxPanels)">
                <text-highlight
                  :queries="search"
                  style="font-weight: bold; cursor: pointer; color: #1976d2;"
                >
                  {{ item.total_box_panels ? item.total_box_panels : 0 }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.total_metal_panels`]="{ item }">
              <span @click="open(item, typeIdMetalPanels)">
                <text-highlight
                  :queries="search"
                  style="font-weight: bold; cursor: pointer; color: #1976d2;"
                >
                  {{ item.total_metal_panels ? item.total_metal_panels : 0 }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.total_back_panels`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.total_back_panels ? item.total_back_panels : 0 }}
              </text-highlight>
            </template>
            <template #[`item.planned_ship_date`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.planned_ship_date }}
              </text-highlight>
            </template>
            <template #[`item.note`]="{ item }">
              <v-tooltip
                v-if="item.last_note"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="white-space: nowrap; color: rgb(255, 152, 0);"
                    v-on="on"
                  >
                    <v-btn
                      v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor) || roles.includes(userRoleIdGlassHandler)"
                      icon
                      small
                      @click="openNotes(item.production_schedule_id)"
                    >
                      <v-icon
                        small
                      >
                        mdi-eye
                      </v-icon>
                    </v-btn><text-highlight
                      :queries="search"
                    >{{ item.last_note_short }}</text-highlight></span>
                </template>
                <span>{{ item.last_note }}</span>
              </v-tooltip>
            </template>
            <template slot="body.append">
              <tr>
                <th />
                <th />
                <th />
                <th />
                <th class="">
                  <span>Totals: </span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_windows ? item.total_windows: 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_awnings ? item.total_awnings : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_pation_doors ? item.total_pation_doors : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_bypass_windows ? item.total_bypass_windows : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_su_items ? item.total_su_items : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_sp_items ? item.total_sp_items : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_box_panels ? item.total_box_panels : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_metal_panels ? item.total_metal_panels : 0, 10), 0) }}</span>
                </th>
                <th class="">
                  <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ items.reduce((total, item) => total + parseInt(item.total_back_panels ? item.total_back_panels : 0, 10), 0) }}</span>
                </th>
                <th />
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <window-breakdown-items-form
      ref="windowBreakdownItemsForm"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import WindowBreakdownItemsForm from '@/components/forms/productionSchedules/WindowBreakdownItemsForm';
import { mapState } from 'vuex';

export default {
  components: {
    WindowBreakdownItemsForm,
  },
  props: {
    filterLocationId: {
      type: Number,
      default: null,
    },
    filterJobSubClass: {
      type: Number,
      default: null,
    },
    filterJob: {
      type: Number,
      default: null,
    },
    inventoryClassId: {
      type: Number,
      default: null,
    },
    filterPlannedShipDate: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    items: [],
    headers: [
      { text: 'Class', value: 'class', width: '3%' },
      { text: 'Location', value: 'location', width: '7%' },
      { text: 'Project', value: 'project' },
      { text: 'Floor', value: 'reference', width: '8%' },
      { text: 'WO#', value: 'work_order', width: '3%' },
      { text: 'Win. Vert.', value: 'total_windows', width: '2%' },
      { text: 'Awn. Case.', value: 'total_awnings', width: '2%' },
      { text: 'Patio Door', value: 'total_pation_doors', width: '2%' },
      { text: 'Bypass Win.', value: 'total_bypass_windows', width: '2%' },
      { text: 'SU Glass', value: 'total_su_items', width: '2%' },
      { text: 'SP Glass', value: 'total_sp_items', width: '2%' },
      { text: 'Box Panels', value: 'total_box_panels', width: '2%' },
      { text: 'Metal Panels', value: 'total_metal_panels', width: '2%' },
      { text: 'Back Panels', value: 'total_back_panels', width: '2%' },
      { text: 'Plan Ship Date', value: 'planned_ship_date', width: '8%' },
      { text: 'Notes', value: 'note' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
    total: 0,
    typeIdSUItems: 1,
    typeIdSPItems: 2,
    typeIdBoxPanels: 3,
    typeIdMetalPanels: 4,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    userRoleIdProductionSupervisor: 18,
    userRoleIdSplanderPaintSupervisor: 21,
    userRoleIdProductionAdmin: 22,
    topSpaceHeight: 250,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 500,
      page: 1,
      sortBy: 'planned_ship_date',
      sortDesc: true,
    },
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
  },
  watch: {
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    filterLocationId() {
      this.get();
    },
    filterJobSubClass() {
      this.get();
    },
    filterJob() {
      this.get();
    },
    inventoryClassId() {
      this.get();
    },
    filterPlannedShipDate() {
      this.get();
    },
    status() {
      this.get();
    },
  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.init();
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          location_id: this.filterLocationId,
          job_sub_class_id: this.filterJobSubClass,
          inventory_class_id: this.inventoryClassId,
          job_id: this.filterJob,
          planned_ship_date: this.filterPlannedShipDate,
          status_id: this.status,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.productionSchedule.readWindowBreakdown(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.total = res.total;
      this.loading = false;
    },

    open(item, typeId) {
      this.$refs.windowBreakdownItemsForm.open(item, typeId);
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
      this.windowWidth = window.innerWidth;
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
    },

    openNotes(id) {
      this.$emit('open-notes', id);
    },

  },
};
</script>

<style scoped>
span
{
  font-size: 12px !important;
}
</style>

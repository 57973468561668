var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-state":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.statuses,"item-text":"name","item-value":"id","label":"Status"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.selectedStatusId),callback:function ($$v) {_vm.selectedStatusId=$$v},expression:"selectedStatusId"}})],1),_c('v-col',{staticClass:"text-end pb-0 mt-4"},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":""},on:{"click":function($event){return _vm.sync()}}},[_vm._v(" SYNC ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.purchaseOrders,"footer-props":_vm.footer,"items-per-page":-1,"search":_vm.search,"loading":_vm.loading,"dense":"","server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : 'All')+" ")])]}},{key:"item.po_number",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.po_number)+" ")])]}},{key:"item.job",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.job_name ? item.job_name : '-')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.status ? item.status.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.hide(item.is_active, item.po_number, item.inventory_class_id)}}},[_vm._v(" "+_vm._s(item.is_active ? 'HIDE' : 'SHOW')+" ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.open(item.po_number, item.inventory_class_id)}}},[_vm._v(" OPEN ")])]}}],null,true)})],1)],1)],1),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
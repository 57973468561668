var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[(_vm.businessRulesGetBoolean('Inventory Classes'))?_c('v-col',{staticClass:"pb-0 pt-4",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-state":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1):_vm._e(),_c('v-col',{staticClass:"pb-0 pt-4",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.statuses,"label":"Status","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : ''}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.statusId),callback:function ($$v) {_vm.statusId=$$v},expression:"statusId"}})],1),_c('v-col',{staticClass:"pb-0 pt-4 pl-6",attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-0 pt-4",attrs:{"cols":"auto"}},[_c('span',{staticStyle:{"font-size":"16px !important","color":"rgba(0,0,0,0.6)"}},[_vm._v("Hide")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":"","label":"Show Manual"},model:{value:(_vm.isShowManual),callback:function ($$v) {_vm.isShowManual=$$v},expression:"isShowManual"}})],1)],1)],1),(_vm.canEdit)?_c('v-col',{staticClass:"text-end pb-0 pt-7"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.openJobForm()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Job ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.jobsHeaders,"items":_vm.jobs,"footer-props":_vm.itemsTableFooterProps,"items-per-page":100,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.windowHeight,"server-items-length":_vm.total},on:{"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id ? item.id : '-')+" ")])],1)]}},{key:"item.inventory_class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.code ? item.code : '-')+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name ? item.name : '-')+" ")])]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.project_name ? item.project_name : '-')+" ")])]}},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.reference ? item.reference : '-')+" ")])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.client_name ? item.client_name : '-')+" ")])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.address ? item.address : '-')+" ")])]}},{key:"item.supervisor",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.supervisor ? item.supervisor : '-')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.is_active ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.is_active ? 'ACTIVE' : 'ARCHIVED')+" ")])]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by ? item.created_by : '-')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"purple","outlined":"","small":""},on:{"click":function($event){return _vm.toggle(item)}}},[_vm._v(" "+_vm._s(item.is_active === 1 ? 'ARCHIVE' : 'ACTIVATE')+" ")]),(_vm.canEdit)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEditJobForm(item.id)}}},[_vm._v(" EDIT ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")]),(_vm.enabled_bom_rma == 1)?_c('a',{staticClass:"mr-2 mt-2 mb-2 ml-1",on:{"click":function($event){return _vm.showBoms(item, 'bom')}}},[_vm._v(" BOMs ")]):_vm._e(),(_vm.enabled_bom_rma == 1)?_c('a',{staticClass:"mr-2 mt-2 mb-2",on:{"click":function($event){return _vm.showBoms(item, 'rma')}}},[_vm._v(" RMAs ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('job-form',{ref:"jobForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
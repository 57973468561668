<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobCodes"
          hide-details="true"
          item-value="code"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
          <template slot="label">
            Job
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterBatchId"
          label="Batch"
          item-value="id"
          item-text="batch_number"
          :items="batches"
          hide-details
          clearable
          :loading="loadingBatch"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterStatusId"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer />
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="mt-4 pr-0 pb-0"
      >
        <v-btn
          color="success"
          outlined
          @click="openFileInput"
        >
          IMPORT
        </v-btn>
      </v-col>
      <!-- <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="mt-4 pr-0 pb-0"
      >
        <v-btn
          color="purple"
          outlined
          @click="startSync()"
        >
          SYNC
        </v-btn>
      </v-col> -->
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="mt-4 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="openNewPo"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New PO
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0 pb-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="poHeaders"
            :items="poItems"
            :footer-props="footer"
            :items-per-page="100"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #item="{ item }">
              <tr>
                <td>
                  <v-btn
                    v-if="item.batch_id"
                    icon
                    :loading="item.loading"
                    @click="expandRow(item)"
                  >
                    <v-icon v-if="item.grouped_pos && expandedItems.includes(item.temp_id)">
                      mdi-chevron-up
                    </v-icon>
                    <v-icon v-else>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : '' }}
                  </v-chip>
                </td>
                <td>
                  <span>
                    <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                  </span>
                </td>
                <td>
                  <span class="font-weight-bold">
                    <text-highlight :queries="search">
                      {{ item.po_number ? item.po_number : '-' }}
                    </text-highlight>
                  </span>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ item.supplier_code ? item.supplier_code : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.total_glass ? item.total_glass : '0' }}
                  </text-highlight>
                </td>
                <td class="text-center">
                  <v-chip
                    small
                    :color="item.batch_id ? '#C0CA33' : '#26C6DA'"
                    text-color="white"
                  >
                    {{ item.batch_id ? 'BATCHED' : 'NOT BATCHED' }}
                  </v-chip>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.created_at ? item.created_at : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ item.created_by_full ? item.created_by_full : '' }}
                  </text-highlight>
                </td>
                <td class="text-center">
                  <!-- <v-chip
                    small
                    :color="item && item.status ? item.status.color : 'black'"
                    text-color="white"
                  >
                    {{ item && item.status ? item.status.name : '' }}
                  </v-chip> -->
                  <v-select
                    v-model="item.status_id"
                    :items="statuses"
                    hide-details
                    item-text="name"
                    item-value="id"
                    dense
                    solo
                    class="select-status"
                    :background-color="item.status.color"
                    style="max-width: 140px; border-radius: 20px; font-size: 12px; color: white !important;"
                    @change="changeStatus(item)"
                  >
                    <template #item="{ item }">
                      <v-chip
                        class="mt-1 mb-1"
                        small
                        style="cursor: pointer;"
                        :color="item ? item.color : 'white'"
                      >
                        <span style="white-space:nowrap; color: #FFFFFF">{{ item ? item.name : '' }}</span>
                      </v-chip>
                    </template>
                    <template #selection="{ item }">
                      <span style="color: white; white-space:nowrap;">{{ item.name }}</span>
                    </template>
                  </v-select>
                </td>
                <td>
                  <text-highlight
                    v-if="item.status_id == statusIdProduced"
                    :queries="search"
                  >
                    {{ item.produced_date ? convertDateFormat(item.produced_date) : '-' }}
                  </text-highlight>
                </td>
                <td
                  class="text-end"
                  style="white-space: nowrap;"
                >
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    :disabled="!item.is_manual || item.batch_id != null"
                    @click="openEdit(item)"
                  >
                    EDIT
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1 mr-1"
                    color="error"
                    outlined
                    small
                    :disabled="!item.is_manual || item.batch_id != null"
                    @click="softDelete(item)"
                  >
                    DELETE
                  </v-btn>
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mb-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        :disabled="item.is_manual == 1 && !item.file"
                        @click="item.is_manual == 1 ? downloadPdf(item.po_number) : runWebhook(item, 'pdf')"
                      >
                        <v-list-item-title :style="`color: ${item.is_manual == 0 || item.file ? 'green' : 'rgba(0,0,0,0.4)'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                :color="`${item.is_manual == 0 || item.file ? 'green' : 'rgba(0,0,0,0.4)'}`"
                              >
                                fas fa-file
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              PDF
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.is_manual == 1 && !item.file"
                        @click="item.is_manual == 1 ? downloadCsv(item.po_number) : runWebhook(item, 'csv')"
                      >
                        <v-list-item-title :style="`color: ${item.is_manual == 0 || item.file ? '#00ACC1' : 'rgba(0,0,0,0.4)'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 18px;"
                                :color="`${item.is_manual == 0 || item.file ? '#00ACC1' : 'rgba(0,0,0,0.4)'}`"
                              >
                                mdi-arrow-collapse-down
                              </v-icon>
                            </v-col>
                            <v-col class="pl-2 pt-4">
                              CSV
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.is_manual == 1 && !item.file"
                        @click="item.is_manual == 1 ? downloadTxt(item.po_number) : runWebhook(item, 'txt')"
                      >
                        <v-list-item-title :style="`color: ${item.is_manual == 0 || item.file ? '#5E35B1' : 'rgba(0,0,0,0.4)'}; font-weight: 600;`">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-1 pl-4"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 18px;"
                                :color="`${item.is_manual == 0 || item.file ? '#5E35B1' : 'rgba(0,0,0,0.4)'}`"
                              >
                                fas fa-file-alt
                              </v-icon>
                            </v-col>
                            <v-col class="pl-2 pt-4">
                              TXT
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="viewGlass(item)"
                      >
                        <v-list-item-title style="color: #00897B; font-weight: 600;">
                          <span style="font-size: 16px !important;">VIEW GLASS</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <!-- eslint-disable -->
              <tr
                v-for="(groupedItem) in item.grouped_pos"
                v-if="item.grouped_pos && expandedItems.includes(item.temp_id)"
                :key="groupedItem.batch_id"
                :style="`background-color: #E8F5E9 !important;`"
              >
              <!-- eslint-enable -->
                <td />
                <td class="text-center" />
                <td>
                  <span>
                    <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
                  </span>
                </td>
                <td>
                  <span class="font-weight-bold">
                    <text-highlight :queries="search">
                      {{ groupedItem.po_number ? groupedItem.po_number : '-' }}
                    </text-highlight>
                  </span>
                </td>

                <td>
                  <text-highlight :queries="search">
                    {{ groupedItem.supplier_code ? groupedItem.supplier_code : '' }}
                  </text-highlight>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ groupedItem.total_glass ? groupedItem.total_glass : '0' }}
                  </text-highlight>
                </td>
                <td class="text-center">
                  <span
                    class="font-weight-bold"
                  >
                    <text-highlight
                      style="font-size: 14px !important;"
                      :queries="search"
                    >

                      {{ groupedItem.batch_number ? groupedItem.batch_number : '' }}
                    </text-highlight></span>
                </td>
                <td>
                  <text-highlight :queries="search">
                    {{ groupedItem.created_at ? groupedItem.created_at : '' }}
                  </text-highlight>
                </td>
                <td />
                <td />
                <td class="text-end">
                  <v-btn
                    outlined
                    small
                    color="#FF8F00"
                    class="ml-1 mt-1 mb-1"
                    @click="viewBatch(item)"
                  >
                    VIEW BATCH
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-batch-form
      ref="batchForm"
      @success="success"
    />
    <glass-import-form
      ref="glassImportForm"
      @success="success"
    />
    <glass-p-o-form
      ref="glassPOForm"
      @success="success"
    />
    <v-file-input
      v-show="false"
      ref="fileInput"
      v-model="importFile"
      @change="importGlassItems"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import GlassBatchForm from '@/components/forms/glasses/GlassBatchForm';
import GlassImportForm from '@/components/forms/glasses/GlassImportForm';
import GlassPOForm from '@/components/forms/glasses/GlassPOForm';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import ExcelHelper from '@/helpers/ExcelHelper';

export default {
  components: {
    GlassBatchForm,
    GlassImportForm,
    GlassPOForm,
  },
  props: {
    jobCode: {
      type: Number,
      default: 0,
    },
    batchId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    poItems: [],
    jobCodes: [],
    batches: [],
    poHeaders: [
      {
        text: '', value: 'expand', align: 'center', width: '2%',
      },
      {
        text: 'Class', value: 'inventory_class', align: 'center', width: '5%',
      },
      { text: 'Job', value: 'job', width: '20%' },
      { text: 'PO#', value: 'po_number', width: '10%' },
      { text: 'Supplier', value: 'supplier_code', width: '10%' },
      { text: 'Qty', value: 'total_glass', width: '2%' },
      {
        text: 'Batch', value: 'batch_number', width: '10%', align: 'center',
      },
      { text: 'Created At', value: 'created_at', width: '10%' },
      { text: 'Created By', value: 'created_by_full', width: '8%' },
      {
        text: 'Status', value: 'status', width: '10%',
      },
      { text: 'Produced At', value: 'produced_date', width: '8%' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      },
    ],
    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    types: [],
    statuses: [],
    loading: false,
    inventoryClassId: null,
    filterJob: null,
    filterBatchId: 0,
    jobLoading: false,
    importFile: null,
    classIdVWDGlass: 7,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    topSpaceHeight: 220,
    windowHeight: 1000,
    expandedItems: [],
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    total: 0,
    filterStatusId: null,
    loadingJobs: false,
    loadingBatch: false,
    statusIdProduced: 3,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
  },
  watch: {
    inventoryClassId() {
      this.get();
    },
    filterJob() {
      this.get();
    },
    filterBatchId() {
      this.get();
    },
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
    filterStatusId() {
      this.get();
    },
  },
  created() {
    if (this.jobCode) this.filterJob = this.jobCode;
    if (this.batchId) this.filterBatchId = this.batchId;
    this.roles = this.user.roles.map((x) => x.id);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getJobs();
      this.getBatches();
      this.getStatuses();
    },
    async get() {
      if (this.inventoryClassId === null) return;
      this.poItems = [];
      this.total = 0;
      this.loading = true;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId, job_code: this.filterJob, batch_id: this.filterBatchId, status_id: this.filterStatusId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.glass.readPos(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.init();
      if (!res || !res.data || res.data.length === 0) {
        this.poItems = [];
        this.total = 0;
        return;
      }
      this.poItems = res.data;
      this.total = res.total;
      this.loading = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        this.loadingJobs = false;
        return;
      }
      this.jobCodes = res;
      this.loadingJobs = false;
    },

    async getBatches() {
      this.loadingBatch = true;
      this.batches = await this.$api.glassBatch.get('batch-numbers');
      this.loadingBatch = false;
    },

    async getStatuses() {
      this.statuses = await this.$api.glassPoStatus.get();
    },

    async removeGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this PO# from Batch?',
      });
      if (!confirm) return;
      const res = this.$api.glass.get(`remove-po/${item.batch_id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    viewGlass(item) {
      this.$router.push({
        name: 'GlassItems',
        params: {
          poNumber: item.po_number,
        },
      });
    },

    viewBatch(item) {
      this.$router.push({
        name: 'GlassBatchView',
        params: {
          poNumber: item.po_number,
        },
      });
    },

    openBatch() {
      this.$refs.batchForm.open();
    },

    openNewPo() {
      this.$refs.glassPOForm.open();
    },

    openEdit(item) {
      this.$refs.glassPOForm.open(item.inventory_class_id, item.po_number);
    },

    async startSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to do a Data Sync? This action will bring new Glass Items into ERP and can take up to 2 minutes!',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glass.get('sync');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
      this.getJobs();
    },

    createAutoBatch(item) {
      this.$refs.batchForm.open(item.inventory_class_id, item.po_number);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
      this.getJobs();
    },
    async importGlassItems() {
      if (!this.importFile) return;
      this.$refs.glassImportForm.open(this.importFile);
    },
    openFileInput() {
      this.importFile = null;
      this.$refs.fileInput.$refs.input.click();
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this PO#?',
      });
      if (!confirm) return;
      const res = await this.$api.glassPurchaseOrderItem.delete(`${item.po_number}/${item.inventory_class_id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    async downloadPdf(poNumber) {
      this.$bus.$emit('loading');
      const res = await this.$api.glass.download(poNumber, 1);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${poNumber}_${now}`;
      const file = new Blob([res], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
    },

    async downloadCsv(poNumber) {
      this.$bus.$emit('loading');
      const res = await this.$api.glass.download(poNumber, 1);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${poNumber}_${now}`;
      const file = new Blob([res], {
        type: 'text/csv',
      });
      saveAs(file, fileName);
    },

    async downloadTxt(poNumber) {
      this.$bus.$emit('loading');
      const res = await this.$api.glass.download(poNumber, 1);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      const file = new File([res], 'name');
      const result = await ExcelHelper.excelToCSV(file);
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${poNumber}_${now}`;
      const blobFile = new Blob([result], {
        type: 'text/plain',
      });
      saveAs(blobFile, fileName);
    },

    async runWebhook(item, type) {
      this.$bus.$emit('loading');
      const inventoryClassId4500 = 1;
      const inventoryClassId4800 = 2;
      const webhookData = {
        module_id: null,
        po_number: item.po_number,
        type,
      };

      if (type === 'pdf') {
        if (item.inventory_class_id === inventoryClassId4500) {
          webhookData.module_id = 22;
        } else if (item.inventory_class_id === inventoryClassId4800) {
          webhookData.module_id = 23;
        }
      } else if (type === 'csv' || type === 'txt') {
        if (item.inventory_class_id === inventoryClassId4500) {
          webhookData.module_id = 24;
        } else if (item.inventory_class_id === inventoryClassId4800) {
          webhookData.module_id = 25;
        }
      }

      const res = await this.$api.glassPurchaseOrderItem.store(webhookData, 'run-webhook-sync', { responseType: 'blob' });
      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.po_number}_${now}`;

      if (type === 'pdf') {
        const file = new Blob([res], {
          type: 'application/pdf',
        });
        saveAs(file, fileName);
      } else if (type === 'csv') {
        const file = new Blob([res], {
          type: 'application/vnd.ms-excel',
        });
        saveAs(file, fileName);
      } else if (type === 'txt') {
        const file = new File([res], 'name');
        const result = await ExcelHelper.excelToCSV(file);
        const blobFile = new Blob([result], {
          type: 'text/plain',
        });
        saveAs(blobFile, fileName);
      }
      this.$bus.$emit('loading');
    },

    async expandRow(item) {
      if (this.expandedItems.includes(item.temp_id)) {
        const index = this.expandedItems.findIndex((x) => x === item.temp_id);
        if (index === -1) return;
        this.expandedItems.splice(index, 1);
        return;
      }

      const index = this.poItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index === -1) return;
      this.poItems[index].loading = true;
      this.$set(this.poItems, index, this.poItems[index]);

      const submitModel = {
        filters: { inventory_class_id: item.inventory_class_id, po_number: item.po_number },
      };
      const res = await this.$api.glass.readPoGrouped(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.poItems[index].loading = false;
        this.$set(this.poItems, index, this.poItems[index]);
        return;
      }

      this.poItems[index].grouped_pos = res;
      this.poItems[index].loading = false;
      this.$set(this.poItems, index, this.poItems[index]);
      this.expandedItems.push(item.temp_id);
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    async changeStatus(item) {
      const res = await this.$api.glass.get(`po-status/${item.po_number}/${item.inventory_class_id}/${item.status_id}`);
      if (!res) return;
      this.get();
    },

    convertDateFormat(inputDate) {
      // Parse the input date
      const date = new Date(inputDate);

      // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      return formattedDate;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td
{
  padding: 0 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    padding-right: 4px;
    padding-left: 4px;
}

span
{
  font-size: 12px;
}
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
}
::v-deep .fa-caret-down:before {
    font-size: 14px;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0 3px 0;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0px !important;
}
::v-deep .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    width: 15px;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding-left: 10px;
    padding-right: 10px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
    width: 12px;
}
::v-deep .select-status .theme--light.v-icon {
    color: white;
    padding-right: 6px !important;
}
</style>

<template>
  <the-dialog-box
    ref="dialogBox"
    :is-submit-btn="false"
    cancel-btn-text="Close"
    :max-width="800"
  >
    <template slot="header">
      <span>Request #{{ request ? request.id : '' }}</span>
      <v-spacer />
      <v-btn
        v-if="isActions && request && request.status_id === statusIdApproved && (roles.includes(userRoleIdAssociate) ||roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover))"
        color="error"
        class="ml-1 mt-1 mb-1"
        outlined
        @click="deleteRequest()"
      >
        DELETE
      </v-btn>
      <v-btn
        v-if="isActions && request && request.status_id === statusIdApproved && (roles.includes(userRoleIdAssociate) ||roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdApprover))"
        color="primary"
        class="ml-1 mt-1 mb-1"
        outlined
        @click="openRequestEditForm()"
      >
        EDIT
      </v-btn>
      <v-btn
        v-if="isActions && request && request.status_id === statusIdApproved && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
        class="ml-1 mt-1 mb-1"
        color="success"
        outlined
        @click="openShipForm()"
      >
        SHIP
      </v-btn>
      <v-btn
        v-if="isActions && request && request.status_id === statusIdOnTruck && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
        class="ml-1 mt-1 mb-1"
        color="error"
        outlined
        @click="openCancelForm()"
      >
        CANCEL
      </v-btn>
      <v-btn
        v-if="isActions && request && request.status_id === statusIdOnTruck && (roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdShipper))"
        class="ml-1 mt-1 mb-1"
        color="success"
        outlined
        @click="openDeliverForm()"
      >
        DELIVER
      </v-btn>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pt-4">
          <span style="font-size: 17px; color: black;"> Submitted by <span style="font-weight: bold;">{{ request ? request.created_by_name : '' }}</span> , on {{ request ? request.created_at : '' }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Inventory Class: <v-chip
            small
            :color="request && request.class ? request.class.color : ''"
            text-color="white"
          >
            {{ request && request.class ? request.class.name : '' }}
          </v-chip></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Status: <v-chip
            small
            :color="request && request.status ? request.status.color : ''"
            text-color="white"
          >
            {{ request && request.status ? request.status.name : '' }}
          </v-chip></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Request Delivery Date: <span style="color: black; font-size: 18px;">{{ request ? request.date : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row v-if="request">
        <v-col>
          <span style="font-size: 16px;">Job: </span><span style="color: black; font-size: 16px;"> <span style="font-weight: bold;">{{ request.job_code ? `${request.job_code}` : '' }}</span> - {{ request.job_name ? request.job_name : '' }} <span style="color: #1976D2;">{{ request.reference ? `; ${request.reference}` : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Floor: <span style="color: black; font-size: 18px;">{{ request ? request.floor_number : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Total Quantity Required: <span style="color: black; font-size: 18px;">{{ request ? request.total_windows : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Total Moving Quantity: <span style="color: black; font-size: 18px;">{{ request ? request.quantity : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Truck Type: <v-chip
            small
            :color="request && request.truck_type ? request.truck_type.color : ''"
            text-color="white"
          >
            {{ request && request.truck_type ? request.truck_type.name : '' }}
          </v-chip></span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span style="font-size: 16px;">Installer: <span style="color: black; font-size: 18px;">{{ request ? request.installer_name : '' }}</span></span>
        </v-col>
      </v-row>
      <v-row v-if="request && request.trucks">
        <v-col class="pb-0">
          <span style="font-size: 16px;">Trucks:</span>
        </v-col>
      </v-row>
      <v-row v-if="request && request.trucks && request.trucks.length > 0">
        <v-col
          v-if="requestToTruckId && selectedTruck"
        >
          <v-row>
            <v-col
              class="pt-0 pb-0 pl-10"
              cols="3"
            >
              <span :style="`color: black; font-size: 16px;`">{{ selectedTruck.truck_name }}</span>
            </v-col>
            <v-col
              class="pt-0 pb-0 pr-1"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">{{ selectedTruck.start_time_formatted }} -</span>
            </v-col>
            <v-col
              class="pt-0 pb-0 pl-0"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">{{ selectedTruck.end_time_formatted }}</span>
            </v-col>
            <v-col
              v-if="(request.status.id === statusIdOnTruck || request.status.id === statusIdDelivered) && selectedTruck.quantity"
              class="pt-0 pb-0 pl-0"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">| Quantity: {{ selectedTruck ? selectedTruck.quantity : '' }} pcs.</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else>
          <v-row
            v-for="(truck, idx) in request.trucks"
            :key="truck.id"
          >
            <v-col
              class="pt-0 pb-0 pl-10"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">Truck {{ idx + 1 }}: </span>
            </v-col>
            <v-col
              class="pt-0 pb-0"
              cols="3"
            >
              <span :style="`color: black; font-size: 16px;`">{{ truck ? truck.truck_name : '' }}</span>
            </v-col>
            <v-col
              class="pt-0 pb-0 pr-1"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">{{ truck ? truck.start_time_formatted : '' }} -</span>
            </v-col>
            <v-col
              class="pt-0 pb-0 pl-0 pr-1"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">{{ truck ? truck.end_time_formatted : '' }}</span>
            </v-col>
            <v-col
              v-if="(request.status.id === statusIdOnTruck || request.status.id === statusIdDelivered) && truck.quantity"
              class="pt-0 pb-0 pl-0"
              cols="auto"
            >
              <span :style="`color: black; font-size: 16px;`">| Quantity: {{ truck ? truck.quantity : '' }} pcs.</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="pt-4">
          <span style="color: black; font-size: 16px;">Notes</span>
        </v-col>
      </v-row>
      <v-row v-if="request">
        <v-col>
          <v-row>
            <v-col class="pt-0">
              <v-virtual-scroll
                :items="request.notes"
                height="200"
                item-height="75"
                style="border: 1px solid rgba(0 ,0 ,0, 0.2); border-radius: 4px;"
              >
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #default="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="wrap-text"
                        v-text="item.text"
                      />
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text v-text="item.created_at" />
                      <v-list-item-action-text v-text="`${item.user.first_name ? item.user.first_name : ''} ${item.user.last_name ? item.user.last_name : ''}`" />
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />
                </template>
              </v-virtual-scroll>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <confirm-form ref="confirmForm" />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    isActions: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null,
    request: null,
    requestId: null,
    requestToTruckId: null,
    selectedTruck: null,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdRequester: 15,
    userRoleIdApprover: 14,
    userRoleIdShipper: 16,
    statusIdApproved: 3,
    statusIdOnTruck: 6,
    statusIdDelivered: 7,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
  },
  methods: {

    async getRequest(id) {
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.truckScheduleRequest.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || !res.data[firstIndex]) return;
      this.request = res.data[firstIndex];
      if (this.requestToTruckId) {
        this.selectedTruck = this.request.trucks.find((x) => x.id === this.requestToTruckId);
      }
    },

    open(requestId, requestToTruckId) {
      this.request = null;
      this.requestId = requestId;
      this.requestToTruckId = requestToTruckId;
      this.getRequest(requestId);
      this.$refs.dialogBox.open();
    },

    openRequestEditForm() {
      this.$refs.dialogBox.close();
      this.$emit('open-edit-form', this.request.id);
    },

    async deleteRequest() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.truckScheduleRequest.delete(this.request.id);
      if (!res) return;
      this.$bus.$emit('showSuccess');
      this.$refs.dialogBox.close();
      this.$emit('success');
    },

    close() {
      this.$refs.dialogBox.close();
    },

    openShipForm() {
      this.$refs.dialogBox.close();
      this.$emit('open-ship-form', this.request.id);
    },

    openCancelForm() {
      this.$refs.dialogBox.close();
      this.$emit('open-cancel-form', this.request.id);
    },

    openDeliverForm() {
      this.$emit('open-deliver-form', this.request.id);
    },
  },
};
</script>

<style scoped>
::v-deep .wrap-text {
  white-space: normal !important;
}
</style>

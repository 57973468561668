<template>
  <v-container fluid>
    <v-tabs
      v-if="dashboardUser"
      v-model="selectedTab"
    >
      <v-tab
        v-for="item in items"
        :key="item.name"
        :disabled="item.is_active == 0 || !dashboardUser.dashboard_ids.includes(item.id)"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <v-row>
      <v-col v-if="selectedTab == glassPurchseOrderIndex">
        <iframe
          src="
https://app.powerbi.com/reportEmbed?reportId=82cf7a66-d4c0-4e25-8efe-4887a6508de6&autoAuth=true&ctid=6606302d-c370-4a41-9a3f-da6a5e074877&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
          allowFullScreen="true"
          style="width: 100%; height: 85vh;"
        />
      </v-col>
      <v-col v-if="selectedTab == chrisleaInventoryIndex">
        <iframe
          style="width: 100%; height: 85vh;"
          src="
https://app.powerbi.com/reportEmbed?reportId=6c382cb1-d915-4b35-8997-b705dd105e6e&autoAuth=true&ctid=6606302d-c370-4a41-9a3f-da6a5e074877&navContentPaneEnabled=false&filterPaneEnabled=false"
          frameborder="0"
          allowFullScreen="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  beforeRouteEnter(to, from, next) {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // User has returned to the page, perform your action
        location.reload(); // Or any other action you want
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    next((vm) => {
      // Cleanup the event listener when leaving the component
      vm.$once('hook:beforeDestroy', () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      });
    });
  },
  props: {

  },
  data: () => ({
    selectedTab: null,
    items: [],
    dashboardUser: null,
    glassPurchseOrderIndex: 1,
    chrisleaInventoryIndex: 0,
  }),

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },

  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUsers();
    },

    async get() {
      this.items = await this.$api.dashboards.get();
      // eslint-disable-next-line no-return-assign, no-param-reassign
      this.items.forEach((item, index) => item.index = index);

      const firstItem = this.items.find((item) => item.is_active === 1 && this.dashboardUser.dashboard_ids.includes(item.id));
      if (firstItem) {
        this.selectedTab = firstItem.index;
      }
    },

    async getUsers() {
      const submitModel = {
        filters: {
          user_id: this.user.id,
        },
      };
      const res = await this.$api.dashboards.read(submitModel, null, 'read/users');
      if (!res) return;
      const firstIndex = 0;
      this.dashboardUser = res[firstIndex];
      this.get();
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="650px"
      :persistent="true"
    >
      <v-card>
        <v-card-title>
          <span style="font-weight: bold; font-size: 24px;">Confirmation</span>
        </v-card-title>
        <v-card-text>
          <span style="color: black; font-size: 18px;">{{ title }}</span>
        </v-card-text>
        <v-card-actions class="pr-2">
          <v-spacer />
          <v-btn
            color="normal"
            class="mr-2"
            large
            @click="cancel"
          >
            <span style="font-size: 18px;">{{ cancelBtn }}</span>
          </v-btn>
          <v-btn
            color="primary"
            large
            @click="confirm"
          >
            <span style="font-size: 18px;">{{ confirmBtn }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  data: () => ({
    title: '',
    message: '',
    confirmBtn: 'Submit',
    cancelBtn: 'Cancel',
    dialog: false,
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  mounted() {
    this.$bus.$on('confirm', this.open);
  },
  methods: {

    open(options = {}) {
      if (options.title) this.title = options.title;
      if (options.message) this.message = options.message;
      if (options.confirmBtn) this.confirmBtn = options.confirmBtn;
      if (options.cancelBtn) this.cancelBtn = options.cancelBtn;
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.dialog = false;
      this.resolvePromise(true);
    },

    cancel() {
      this.dialog = false;
      this.resolvePromise(false);
    },
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="500px"
      :persistent="false"
    >
      <v-form
        ref="form"
      >
        <v-card
          v-if="item"
          :color="getItemColor(item)"
          class="rounded"
          style="padding: 5px 7px 5px 7px;"
          tile
        >
          <v-card-title class="pt-3 pb-7">
            <v-row>
              <v-col>
                <v-row
                  align="center"
                >
                  <v-col class="pt-1 pb-1 pl-1">
                    <v-chip
                      small
                      color="#FFFFFF"
                      text-color="#01579B"
                    >
                      {{ getItemName(item) }}
                    </v-chip>
                  </v-col>
                  <v-spacer />
                  <v-col
                    class="pt-1 pb-1"
                    cols="auto"
                  >
                    <v-btn
                      v-if="!item.is_material_available && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor))"
                      class="elevation-0"
                      color="#FFFFFF"
                      small
                      @click="finishStage(item.id, materialAvailableId)"
                    >
                      <span style="color: #01579B;">FINISH MATERIAL</span>
                    </v-btn>
                    <v-btn
                      v-if=" (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))"
                      class="elevation-0"
                      color="#FFFFFF"
                      small
                      @click="toggle(item.id, item.status_id)"
                    >
                      <span style="color: #01579B;">COMPLETE</span>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="auto"
                    class="pr-6 pl-0"
                  >
                    <v-menu offset-y>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          elevation="0"
                          fab
                          color="#FFFFFF"
                          x-small
                          v-bind="attrs"
                          tile
                          style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                          v-on="on"
                        >
                          <v-icon style="color: #01579B;">
                            mdi-dots-vertical
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list
                        class="pa-3"
                      >
                        <v-list-item
                          v-if="item.status_id === statusIdInProgress && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))"
                          @click="openEdit(item)"
                        >
                          <v-list-item-title style="color: #1976d2; font-weight: 600;">
                            EDIT
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate)) && item.status_id == statusIdInProgress"
                          @click="softDelete(item.id)"
                        >
                          <v-list-item-title style="color: #ff5252; font-weight: 600;">
                            DELETE
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor) || roles.includes(userRoleIdGlassHandler)"
                          @click="openNotes(item.id)"
                        >
                          <v-list-item-title style="color: #ff9800; font-weight: 600;">
                            NOTES {{ item.notes ? `(${item.notes.length})` : '' }}
                          </v-list-item-title>
                        </v-list-item>
                        <!-- <v-list-item @click="openHistory(item)">
                          <v-list-item-title style="color: #FDD835; font-weight: 600;">
                            HISTORY
                          </v-list-item-title>
                        </v-list-item> -->
                        <v-list-item
                          v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate)) && item.status_id == statusIdInProgress"
                          @click="toggle(item.id, item.status_id, true)"
                        >
                          <v-list-item-title style="color: purple; font-weight: 600;">
                            ARCHIVE
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item
                          v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))"
                          @click="openTruckForm(item)"
                        >
                          <v-list-item-title
                            style="color: gray; font-weight: 600;"
                          >
                            <v-icon
                              small
                              class="mr-1 mb-1"
                              color="gray"
                            >
                              fas fa-plus
                            </v-icon> ADD TRUCK
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="((roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate))) && item.truck_schedule_total > 0"
                          @click="redirectTrucks(item.job_id)"
                        >
                          <v-list-item-title
                            style="color: gray; font-weight: 600;"
                          >
                            VIEW TRUCKS {{ `(${item.truck_schedule_total})` }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-divider />
                        <v-list-item
                          v-if="!item.is_splander_paint && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                          @click="finishStage(item.id, spandrelId)"
                        >
                          <v-list-item-title style="color: #F7014C; font-weight: bold;">
                            FINISH SPANDREL
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-else-if="item.is_splander_paint && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                          @click="cancelStage(item.id, spandrelId)"
                        >
                          <v-list-item-title style="color: #F7014C; font-weight: bold;">
                            CANCEL SPANDREL
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="!item.is_panels && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                          @click="finishStage(item.id, panelsId)"
                        >
                          <v-list-item-title style="color: #1874A7; font-weight: bold;">
                            FINISH PANELS
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-else-if="item.is_panels && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor))"
                          @click="cancelStage(item.id, panelsId)"
                        >
                          <v-list-item-title style="color: #1874A7; font-weight: bold;">
                            CANCEL PANELS
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-else-if="item.is_material_available && (roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdProductionSupervisor))"
                          @click="cancelStage(item.id, materialAvailableId)"
                        >
                          <v-list-item-title style="color: #F9A825; font-weight: 600;">
                            CANCEL MATERIAL
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider
                v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'"
                vertical
                inset
                style="max-height: 30px; margin-top: 15px;"
              />
              <v-col
                cols="auto"
                class="text-end pr-0"
              >
                <v-btn
                  icon
                  @click="dialog = false"
                >
                  <v-icon
                    color="#FFFFFF"
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text
            style="background-color: #FFFFFF; border-radius: 5px;"
          >
            <v-row
              style="min-height: 41px; border-bottom: 1px solid rgba(0,0,0,0.1);"
              align="center"
            >
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Class</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <v-chip
                  small
                  :color="item && item.class ? item.class.color : 'grey'"
                  text-color="white"
                >
                  {{ item && item.class ? item.class.name : '' }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row style="min-height: 41px; border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Project</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.project }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Floor</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.project_floor }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">#WO</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.wo_number }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Win.Vert</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.window_verticals }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Patio Doors</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.patio_doors }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Euro Doors</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.euro_doors }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Total Rel.Qty</span>
              </v-col>
              <v-col style="line-height: 16px;">
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.total_release_qty }}</span>
              </v-col>
            </v-row>
            <v-row
              style="border-bottom: 1px solid rgba(0,0,0,0.1);"
              align="center"
            >
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Stage</span>
              </v-col>
              <v-col
                cols="auto "
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  color="#FFD84D"
                  :style="`cursor: default; border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${item.is_splander_paint ? '#FFD84D' : ''}`"
                >
                  <span
                    v-if="item.is_splander_paint"
                    style="color: #000000;"
                  >S</span>
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  color="#FFD84D"
                  :style="`cursor: default; border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${item.is_panels ? '#FFD84D' : ''}`"
                >
                  <span
                    v-if="item.is_panels"
                    style="color: #000000;"
                  >P</span>
                </v-btn>
              </v-col>
              <v-col
                cols="auto "
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  color="#FFD84D"
                  :style="`cursor: default; border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid #FFD84D; background-color: ${item.is_material_available ? '#FFD84D' : ''}`"
                >
                  <span
                    v-if="item.is_material_available"
                    style="color: #000000;"
                  >M</span>
                </v-btn>
              </v-col>
              <v-col
                cols="auto "
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  :ripple="false"
                  color="#FFD84D"
                  :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_cut > 0 ? '#FFD84D' : item.is_assemble == 1 ? '#4CAF50' : ''}; background-color: ${item.total_cut > 0 ? '#FFD84D' : item.is_assemble == 1 ? '#4CAF50' : ''}`"
                  @click="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor)) && item.total_cut > 0 ? openFinishedGoodForm(item, 3): ''"
                >
                  <span
                    v-if="item.total_cut > 0"
                    style="color: #000000;"
                  >{{ item.total_cut }}</span>
                  <span
                    v-else-if="item.is_assemble == 1"
                    style="color: #FFFFFF;"
                  >C</span>
                  <span
                    v-else
                    style="color: #000000;"
                  />
                </v-btn>
              </v-col>
              <v-col
                cols="auto "
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  color="#FFD84D"
                  :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_assemble > 0 ? '#FFD84D' : item.is_t_and_b == 1 ? '#4CAF50' : ''}; background-color: ${item.total_assemble > 0 ? '#FFD84D' : item.is_t_and_b == 1 ? '#4CAF50' : ''}`"
                  @click="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor)) && item.total_assemble > 0 ? openFinishedGoodForm(item, 4): ''"
                >
                  <span
                    v-if="item.total_assemble > 0"
                    style="color: #000000;"
                  >{{ item.total_assemble }}</span>
                  <span
                    v-else-if="item.is_t_and_b == 1"
                    style="color: #FFFFFF;"
                  >A</span>
                  <span
                    v-else
                    style="color: #000000;"
                  />
                </v-btn>
              </v-col>
              <v-col
                cols="auto "
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  color="#FFD84D"
                  :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_t_and_b > 0 ? '#FFD84D' : item.is_glaze == 1 ? '#4CAF50' : ''}; background-color: ${item.total_t_and_b > 0 ? '#FFD84D' : item.is_glaze == 1 ? '#4CAF50' : ''}`"
                  @click="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor)) && item.total_t_and_b > 0 ? openFinishedGoodForm(item, 5): ''"
                >
                  <span
                    v-if="item.total_t_and_b > 0"
                    style="color: #000000;"
                  >{{ item.total_t_and_b }}</span>
                  <span
                    v-else-if="item.is_glaze == 1"
                    style="color: #FFFFFF;"
                  >T</span>
                  <span
                    v-else
                    style="color: #000000;"
                  />
                </v-btn>
              </v-col>
              <v-col
                cols="auto "
                style="line-height: 16px;"
                class="pr-1 pl-1"
              >
                <v-btn
                  elevation="0"
                  fab
                  outlined
                  tile
                  color="#FFD84D"
                  :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_glaze > 0 ? '#FFD84D' : item.is_prod_backlog == 1 ? '#4CAF50' : ''}; background-color: ${item.total_glaze > 0 ? '#FFD84D' : item.is_prod_backlog == 1 ? '#4CAF50' : ''}`"
                  @click="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdProductionAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdSplanderPaintSupervisor)) && item.total_glaze > 0 ? openFinishedGoodForm(item, 6): ''"
                >
                  <span
                    v-if="item.total_glaze > 0"
                    style="color: #000000;"
                  >{{ item.total_glaze }}</span>
                  <span
                    v-else-if="item.is_prod_backlog == 1"
                    style="color: #FFFFFF;"
                  >G</span>
                  <span
                    v-else
                    style="color: #000000;"
                  />
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              style="border-bottom: 1px solid rgba(0,0,0,0.1);"
              align="center"
            >
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Prod.Backlog</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <v-btn
                  v-if="item.production_backlog || item.production_backlog == 0"
                  elevation="0"
                  fab
                  outlined
                  tile
                  :style="`border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_prod_backlog != 0 ? '#EF5350' : '#4CAF50'}; background-color: ${item.total_prod_backlog != 0 ? '#EF5350' : '#4CAF50'} ;`"
                >
                  <span
                    style="color: #FFFFFF;"
                  >{{ item.production_backlog || item.production_backlog == 0 ? item.production_backlog : '' }}</span>
                </v-btn>
                <a
                  v-else
                >-</a>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Ship.Backlog</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <v-btn
                  v-if="item.shipping_backlog || item.shipping_backlog == 0"
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  :style="`cursor: default; border-radius: 4px; width: 25px; height: 25px; border: 1.5px solid ${item.total_shipping != 0 ? '#EF5350' : '#4CAF50'}; background-color: ${item.total_shipping != 0 ? '#EF5350' : '#4CAF50'};`"
                >
                  <span
                    style="color: #FFFFFF;"
                  >{{ item.shipping_backlog || item.shipping_backlog == 0 ? item.shipping_backlog : '' }}</span>
                </v-btn>
                <a
                  v-else
                >-</a>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"

                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">SP PO#</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <span v-if="item.sp_po_number">{{ item.sp_po_number }}</span>
                <span v-else>N/A</span>
              </v-col>
            </v-row>
            <v-row
              style="border-bottom: 1px solid rgba(0,0,0,0.1);"
              align="center"
            >
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Glass Note</span>
              </v-col>
              <v-col

                style="line-height: 16px;"
              >
                <v-btn
                  v-if="item.sp_note_status"
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  :color="item.sp_note_status.color"
                  :style="{
                    cursor: 'default',
                    borderRadius: '4px',
                    width: item.sp_note_status.name === 'N/A' || item.sp_note_status.name === 'PEN' || (item.sp_note_status.id === noteStatusIdShort && item.sp_note.length) > 2 ? 'auto' : '25px',
                    height: '25px',
                    padding: '0 7px',
                    border: `1.5px solid ${item.sp_note_status.color}`,
                    backgroundColor: `${item.sp_note_status.color}`
                  }"
                >
                  <span
                    v-if="item.sp_note_status.id === noteStatusIdShort"
                    style="color: #000000;"
                  >-{{ item.sp_note }}</span>
                  <span
                    v-else
                    style="color: #000000;"
                  >{{ item.sp_note_status.name }}</span>
                </v-btn>
                <v-btn
                  v-else
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  :style="{
                    cursor: 'default',
                    borderRadius: '4px',
                    width: 'auto',
                    height: '25px',
                    padding: '0 7px',
                    border: `1.5px solid #E0E0E0`,
                    backgroundColor: `#E0E0E0`
                  }"
                >
                  <span>N/A</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">S/U PO#</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <span v-if="item.su_po_number">{{ item.su_po_number }}</span>
                <span v-else>N/A</span>
              </v-col>
            </v-row>
            <v-row
              style="border-bottom: 1px solid rgba(0,0,0,0.1);"
              align="center"
            >
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Glass Note</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <v-btn
                  v-if="item.su_note_status"
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  :color="item.su_note_status.color"
                  :style="{
                    cursor: 'default',
                    borderRadius: '4px',
                    width: item.su_note_status.name === 'N/A' || item.su_note_status.name === 'PEN' || (item.su_note_status.id === noteStatusIdShort && item.su_note.length > 2) ? 'auto' : '25px',
                    height: '25px',
                    padding: '0 7px',
                    border: `1.5px solid ${item.su_note_status.color}`,
                    backgroundColor: `${item.su_note_status.color}`
                  }"
                >
                  <span
                    v-if="item.su_note_status.id === noteStatusIdShort"
                    style="color: #000000;"
                  >-{{ item.su_note }}</span>
                  <span
                    v-else
                    style="color: #000000;"
                  >{{ item.su_note_status.name }}</span>
                </v-btn>
                <v-btn
                  v-else
                  elevation="0"
                  fab
                  outlined
                  tile
                  readonly
                  :style="{
                    cursor: 'default',
                    borderRadius: '4px',
                    width: 'auto',
                    height: '25px',
                    padding: '0 7px',
                    border: `1.5px solid #E0E0E0`,
                    backgroundColor: `#E0E0E0`
                  }"
                >
                  <span>N/A</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Glass Req. By</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.glass_required_by_short }}</span>
              </v-col>
            </v-row>
            <v-row style="border-bottom: 1px solid rgba(0,0,0,0.1);">
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Panned Ship Date</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: #000000; line-height: 10px;">{{ item.planned_ship_date_short }}</span>
              </v-col>
            </v-row>
            <v-row
              v-if="item.last_note"
              align="center"
            >
              <v-col
                cols="4"
                style="line-height: 16px;"
              >
                <span style="font-size: 16px; color: rgba(0,0,0,0.8)">Note</span>
              </v-col>
              <v-col
                style="line-height: 16px;"
              >
                <span
                  style="color: rgb(255, 152, 0);"
                >
                  <v-btn
                    icon
                    small
                    @click="openNotes(item.id)"
                  >
                    <v-icon
                      small
                      style="color: rgb(255, 152, 0);"
                    >
                      mdi-eye
                    </v-icon>
                  </v-btn></span>
                <span>{{ item.last_note }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
    <confirm-form ref="confirmForm" />
    <production-schedule-form
      ref="productionForm"
      @success="get()"
    />
    <production-schedule-note-form
      ref="noteForm"
      @success="get()"
    />
    <production-schedule-history-form
      ref="historyForm"
      @success="get()"
    />

    <production-schedule-last-stage-form
      ref="lastStageForm"
      @success="get()"
    />
    <truck-schedule-approve-form
      ref="approveForm"
      @success="get()"
    />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductionScheduleForm from '@/components/forms/productionSchedules/ProductionScheduleForm';
import ProductionScheduleNoteForm from '@/components/forms/productionSchedules/ProductionScheduleNoteForm';
import ProductionScheduleHistoryForm from '@/components/forms/productionSchedules/ProductionScheduleHistoryForm';
import ProductionScheduleLastStageForm from '@/components/forms/productionSchedules/ProductionScheduleLastStageForm';
import TruckScheduleApproveForm from '@/components/forms/truckSchedules/TruckScheduleApproveForm';

export default {
  components: {
    ProductionScheduleForm,
    ProductionScheduleNoteForm,
    ProductionScheduleHistoryForm,
    ProductionScheduleLastStageForm,
    TruckScheduleApproveForm,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemId: null,
    dialog: false,
    statusIdInProgress: 1,
    statusIdComleted: 2,
    noteStatusIdShort: 2,
    materialAvailableId: 1,
    cutId: 2,
    assembleId: 3,
    spandrelId: 4,
    panelsId: 5,
    tAndBId: 6,
    glazedId: 7,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    userRoleIdProductionSupervisor: 18,
    userRoleIdSplanderPaintSupervisor: 21,
    userRoleIdProductionAdmin: 22,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
  },
  methods: {

    async get() {
      if (!this.itemId) return;
      const submitModel = {
        filters: {
          id: this.itemId,
        },
      };
      const res = await this.$api.productionSchedule.read(submitModel);
      const firstIndex = 0;
      if (!res || !res.data || res.data.length === 0) {
        return;
      }
      this.item = res.data[firstIndex];
    },

    async open(id) {
      this.itemId = id;
      await this.get();
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$emit('success');
    },

    async finishStage(itemId, status) {
      let title = '';

      switch (status) {
        case this.materialAvailableId:
          title = 'Are you sure that you want to FINISH MATERIAL AVAILABLE?';
          break;
        case this.cutId:
          title = 'Are you sure that you want to FINISH CUTTING?';
          break;
        case this.assembleId:
          title = 'Are you sure that you want to FINISH ASSEMBLING?';
          break;
        case this.spandrelId:
          title = 'Are you sure that you want to FINISH SPANDREL PAINT?';
          break;
        case this.panelsId:
          title = 'Are you sure that you want to FINISH PANELS?';
          break;
        case this.tAndBId:
          title = 'Are you sure that you want to FINISH T & B?';
          break;
        case this.glazedId:
          this.$refs.lastStageForm.open(itemId);
          return;
        default:
          break;
      }
      const confirm = await this.$refs.confirmForm.open({
        title,
      });
      if (!confirm) return;
      const submitModel = {
        id: itemId,
        status,
      };
      const res = await this.$api.productionSchedule.store(submitModel, 'stage');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    openEdit(item) {
      if (item.job_id) {
        this.$refs.productionForm.open(item.id);
      } else {
        this.$refs.manualForm.open(item.id);
      }
    },

    openNotes(id) {
      this.$refs.noteForm.open(id);
    },

    openHistory(id) {
      this.$refs.historyForm.open(id);
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.productionSchedule.delete(id);
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async cancelStage(itemId, status) {
      let title = '';

      switch (status) {
        case this.materialAvailableId:
          title = 'Are you sure that you want to CANCEL MATERIAL AVAILABLE?';
          break;
        case this.cutId:
          title = 'Are you sure that you want to CANCEL CUTTING?';
          break;
        case this.assembleId:
          title = 'Are you sure that you want to CANCEL ASSEMBLING?';
          break;
        case this.spandrelId:
          title = 'Are you sure that you want to CANCEL SPANDREL PAINT?';
          break;
        case this.panelsId:
          title = 'Are you sure that you want to CANCEL PANELS?';
          break;
        case this.tAndBId:
          title = 'Are you sure that you want to CANCEL T & B?';
          break;
        case this.glazedId:
          title = 'Are you sure that you want to CANCEL GLAZE?';
          break;
        default:
          break;
      }
      const confirm = await this.$refs.confirmForm.open({
        title,
      });
      if (!confirm) return;
      const submitModel = {
        id: itemId,
        status,
      };
      const res = await this.$api.productionSchedule.store(submitModel, 'cancel-stage');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async toggle(id, statusId) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${statusId === this.statusIdInProgress ? 'COMPLETE' : 'RESTORE'}?`,
      });
      if (!confirm) return;
      const res = await this.$api.productionSchedule.get(`toggle/${id}/${statusId}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.close();
    },

    getItemColor(item) {
      if (item.is_glaze === 1 && item.total_glaze === 0) {
        return '#0BDE79';
      } if (item.is_t_and_b === 1 && item.total_t_and_b === 0) {
        return '#F7E800';
      }
      if (item.is_assemble === 1 && item.total_assemble === 0) {
        return '#9C27B0';
      } if (item.is_cut === 1 && item.total_cut === 0) {
        return '#00BFA5';
      } if (item.is_material_available === 1 && item.total_material === 0) {
        return '#1870CB';
      }
      return '#F7A51C';
    },

    getItemName(item) {
      if (item.is_glaze === 1 && item.total_glaze === 0) {
        return 'COMPLETE';
      } if (item.is_t_and_b === 1 && item.total_t_and_b === 0) {
        return 'GLAZE';
      }
      if (item.is_assemble === 1 && item.total_assemble === 0) {
        return 'T & B';
      } if (item.is_cut === 1 && item.total_cut === 0) {
        return 'ASSEMBLY';
      } if (item.is_material_available === 1 && item.total_material === 0) {
        return 'CUT';
      }
      return 'MATERIAL';
    },

    getFontColor(item) {
      if (item.is_glaze === 1 && item.total_glaze === 0) {
        return '#000';
      } if (item.is_t_and_b === 1 && item.total_t_and_b === 0) {
        return '#000';
      }
      if (item.is_assemble === 1 && item.total_assemble === 0) {
        return '#000';
      } if (item.is_cut === 1 && item.total_cut === 0) {
        return '#FFF';
      } if (item.is_material_available === 1 && item.total_material === 0) {
        return '#FFF';
      }
      return '#FFF';
    },

    openTruckForm(item) {
      this.$refs.approveForm.openProductionSchedule(item);
    },
  },
};
</script>

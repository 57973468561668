<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      :persistent="true"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-text-field
                    v-model="color_code"
                  >
                    <template slot="label">
                      Colour Code
                    </template>
                  </v-text-field>
                  <v-text-field v-model="color_name">
                    <template slot="label">
                      Colour Name
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import ColorsApi from '../../api/ColorsApi';

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    id: null,
    color_name: '',
    color_code: '',
  }),
  watch: {
    // Auto select country based on region
    region(val) {
      if (val) {
        const region = this.regions.find((x) => x.id === val);
        this.country = this.countries.find(
          (x) => x.id === region.country_id,
        ).id;
      }
    },
  },
  created() {
  },
  methods: {

    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit ${item.name} colour `;
          this.btnSubmitName = 'Update';
          this.loadProfileToForm(item);
        } else {
          this.dialogTitle = 'New Colour';
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        const data = new FormData();

        if (this.color_name) {
          data.append('name', this.color_name);
          data.append('enabled', 1);
          data.append('deleted', 0);
        }

        if (this.id) {
          data.append('id', this.id);
        }

        if (this.color_code) {
          data.append('code', this.color_code);
        }

        data.append('created_by', this.$store.getters.user.id);

        const res = await ColorsApi.storeFormData(data);
        if (res) {
          this.$emit('profile-creation-success');
        } else {
          this.$emit('profile-creation-fail');
        }
        this.dialog = false;
      }
    },
    async loadProfileToForm(item) {
      await this.$refs.form.reset();
      this.id = item.id;
      this.color_name = item.name;
      this.color_code = item.code;
    },
    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.color_name = '';
      this.color_code = '';
    },
  },
};
</script>
<style scoped>
.profile-photo{
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
.profile-p{
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}</style>

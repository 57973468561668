<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
    >
      <v-card style="padding: 10px !important; border-radius: 0px !important;">
        <v-row>
          <v-col style="font-size: 20px;">
            Supply QR Code to Paint Purchase Order
          </v-col>
          <v-col
            cols="1"
            class="text-end"
          >
            <v-btn
              icon
              @click="closeForm"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          :style="`background-color: ${hexToRgb('#E0E0E0')}; margin:10px 0 0 0`"
        >
          <v-col
            cols="4"
            class="text-center"
          >
            <span style="font-size: 20px;">Item: <span class="font-weight-bold">{{ purchaseOrderItem ? purchaseOrderItem.item_name: '' }}</span></span>
          </v-col>
          <v-col
            cols="4"
            class="text-center"
          >
            <span style="font-size: 20px;">Note: <span class="font-weight-bold">{{ purchaseOrderItem && purchaseOrderItem.note ? purchaseOrderItem.note : 'MISSING' }}</span> </span>
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            <span style="font-size: 20px;">Length: <span class="font-weight-bold">{{ purchaseOrderItem ? purchaseOrderItem.length_name : '' }}</span> </span>
          </v-col>
          <v-col
            cols="2"
            class="text-center"
          >
            <span style="font-size: 20px;">UOM: <span class="font-weight-bold">{{ purchaseOrderItem ? purchaseOrderItem.uom_name : '' }}</span> </span>
          </v-col>
        </v-row>
        <v-row
          class="mt-0"
          :style="`background-color: ${hexToRgb('#E0E0E0')}; margin:10px 0 0 0`"
        >
          <v-col
            cols="4"
            class="text-center"
          >
            <span style="font-size: 20px;">Qty Required: <span class="font-weight-bold">{{ purchaseOrderItem ? purchaseOrderItem.quantity_required: '' }}</span></span>
          </v-col>
          <v-col
            cols="4"
            class="text-center"
          >
            <span
              style="font-size: 20px;"
            >Qty Not Fulfilled: <span
              style="color: red;"
              class="font-weight-bold"
            >{{ purchaseOrderItem ? purchaseOrderItem.quantity_remained : '' }}</span> </span>
          </v-col>
          <v-col
            cols="4"
            class="text-center"
          >
            <span style="font-size: 20px;">Qty Fulfilled: <span class="font-weight-bold">{{ purchaseOrderItem ? Math.abs(purchaseOrderItem.quantity_required - purchaseOrderItem.quantity_remained) : '' }}</span> </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-form
              ref="form"
            >
              <v-data-table
                class="elevation-1"
                :headers="tableHeaders"
                :items="inventoryTags"
                hide-default-footer
                :loading="loading"
                dense
              >
                <template
                  #[`item.class`]="{ item }"
                >
                  <v-chip
                    small
                    :color="item && item.item.classes ? item.item.classes.color : ''"
                    text-color="white"
                  >
                    {{ item && item.item.classes ? item.item.classes.name : '' }}
                  </v-chip>
                </template>
                <template #[`item.inventory_tag`]="{ item }">
                  <span style="font-weight: bold;">
                    {{ item.inventory_tag ? item.inventory_tag : '' }}</span>
                </template>
                <template #[`item.quantity`]="{ item }">
                  {{ item.quantity ? item.quantity_required : '' }}
                </template>
                <template #[`item.quantity_on_hand`]="{ item }">
                  {{ item ? item.quantity_on_hand : '' }}
                </template>
                <template #[`item.color_code`]="{ item }">
                  {{ item.color_code ? item.color_code : '' }}
                </template>
                <template #[`item.inventory_location`]="{ item }">
                  {{ item.inventory_location_name ? item.inventory_location_name : '-' }}
                </template>
                <template #[`item.location_code`]="{ item }">
                  {{ item.location_name ? item.location_name : '-' }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-row v-if="purchaseOrderItem">
                    <v-col
                      class="pt-0 pb-0 text-start pl-0"
                      style="margin-top: 13px;"
                    >
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="success"
                        outlined
                        @click="getInFull(item)"
                      >
                        GET IN FULL
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-0 pb-0"
                    >
                      <v-text-field
                        v-model="purchaseOrderItem.quantity"
                        style="max-width: 100px;"
                        type="number"
                      />
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-0 pb-0 pr-5"
                      style="margin-top: 13px;"
                    >
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        @click="submit(purchaseOrderItem,item)"
                      >
                        Add
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-end pt-7">
            <v-btn
              color="normal"
              class="mr-2"
              @click="closeForm"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>

export default {
  data: () => ({
    qrCode: null,
    poNumber: null,
    inventoryClassId: null,
    inventoryTags: [],
    purchaseOrderItem: null,
    purchaseOrderItemId: null,
    dialog: false,
    tableHeaders: [
      { text: 'Class', value: 'class' },
      { text: 'QR Code', value: 'inventory_tag' },
      { text: 'Color', value: 'color_code' },
      { text: 'Location', value: 'location_name' },
      { text: 'Shelf', value: 'inventory_location' },
      { text: 'Qty on Hand', value: 'quantity_on_hand' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '40%',
      },
    ],
    loading: false,
  }),
  methods: {

    async getPurchaseOrderItem() {
      const readModel = {
        filters:
        {
          id: this.purchaseOrderItemId,
        },
      };
      const res = await this.$api.supplyToPaint.readPurchaseOrderItem(readModel);

      const firstIndex = 0;
      this.purchaseOrderItem = res[firstIndex];
      if (parseInt(this.purchaseOrderItem.quantity_remained, 10) === 0) {
        this.$emit('success');
        this.dialog = false;
        return;
      }
      this.dialog = true;
      this.getInventoryTags();
    },

    async getInventoryTags() {
      this.loading = true;
      this.inventoryTags = [];
      const submitModel = {
        filters: {
          inventory_item_id: this.purchaseOrderItem.inventory_item_id,
          uom_id: this.purchaseOrderItem.uom_id,
          inventory_class_id: this.purchaseOrderItem.inventory_class_id,
          length_id: this.purchaseOrderItem.length_id,
        },
        sort: {
          order_by_first_sort: 'quantity_on_hand',
          sort_direction: 'asc',
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data.length === 0) {
        this.$bus.$emit('showError', 'There are not QR Codes for this Paint Purchase Order Item');
        this.dialog = false;
        return;
      }

      this.inventoryTags = res.data;
      this.loading = false;
    },

    getInFull(tag) {
      const submitItem = this.purchaseOrderItem;
      if (submitItem.quantity_remained !== this.purchaseOrderItem.quantity_required) {
        submitItem.quantity = this.purchaseOrderItem.quantity_remained;
      } else { submitItem.quantity = this.purchaseOrderItem.quantity_required; }
      this.submit(submitItem, tag);
    },

    async submit(item, tag) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to get material form this QR Code?',
      });
      if (!confirm) return;
      if (parseInt(item.quantity, 10) === 0) {
        this.$bus.$emit('showError', 'Quantity can\'t be 0');
        return;
      }
      if (parseInt(item.quantity, 10) > item.quantity_required) {
        this.$bus.$emit('showError', 'Quantity can\'t be greated than Quantity Required');
        return;
      }
      if (parseInt(item.quantity, 10) > tag.quantity_on_hand) {
        this.$bus.$emit('showError', 'Quantity can\'t be greated than Quantity On Hand');
        return;
      }

      const submitItem = {
        inventory_tag: tag.inventory_tag,
        paint_purchase_order_item_id: item.id,
        quantity: parseInt(item.quantity, 10),
        is_return: 0,
        inventory_class_id: item.inventory_class_id,
      };
      const res = await this.$api.supplyToPaint.store(submitItem);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getPurchaseOrderItem();
    },

    async open(id) {
      this.purchaseOrderItemId = id;
      this.purchaseOrderItem = null;
      this.inventoryTags = [];
      this.getPurchaseOrderItem();
    },

    closeForm() {
      this.$emit('success');
      this.dialog = false;
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .v-dialog--fullscreen {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
</style>

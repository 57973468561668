<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row align="center">
              <v-col>
                <h2>
                  SCOPE OF PROJECT {{ item.id ? `#${item.id}` : '' }}
                </h2>
              </v-col>
              <v-col
                class="text-end"
              >
                <v-btn
                  text
                  fab
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col style="max-width: 800px; padding-left: 30px; padding-top: 30px;">
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="item.client_id"
                      :items="clients"
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v || 'Client is required']"
                      autocomplete="preventAutocomplete"
                    >
                      <template slot="label">
                        Client<RedAsterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="item.address_id"
                      :items="addresses"
                      item-text="address"
                      item-value="id"
                      no-data-text="Select a Client first"
                      :rules="[(v) => !!v || 'Client Address is required']"
                      :loading="loadAddresses"
                      autocomplete="preventAutocomplete"
                    >
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #selection="{ item }">
                        <v-chip
                          v-if="item && item.type"
                          small
                          :color="item.type.color"
                          text-color="white"
                          class="mr-2"
                        >
                          {{ item.type.name }}
                        </v-chip>
                        <span>
                          {{ item.address }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line vue/no-template-shadow -->
                      <template #item="{ item }">
                        <v-chip
                          v-if="item && item.type"
                          small
                          :color="item.type.color"
                          text-color="white"
                          class="mr-2"
                        >
                          {{ item.type.name }}
                        </v-chip>
                        <span>
                          {{ item.address }}
                        </span>
                      </template>
                      <template slot="label">
                        Job Address<RedAsterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="pt-0"
                    cols="6"
                  >
                    <v-text-field
                      v-model="item.project_name"
                      :disabled="disabled"
                      :rules="[(v) => !!v || 'Project Name is required']"
                      autocomplete="preventAutocomplete"
                    >
                      <template slot="label">
                        Project Name<RedAsterisk />
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pt-0"
                  >
                    <v-text-field
                      v-model="item.contact"
                      :rules="[(v) => !!v || 'Contact is required']"
                      :disabled="disabled"
                      autocomplete="preventAutocomplete"
                    >
                      <template slot="label">
                        Contact<RedAsterisk />
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="6"
                    class="pt-0"
                  >
                    <v-autocomplete
                      v-model="item.project_manager_id"
                      :items="projectManagers"
                      item-text="full_name"
                      :disabled="disabled"
                      item-value="id"
                      :rules="[(v) => !!v || 'Project Manager is required']"
                      autocomplete="preventAutocomplete"
                    >
                      <template slot="label">
                        Project Manager<RedAsterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    class="pt-0"
                  >
                    <v-autocomplete
                      v-model="item.estimator_id"
                      :items="estimators"
                      item-text="full_name"
                      :disabled="disabled"
                      item-value="id"
                      :rules="[(v) => !!v || 'Estimator is required']"
                      autocomplete="preventAutocomplete"
                    >
                      <template slot="label">
                        Estimator<RedAsterisk />
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                v-for="element, index in elements"
                :key="element.id"
                cols="12"
                class="pt-10"
              >
                <v-row :style="`background-color: ${getHeadingBackgoundColor(index)}; max-width: 800px;`">
                  <v-col>
                    <h1>{{ element.order }}. {{ element.name }}</h1>
                  </v-col>
                </v-row>
                <template v-if="element.children">
                  <recursive-component
                    :parent="element"
                    :disabled="disabled"
                  />
                </template>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2 mb-3"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              :disabled="disabled"
              class="mr-3 mb-3"
              @click="submit"
            >
              {{ item.id ? 'Update' : 'Create' }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import RecursiveComponent from '@/components/elements/RecursiveComponent';

export default {
  components: {
    RecursiveComponent,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: null,
      client_id: null,
      project_name: null,
      address_id: null,
      contact: null,
      project_manager_id: null,
      estimator_id: null,
    },
    clients: [],
    projectManagers: [],
    estimators: [],
    elements: [],
    dialog: false,
    disabled: false,
    loadAddresses: false,
    addresses: [],

  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.client_id': function () {
      if (!this.item.client_id) return;
      this.getAddresses();
    },
  },
  created() {
    this.item = { ...this.itemDto };
    this.getClients();
    this.getProjectManagers();
    this.getEstimators();
  },
  methods: {

    async get(id) {
      if (!id) return;
      this.$bus.$emit('loading');
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.scopeOfProject.read(submitModel);
      if (!res || res.data.length < 1 || !res.data[0]) {
        this.$bus.$emit('loading');
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];
      this.$nextTick(async () => {
        this.$bus.$emit('loading');
      });
    },

    async getElements(id) {
      this.elements = await this.$api.scopeOfProjectElement.get(id);
    },

    async getClients() {
      const readModel = {
        filters:
        {
        },
      };
      this.clients = await this.$api.client.read(readModel);
    },

    async getAddresses() {
      this.loadAddresses = true;
      const readModel = {
        filters:
        {
          client_id: this.item.client_id,
        },
      };
      const res = await this.$api.address.read(readModel);
      this.addresses = res;
      if (res.length === 1) {
        this.item.client_address_id = res[0].id;
      }
      this.loadAddresses = false;
    },

    async getProjectManagers() {
      const roleIdProjectManager = 20;
      const submitModel = {
        filters: {
          role_id: roleIdProjectManager,
        },
      };
      this.projectManagers = await this.$api.user.read(submitModel);
    },

    async getEstimators() {
      const roleIdEstimator = 19;
      const submitModel = {
        filters: {
          role_id: roleIdEstimator,
        },
      };
      this.estimators = await this.$api.user.read(submitModel);
    },

    async submit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.$bus.$emit('loading');
      const submitData = {
        item: this.item,
        elements: this.elements,
      };

      let res;
      if (this.item.id) {
        res = await this.$api.scopeOfProject.update(submitData);
      } else {
        res = await this.$api.scopeOfProject.store(submitData);
      }

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      this.$bus.$emit('loading');
      this.dialog = false;
    },

    async open(id, isDisabled = false) {
      if (this.$refs.form) this.$refs.form.reset();
      if (id) {
        this.item.id = id;
        this.get(id);
        this.getElements(id);
      } else {
        this.item = { ...this.itemDto };
        this.getElements();
      }
      this.dialog = true;
      this.disabled = isDisabled;
    },

    getHeadingBackgoundColor(index) {
      switch (index) {
        case 0:
          return '#DCEDC8';
        case 1:
          return '#C8E6C9';
        case 2:
          return '#B2DFDB';
        case 3:
          return '#80CBC4';
        case 4:
          return '#4DB6AC';
        case 5:
          return '#26A69A';
        case 6:
          return '#009688';
        default:
          return '#009688';
      }
    },
  },
};
</script>

<style scoped>
.scope-checkbox {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

h1 {
    font-weight: bold;
    font-size: 2em;
    color: #000000;
}

h2 {
    font-weight: bold;
    font-size: 1.5em;
    color: #000000;
}
</style>

<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="1000"
    :submit-btn-text="itemId ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ itemId ? 'Update' : 'Create' }} Glass</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          v-if="!item.id"
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.glass_code_type_id"
            :items="glassCodeTypes"
            item-text="name"
            label="Glass Type"
            item-value="id"
            :rules="[(v) => !!v || 'Glass Type is required']"
            @change="generateGlassCode()"
          >
            <template slot="label">
              Glass Type<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          v-show="!item.id"
          cols="8"
          class="pb-0 mt-5"
        >
          <span
            style="font-size: 20px; color: black;"
          >Generated Glass Code: <span class="font-weight-bold">{{ item.row }}</span></span>
        </v-col>
        <v-col
          v-show="item.id"
          cols="12"
          class="pb-0 mt-5"
        >
          <span
            style="font-size: 20px; color: black;"
          >Glass Code: <span class="font-weight-bold">{{ item.row }}</span></span>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.part_number"
            :items="partNumbers"
            label="Part Number"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.awd_gas"
            :items="awdGlasses"
            item-text="name"
            label="AWD Gas"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.awd_spacer"
            :items="awdSpacers"
            item-text="name"
            label="AWD Spacer"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.cen_heat_treatment"
            :items="cenHeatTreatments"
            item-text="name"
            label="Cen Heat Treatment"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.center_glass"
            :items="centerGlasses"
            item-text="name"
            label="Center Glass"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.double_triple"
            :items="doubleTriples"
            item-text="name"
            label="Double/Triple"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.ext_heat_treatment"
            :items="extHeatTreatments"
            item-text="name"
            label="Ext Heat Treatment"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.exterior_glass"
            :items="exteriorGlasses"
            item-text="name"
            label="Exterior Glass"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.fritted"
            :items="fritteds"
            item-text="name"
            label="Fritted"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.glass_code"
            :items="glassCodes"
            item-text="name"
            label="Glass Code"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.int_heat_treatment"
            :items="intHeatTreatments"
            item-text="name"
            label="Int Heat Treatment"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.interior_glass"
            :items="interiorGlasses"
            item-text="name"
            label="Interior Glass"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.le_surface"
            :items="leSurfaces"
            item-text="name"
            label="LE Surface"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.middle_glass"
            :items="middleGlasses"
            item-text="name"
            label="Middle Glass"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.sgl_heat_treatment"
            :items="sglHeatTreatments"
            item-text="name"
            label="Sgl Heat Treatment"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.single_glass"
            :items="singleGlasses"
            item-text="name"
            label="Single Glass"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.spandrel_paint"
            :items="spandlerPaints"
            item-text="name"
            label="Spandrel Paint"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.stc"
            :items="stcs"
            label="STC"
            item-text="name"
            item-value="code"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <v-text-field
            v-model="item.vwd_code"
            label="VWD Code"
            @keydown.space.prevent
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      row: '',
      col: null,
      part_number: '',
      part_number_suffix: '0000',
      awd_gas: '',
      awd_spacer: '',
      cen_heat_treatment: '',
      center_glass: '',
      double_triple: '',
      ext_heat_treatment: '',
      exterior_glass: '',
      fritted: '',
      glass_code: '',
      int_heat_treatment: '',
      interior_glass: '',
      le_surface: '',
      middle_glass: '',
      sgl_heat_treatment: '',
      single_glass: '',
      spandrel_paint: '',
      stc: '',
      vwd_code: '',
      is_activated: 1,
      glass_code_type_id: null,
    },
    awdGlasses: [],
    awdSpacers: [],
    cenHeatTreatments: [],
    centerGlasses: [],
    doubleTriples: [],
    extHeatTreatments: [],
    exteriorGlasses: [],
    fritteds: [],
    glassCodes: [],
    intHeatTreatments: [],
    interiorGlasses: [],
    leSurfaces: [],
    middleGlasses: [],
    sglHeatTreatments: [],
    singleGlasses: [],
    spandlerPaints: [],
    stcs: [],
    glassCodeTypes: [],
    partNumbers: ['903-AWD', '900-AWD'],
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async init() {
      this.getSpecifications();
      this.getGlassTypes();
    },

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.glass.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getGlassTypes() {
      this.glassCodeTypes = await this.$api.glass.get('glass-code-types');
    },

    async getSpecifications() {
      const classIdAWDGlass = 1;
      const classIdAWDSpacer = 2;
      const classIdCenHeatTreatment = 3;
      const classIdCenterGlass = 4;
      const classIdDoubleTriple = 5;
      const classIdExtHeatTreatment = 6;
      const classIdExteriorGlass = 7;
      const classIdFritted = 8;
      const classIdGlassCode = 9;
      const classIdIntHeatTreatment = 10;
      const classIdInteriorGlass = 11;
      const classIdLESurface = 12;
      const classIdMiddleGlass = 13;
      const classIdSglHeatTreatment = 14;
      const classIdSingleGlass = 15;
      const classIdSpandlerPaint = 16;
      const classIdSTC = 17;

      this.awdGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdAWDGlass } });
      this.awdSpacers = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdAWDSpacer } });
      this.cenHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdCenHeatTreatment } });
      this.centerGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdCenterGlass } });
      this.doubleTriples = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdDoubleTriple } });
      this.extHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdExtHeatTreatment } });
      this.exteriorGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdExteriorGlass } });
      this.fritteds = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdFritted } });
      this.glassCodes = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdGlassCode } });
      this.intHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdIntHeatTreatment } });
      this.interiorGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdInteriorGlass } });
      this.leSurfaces = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdLESurface } });
      this.middleGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdMiddleGlass } });
      this.sglHeatTreatments = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSglHeatTreatment } });
      this.singleGlasses = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSingleGlass } });
      this.spandlerPaints = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSpandlerPaint } });
      this.stcs = await this.$api.glass.readSpecifications({ filters: { glass_class_id: classIdSTC } });
    },

    async submit() {
      this.item.created_by = this.$store.getters.user.id;
      this.item.is_activated = 1;
      let res;
      if (this.itemId) {
        res = await this.$api.glass.update(this.item);
      } else {
        res = await this.$api.glass.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    async generateGlassCode() {
      this.item.row = await this.$api.glass.get(`generate-g-code/${this.item.glass_code_type_id}`);
    },

    open(id) {
      this.generatedGlassCode = '';
      this.itemId = id;
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
      this.init();
    },
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="1500px"
    >
      <v-card tile>
        <v-card-title>
          Physical Count "{{ physicalCountList ? physicalCountList.name : '' }}, as of {{ physicalCountList ? physicalCountList.date : '' }}"

          <v-col class="text-right">
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="3"
              class="mb-3 pt-0"
            >
              <v-text-field
                v-model="search"
                append-icon="fas fa-search"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
          </v-row>
          <v-data-table
            class="elevation-1"
            :headers="profilesHeaders"
            :items="physicalCounts"
            :items-per-page="-1"
            :loading="loading"
            :search="search"
            hide-default-footer
            fixed-header
            height="65vh"
            dense
          >
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                small
                :color="item.item && item.item.classes ? item.item.classes.color : 'transparent'"
                text-color="white"
              >
                {{ item.item && item.item.classes ? item.item.classes.name : '' }}
              </v-chip>
            </template>
            <template #[`item.inventory_tag`]="{ item }">
              <text-highlight
                class="font-weight-bold"
                :queries="search"
              >
                {{ item ? item.inventory_tag : '' }}
              </text-highlight>
            </template>
            <template #[`item.item_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item ? item.item_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.length`]="{ item }">
              {{ item && item.length ? item.length.name : '' }}
            </template>
            <template #[`item.uom`]="{ item }">
              {{ item && item.uom ? item.uom.name : '' }}
            </template>
            <template #[`item.location`]="{ item }">
              {{ item && item.location ? item.location.name : '' }}
            </template>
            <template #[`item.color_code`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item ? item.color_code : '' }}
              </text-highlight>
            </template>
            <template #[`item.inventory_location`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item && item.inventory_location ? item.inventory_location.name : '' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ item ? item.quantity : '-' }}
            </template>
            <template #[`item.created_at`]="{ item }">
              {{ item ? item.created_at : '' }}
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item ? item.user_full_name : '' }}
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="8" />
            <v-col
              class="text-end mt-2"
              cols="2"
            >
              <h3
                style="color:black;"
                class="font-weight-bold"
              >
                Total Records: {{ totalRecords }}
              </h3>
            </v-col>
            <v-col
              class="text-end mt-2"
              cols="2"
            >
              <h3
                style="color:black;"
                class="font-weight-bold"
              >
                Total Quantity: {{ totalQuantity }}
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';

export default {
  components: {
    TextHighlight,
  },
  props:
  {
    physicalCountList: {
      type: Object,
      default: () => null,
    },
  },
  data: () => ({
    physicalCounts: [],
    loading: false,
    dialog: false,
    search: '',
    totalRecords: 0,
    totalQuantity: 0,

  }),
  computed: {
    profilesHeaders() {
      const headers = [];
      headers.push({ text: 'Class', value: 'inventory_class' });
      headers.push({ text: 'QR Code', value: 'inventory_tag' });
      headers.push({ text: 'Inventory Item', value: 'item_name' });
      headers.push({ text: 'Length', value: 'length' });
      headers.push({ text: 'UOM', value: 'uom' });
      headers.push({ text: 'Colour', value: 'color_code' });
      headers.push({ text: 'Location', value: 'location' });
      headers.push({ text: 'Shelf', value: 'inventory_location' });
      headers.push({ text: 'Quantity', value: 'quantity' });
      headers.push({ text: 'Created At', value: 'created_at' });
      headers.push({ text: 'Created By', value: 'created_by' });

      return headers;
    },
  },

  watch: {
    physicalCountList() {
      this.getPhysicalCounts();
    },
  },

  created() {
  },

  methods: {
    open() {
      this.dialog = true;
    },

    async getPhysicalCounts() {
      if (!this.physicalCountList) return;
      this.loading = true;
      this.physicalCounts = [];
      this.totalRecords = 0;
      this.totalQuantity = 0;

      this.physicalCounts = await this.$api.physicalCount.getList(`${this.physicalCountList.id}`);
      this.totalRecords = this.physicalCounts.length;
      this.physicalCounts.forEach((x) => { this.totalQuantity += x.quantity; });
      this.loading = false;
    },
  },

};
</script>

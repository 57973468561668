var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"max-width":1000,"submit-btn-text":!_vm.isNew ? 'Update' : 'Create'}},[_c('template',{slot:"header"},[_c('span',[_vm._v(_vm._s(!_vm.isNew ? 'Update' : 'Create')+" Inventory PO Item")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"md":"3","sm":"2"}},[_c('inventory-class-select-element',{attrs:{"is-add-all":false,"is-required":true,"is4500":_vm.inventoryClassId === _vm.class4500Id,"is4800":_vm.inventoryClassId === _vm.class4800Id,"is-railings":_vm.inventoryClassId === _vm.classRailingsId,"is-inst":_vm.inventoryClassId === _vm.classInstId,"is-glass":_vm.inventoryClassId === _vm.classGlassId,"is-commercial":_vm.inventoryClassId === _vm.classCommercialId,"is-vwd-glass":_vm.inventoryClassId === _vm.classVWDGlass,"is-panels":_vm.inventoryClassId === _vm.classPanelsId,"is-sid":_vm.inventoryClassId === _vm.classSidgId,"is-rail-part":_vm.inventoryClassId === _vm.classPanelPartsId,"is-parts":_vm.inventoryClassId === _vm.classPartsId},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"6","sm":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.inventoryItems,"item-value":"id","loading":_vm.loadingInventoryItem,"item-text":function (item) { return item.material_id + ': ' + item.name; },"rules":[function (v) { return !!v || 'Inventory Item is required'; }],"no-data-text":"Select a Class first"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.classes ? item.classes.color : 'white'}},[_vm._v(" "+_vm._s(item && item.classes ? item.classes.name : '')+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.material_id))]),_c('span',[_vm._v(_vm._s(': ' + item.name))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.classes ? item.classes.color : 'white'}},[_vm._v(" "+_vm._s(item && item.classes ? item.classes.name : '')+" ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.material_id))]),_c('span',[_vm._v(_vm._s(': ' + item.name))])]}}]),model:{value:(_vm.item.inventory_item_id),callback:function ($$v) {_vm.$set(_vm.item, "inventory_item_id", $$v)},expression:"item.inventory_item_id"}},[_c('template',{slot:"label"},[_vm._v(" Inventory Item"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.uoms,"auto-select-first":"","item-text":"name","item-value":"id","disabled":!_vm.item.inventory_item_id,"loading":_vm.loadingUom,"rules":[function (v) { return !!v || 'UOM is required'; }]},model:{value:(_vm.item.uom_id),callback:function ($$v) {_vm.$set(_vm.item, "uom_id", $$v)},expression:"item.uom_id"}},[_c('template',{slot:"label"},[_vm._v(" UOM"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.colors,"item-text":"code","item-value":"id","clearable":true,"loading":_vm.loadingColors},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+" ")])]}}]),model:{value:(_vm.item.color_id),callback:function ($$v) {_vm.$set(_vm.item, "color_id", $$v)},expression:"item.color_id"}},[_c('template',{slot:"label"},[_vm._v(" Color ")])],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.locations,"auto-select-first":"","item-text":"name","item-value":"id","loading":_vm.loadingLocations,"rules":[function (v) { return !!v || 'Ship To is required'; }]},model:{value:(_vm.item.location_id),callback:function ($$v) {_vm.$set(_vm.item, "location_id", $$v)},expression:"item.location_id"}},[_c('template',{slot:"label"},[_vm._v(" Ship To"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2","md":"2","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Quantity is required'; }],"type":"number"},model:{value:(_vm.item.quantity),callback:function ($$v) {_vm.$set(_vm.item, "quantity", $$v)},expression:"item.quantity"}},[_c('template',{slot:"label"},[_vm._v(" Quantity"),_c('RedAsterisk')],1)],2)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"4","md":"4","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[function (v) { return !!v || 'Unit Price is required'; }],"type":"number","suffix":("Ontario HST 13%: $" + ((_vm.item.price * 0.13)))},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", $$v)},expression:"item.price"}},[_c('template',{slot:"label"},[_vm._v(" Unit Price"),_c('RedAsterisk')],1)],2)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("Note")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-textarea',{attrs:{"outlined":"","rows":"3"},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable no-nested-ternary */
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="900px"
      :persistent="true"
    >
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-alert
              v-model="errorAlert"
              type="error"
            >
              {{ errorAlertText }}
            </v-alert>
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <p>Sheet Date</p>
                  <v-date-picker
                    v-model="picker"
                    full-width
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="pb-0"
                >
                  <inventory-class-select-element
                    v-model="inventoryClassId"
                    :is-add-all="false"
                    :is-required="true"
                  />
                  <v-autocomplete
                    v-model="selectedJobId"
                    :items="jobs"
                    :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
                    item-value="id"
                    :rules="[(v) => !!v || 'Job is required']"
                    required
                  >
                    <template #selection="{ item }">
                      <span>
                        <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                      </span>
                    </template>

                    <template #item="{ item }">
                      <span>
                        <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                      </span>
                    </template>
                    <template slot="label">
                      Job<RedAsterisk />
                    </template>
                  </v-autocomplete>

                  <!-- <v-text-field
                    v-model="release_floor"
                    label="Release/Floor"
                  /> -->
                  <v-autocomplete
                    v-if="inventoryClassId !== inventoryClassIdVWDGlass && inventoryClassId !== inventoryClassIdGlass"
                    v-model="ex_color"
                    :items="colors"
                    item-text="code"
                    item-value="id"
                    required
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template slot="label">
                      External colour
                    </template>
                  </v-autocomplete>
                  <v-autocomplete
                    v-if="inventoryClassId !== inventoryClassIdVWDGlass && inventoryClassId !== inventoryClassIdGlass"
                    v-model="in_color"
                    :items="colors"
                    item-text="code"
                    item-value="id"
                    required
                  >
                    <template #selection="{ item }">
                      <span>
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template #item="{ item }">
                      <span>
                        {{ item.code }}: {{ item.name }}
                      </span>
                    </template>
                    <template slot="label">
                      Internal colour
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submitForm"
            >
              {{ btnSubmitName }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import RedAsterisk from '@/components/RedAsterisk';
import { mapState } from 'vuex';

export default {
  components: {
    RedAsterisk,

  },
  data: () => ({
    dialog: false,
    dialogTitle: '',
    valid: false,
    errorAlert: false,
    errorAlertText: '',
    btnSubmitName: '',
    jobs: [],
    colors: [],
    id: null,
    supervisor: null,
    release_floor: null,
    job: null,
    selectedJobId: null,
    description: '',
    ex_color: '',
    in_color: '',
    ex: '',
    in: '',
    picker: new Date().toISOString().substr(0, 10),
    inventoryClassId: null,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
  }),
  computed: {
    ...mapState({
      user: 'user',
    }),
  },
  watch: {
    inventoryClassId() {
      this.fetchJobs();
    },
  },
  created() {

  },
  methods: {
    async init() {
      this.fetchColors();
      this.fetchJobs();
    },

    async fetchJobs() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      this.jobs = await this.$api.job.read(readModel);
    },

    async fetchColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },

    async getLatestSupplyRecord() {
      if (!this.user) return;
      const latestTrue = 1;
      // Add new filters to readModel
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
          latest: latestTrue,
          user_id: this.user.id,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      const res = await this.$api.supplyToLine.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;

      // Get latest record with index 0 (read returns an array with only one record)
      const latestSupplyRecord = res[firstIndex];

      if (!latestSupplyRecord || !latestSupplyRecord.job_id) return;
      // Find latest used job from latest Supply record
      const defaultJobRecord = this.jobs.find((x) => x.id === latestSupplyRecord.job_id);
      if (!defaultJobRecord) return;
      this.selectedJobId = defaultJobRecord.id;
    },

    async submitForm() {
      if (!this.selectedJobId) return;
      if (this.$refs.form.validate()) {
        if (this.ex_color) {
          this.ex = `${this.ex_color.color_name} (${this.ex_color.color_code})`;
        } else {
          this.ex = '-';
        }
        if (this.in_color) {
          this.in = `${this.in_color.color_name} (${this.in_color.color_code})`;
        } else {
          this.in = '-';
        }
        this.userId = this.$store.getters.user.id;
        const data = {
          id: this.id,
          job_id: this.selectedJobId,
          // eslint-disable-next-line no-nested-ternary
          ex_color: this.ex_color
            ? this.ex_color.id
              ? this.ex_color.id
              : this.ex_color
            : null,
          // eslint-disable-next-line no-nested-ternary
          in_color: this.in_color
            ? this.in_color.id
              ? this.in_color.id
              : this.in_color
            : null,
          sheet_date: this.picker,
          created_by: this.userId,
          release_floor: this.release_floor,
          inventory_class_id: this.inventoryClassId,

        };
        const res = await this.$api.supplyToLine.store(data);
        if (res) {
          this.$emit('consumption-creation-success');
        } else {
          this.$emit('consumption-creation-fail');
        }
        this.dialog = false;
      }
    },

    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        // Clear form validation
        if (item) {
          this.dialogTitle = `Edit MATERIAL SUPPLY / RETURN SHEET #${item.id}`;
          this.btnSubmitName = 'Update';
          this.loadConsumptionToForm(item);
        } else {
          this.dialogTitle = 'MATERIAL SUPPLY / RETURN SHEET';
          this.btnSubmitName = 'Create';
          this.clearForm();
        }
      });
      this.init();
    },

    async classSelectChanged(value) {
      this.readModel = {
        filters:
        {
          inventory_class_id: value,
        },
        sort: {
          order_by_first: 'id',
          direction_sort: 0,
        },
      };
      await this.fetchJobs();
    },

    async loadConsumptionToForm(item) {
      await this.$refs.form.reset();
      this.id = item.id;
      this.selectedJobId = item.job_id;
      this.supervisor = item.supervisor_id;
      this.ex_color = item.ex_color;
      this.in_color = item.in_color;
      this.picker = item.sheet_date;
      this.release_floor = item.release_floor;
      this.inventoryClassId = item.class ? item.class.id : null;
    },

    async clearForm() {
      await this.$refs.form.reset();
      this.id = null;
      this.selectedJobId = null;
      this.supervisor = null;
      this.ex_color = null;
      this.in_color = null;
    },
  },
};
</script>

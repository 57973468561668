<template>
  <v-container fluid>
    <v-row>
      <v-col class="">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="500"
            :loading="loading"
            fixed-header
            :height="windowHeight"
            dense
          >
            <template #[`item.row`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.row }}
              </text-highlight>
            </template>
            <template #[`item.glass_description`]="{ item }">
              <span v-if="item.exterior_glass"><span style="font-weight: bold">E: </span>
                <text-highlight
                  :queries="searchArray"
                >
                  {{ item.exterior_glass }} {{ item.ext_heat_treatment }}
                </text-highlight>
              </span>
              <span v-if="item.center_glass"><span v-if="item.exterior_glass">/</span><span style="font-weight: bold"> C: </span>
                <text-highlight
                  :queries="searchArray"
                >
                  {{ item.center_glass }}
                </text-highlight>
              </span>
              <span v-if="item.interior_glass"><span v-if="item.exterior_glass || item.center_glass">/</span><span style="font-weight: bold"> I: </span>
                <text-highlight
                  :queries="searchArray"
                >
                  {{ item.interior_glass }}
                </text-highlight>
              </span>
              <span v-if="item.single_glass"><span style="font-weight: bold">S: </span>
                <text-highlight
                  :queries="searchArray"
                >
                  {{ item.single_glass }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.le_surface`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.le_surface }}
              </text-highlight>
            </template>
            <template #[`item.argon`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.argon }}
              </text-highlight>
            </template>
            <template #[`item.awd_spacer`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.awd_spacer }}
              </text-highlight>
            </template>
            <template #[`item.spandrel_paint`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.spandrel_paint }}
              </text-highlight>
            </template>
            <template #[`item.stc`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.stc }}
              </text-highlight>
            </template>
            <template #[`item.glass_type`]="{ item }">
              <text-highlight
                :queries="searchArray"
              >
                {{ item.glass_type }}
              </text-highlight>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    items: [],
    loading: false,
    headers: [
      { text: 'Code', value: 'row' },
      { text: 'Glass Description', value: 'glass_description' },
      { text: 'Low E', value: 'le_surface' },
      { text: 'Argon', value: 'argon' },
      { text: 'Spacer Type', value: 'awd_spacer' },
      { text: 'Spandrel Paint', value: 'spandrel_paint' },
      { text: 'STC', value: 'stc' },
      { text: 'Glass Type', value: 'glass_type' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    topSpaceHeight: 150,
    windowHeight: 1000,
    searchArray: [],
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    search() {
      this.get();
      this.searchArray = this.search.split(' ');
    },
  },
  created() {
    this.init();
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      this.items = [];
      const submitModel = {
        filters: {
        },
        search: this.search,
      };
      const res = await this.$api.glass.readGlassPaintCode(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.items = [];
        this.loading = false;
        return;
      }
      this.items = res;
      this.loading = false;
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row class="align-center">
      <v-col
        v-for="(item, index) in items"
        :key="index"
        sm="auto"
      >
        <v-checkbox
          :key="'m' + index"
          :label="item.name.toUpperCase()"
          :input-value="item.is_active"
          @click="submit(item)"
        />
      </v-col>
      <v-col class="text-end">
        <a href="https://app.powerbi.com/groups/b21fb517-8ca2-4267-8963-23ca228bc885/list?experience=power-bi">POWER BI URL</a>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 16px; font-weight: bold;">User Access</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="users"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.full_name`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.full_name }}
              </text-highlight>
            </template>
            <template #[`item.roles`]="{ item }">
              <v-row v-if="item.roles && item.roles.length > 1">
                <v-col class="pl-1">
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span
                        style="color: #1976d2;"
                        v-on="on"
                      >ROLES</span>
                    </template>
                    <span
                      v-for="p in item.roles"
                      :key="p.id"
                    >
                      {{ p.name }}<br>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col
                  v-for="p, idx in item.roles"
                  :key="p.id"
                  cols="auto"
                  class="pr-0 pl-1"
                >
                  <span>
                    {{ p.name }}<span v-if="idx !== item.roles.length - 1">,</span>
                  </span>
                </v-col>
              </v-row>
            </template>

            <template #[`item.glass_purchase_order`]="{ item }">
              <v-checkbox
                v-if="item.dashboard_ids"
                :input-value="item.dashboard_ids.includes(glassPurchseOrderId)"
                hide-details
                class="mt-0"
                @change="handleCheckboxChange($event, item, glassPurchseOrderId)"
              />
            </template>
            <template #[`item.chrislea_inventory`]="{ item }">
              <v-checkbox
                v-if="item.dashboard_ids"
                :input-value="item.dashboard_ids.includes(chrisleaInventoryId)"
                hide-details
                class="mt-0"
                @change="handleCheckboxChange($event, item, chrisleaInventoryId)"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data: () => ({
    items: [],
    users: [],
    headers: [
      { text: 'User', value: 'full_name' },
      { text: 'Roles', value: 'roles' },
      { text: 'Glass Purchase Order Items', value: 'glass_purchase_order' },
      { text: '300 Chrislea Inventory', value: 'chrislea_inventory' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 1000, -1],
    },
    loading: false,
    glassPurchseOrderId: 1,
    chrisleaInventoryId: 2,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.get();
      this.getUsers();
    },

    async submit(item) {
      const record = item;
      record.is_active = !item.is_active;
      const res = await this.$api.dashboards.store(record);
      if (!res) {
        this.$bus.$emit('showError');
      }
    },

    async get() {
      this.items = await this.$api.dashboards.get();
    },

    async getUsers() {
      this.loading = true;
      const submitModel = {
        filters: {
        },
      };
      this.users = await this.$api.dashboards.read(submitModel, null, 'read/users');
      this.loading = false;
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async handleCheckboxChange(isChecked, item, id) {
      if (isChecked) {
        item.dashboard_ids.push(id);
      } else {
        const index = item.dashboard_ids.indexOf(id);
        if (index !== -1) {
          item.dashboard_ids.splice(index, 1);
        }
      }

      const submitModel = {
        user_id: item.id,
        dashboard_ids: item.dashboard_ids,
      };

      const res = await this.$api.dashboards.update(submitModel, 'user-dashboard');

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.getUsers();
    },
  },
};
</script>

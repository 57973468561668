<template>
  <v-container
    fluid
    class="px-4"
  >
    <v-expansion-panels
      v-model="panel"
      :disabled="disabled"
    >
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Organization</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Organization />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Users</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Users />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Business Rules</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <BusinessRules />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Equipment</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Equipment />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Inventory</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-tabs
            v-model="inventoryTab"
            class="mb-4"
          >
            <v-tab
              v-for="item in inventoryTabs"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="inventoryTab">
            <v-tab-item>
              <Items />
            </v-tab-item>
            <v-tab-item>
              <Colors />
            </v-tab-item>
            <v-tab-item>
              <Lengths />
            </v-tab-item>
            <v-tab-item>
              <Shelves />
            </v-tab-item>
            <v-tab-item>
              <InventoryCategories />
            </v-tab-item>
            <v-tab-item>
              <PhysicalCountList />
            </v-tab-item>
            <v-tab-item>
              <DangerLevels />
            </v-tab-item>
          </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Glass</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-tabs
            v-model="glassTab"
            class="mb-4"
          >
            <v-tab
              v-for="item in glassTabs"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="glassTab">
            <v-tab-item>
              <Glass />
            </v-tab-item>
            <v-tab-item>
              <GlassSpecification />
            </v-tab-item>
            <v-tab-item>
              <GlassPaintCodes />
            </v-tab-item>
          </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>RM Supply / Scrap Reasons</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <SupplyToLine />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Data Sync</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <DataSync />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Reports</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Reports />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header><h3>Webhooks</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Webhooks />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Truck Schedule</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <TruckSchedule />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header><h3>Dashboards</h3></v-expansion-panel-header>
        <v-expansion-panel-content>
          <Dashboards />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import Items from './Items';
import DangerLevels from './DangerLevels';
import Equipment from './Equipment';
import Users from './Users';
import Organization from './Organization';
import Colors from './Colors.vue';
import InventoryCategories from './InventoryCategories.vue';
import DataSync from './DataSync.vue';
import BusinessRules from './BusinessRules.vue';
import PhysicalCountList from './PhysicalCountList';
import Reports from './Reports';
import Shelves from './Shelves';
import SupplyToLine from './SupplyToLine';
import Glass from './Glass';
import Webhooks from './Webhooks';
import TruckSchedule from './TruckSchedule';
import Dashboards from './Dashboards';
import Lengths from './Lengths';
import GlassSpecification from './GlassSpecification';
import GlassPaintCodes from './GlassPaintCodes';

export default {
  name: 'Index',
  components: {
    Users,
    DangerLevels,
    Equipment,
    Items,
    Organization,
    Colors,
    InventoryCategories,
    DataSync,
    BusinessRules,
    PhysicalCountList,
    Reports,
    Shelves,
    SupplyToLine,
    Glass,
    Webhooks,
    TruckSchedule,
    Dashboards,
    Lengths,
    GlassSpecification,
    GlassPaintCodes,
  },
  filters: {},
  data: () => ({
    selectedTab: 0,
    panel: null,
    disabled: null,
    inventoryTab: null,
    inventoryTabs: ['Raw Materials (Items)', 'Material Colours', 'Lengths', 'Shelves', 'Material Categories', 'Pshysical Counts', 'Caution, Danger Levels'],
    glassTab: null,
    glassTabs: ['Glass', 'Specifications', 'Paint Codes'],
  }),
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // const selectedTab = this.$store.getters.selectedAdmiii;
      // if (selectedTab) {
      //   this.selectedTab = selectedTab;
      // }
    },
    changeTab() {
      // Keep current tab in local storage
      // this.$store.commit('storeInventoryTab', this.selectedTab);
    },
  },
};
</script>
<style scoped>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('the-dialog-box',{ref:"dialogBox",attrs:{"submit":_vm.submit,"submit-btn-text":"Create","full-screen":true,"warning-text":'*Red ones are still to be created'}},[_c('template',{slot:"header"},[_c('span',[_vm._v("Create Auto Batch")])]),_c('template',{slot:"body"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-add-all":false,"is-required":true},model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"label":"Job","items":_vm.jobCodes,"hide-details":"true","item-value":"code","clearable":"","loading":_vm.jobsLoading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.code ? ("" + (item.code)) : ''))]),_vm._v(" - "+_vm._s(item.name ? item.name : '')),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.reference ? ("; " + (item.reference)) : ''))])])]}}]),model:{value:(_vm.filterJob),callback:function ($$v) {_vm.filterJob=$$v},expression:"filterJob"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.poNumbers,"hide-details":"true","disabled":!_vm.inventoryClassId,"rules":[function (v) { return !!v || 'PO# is required'; }],"loading":_vm.poLoading},model:{value:(_vm.poNumber),callback:function ($$v) {_vm.poNumber=$$v},expression:"poNumber"}},[_c('template',{slot:"label"},[_vm._v(" PO#"),_c('RedAsterisk')],1)],2)],1),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.groupedBatches,"hide-default-footer":"","items-per-page":-1,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.inventory_class_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])]}},{key:"item.batch_number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",style:(("font-size: 14px !important; color: " + (item.id ? 'black' : 'red')))},[_vm._v(" "+_vm._s(item.batch_number ? item.batch_number : '')+" ")])]}},{key:"item.po_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.po_number)+" ")]}},{key:"item.g_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.g_code)+" ")]}},{key:"item.glass_thickness",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.glass_thickness)+" ")]}},{key:"item.total_glass",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_glass)+" ")]}},{key:"item.job",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(item.job_code ? ("" + (item.job_code)) : ''))]),_vm._v(" - "+_vm._s(item.job_name ? item.job_name : item.job_name)+" "),_c('span',{staticStyle:{"color":"#1976D2"}},[_vm._v(_vm._s(item.job_reference ? ("; " + (item.job_reference)) : ''))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('v-chip',{attrs:{"small":"","color":item.status ? item.status.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item.status ? item.status.name : '')+" ")]):_c('v-chip',{attrs:{"small":"","color":"error","text-color":"white"}},[_vm._v(" TO BE CREATED ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","disabled":item.id ? true : false},on:{"click":function($event){return _vm.removeBatch(item.batch_number)}}},[_vm._v(" CANCEL BATCH ")])]}}],null,true)})],1)],1),(_vm.poNumber && _vm.total)?_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('span',{staticStyle:{"color":"#000000","font-size":"18px"}},[_vm._v("Total: "),_c('span',{staticStyle:{"font-size":"20px","font-weight":"500"}},[_vm._v(_vm._s(_vm.total))])])])],1):_vm._e(),_c('confirm-form',{ref:"confirmForm"})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
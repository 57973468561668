<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="1"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobCodes"
          hide-details="true"
          item-value="code"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :single-select="false"
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterPo"
          label="PO#"
          :items="poNumbers"
          hide-details="true"
          clearable
          :loading="laodingPo"
        />
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterBatchId"
          label="Batch"
          item-value="id"
          item-text="batch_number"
          :items="batches"
          hide-details
          clearable
          :loading="loadingBatch"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterStatusId"
          label="Status"
          :items="statuses"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-menu
          ref="menuFrom"
          v-model="dateMenuFrom"
          :return-value.sync="filterDateFrom"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDateFrom"
              label="Produced From"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menuFrom.save(filterDateFrom)"
            />
          </template>
          <v-date-picker
            v-model="filterDateFrom"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuFrom = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDateFrom = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-menu
          ref="menuTo"
          v-model="dateMenuTo"
          :return-value.sync="filterDateTo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDateTo"
              label="Produced To"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menuTo.save(filterDateTo)"
            />
          </template>
          <v-date-picker
            v-model="filterDateTo"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menuTo = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDateTo = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="pb-0 pr-0 pt-0"
      >
        <v-btn
          color="success"
          class="mt-3"
          outlined
          :disabled="selectedGlass.length === 0"
          @click="openAddBatch()"
        >
          Add to Batch
        </v-btn>
      </v-col>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="pb-0 pr-0 pt-0"
      >
        <v-btn
          color="primary"
          class="mt-3"
          outlined
          :disabled="selectedGlass.length === 0"
          @click="openNewBatch()"
        >
          New Batch
        </v-btn>
      </v-col>
      <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)) || roles.includes(userRoleIdGlassHandler)"
        cols="auto"
        class="pb-0 pr-0 pt-0"
      >
        <v-btn
          color="primary"
          class="mt-3"
          outlined
          :disabled="selectedGlass.length === 0"
          @click="openNewBatch(true)"
        >
          New Remake PO
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col class="pr-2 pt-8">
            <span style="font-size: 16px !important; color: #00000099">Decimal</span>
          </v-col>
          <v-col class="pl-0">
            <v-switch
              v-model="uomSwitch"
              hide-details
              class="pt-5"
              label="Fraction"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-row>
          <v-col class="pr-2 pt-8">
            <span style="font-size: 16px !important; color: #00000099">All</span>
          </v-col>
          <v-col class="pl-0">
            <v-switch
              v-model="switchAssigned"
              hide-details
              class="pt-5"
              label="Unassigned"
            />
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col
        v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
        cols="auto"
        class="pb-0 pt-0"
      >
        <v-btn
          color="purple"
          class="mt-3"
          outlined
          @click="startSync()"
        >
          SYNC
        </v-btn>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="glassHeaders"
            :items="glassItems"
            :footer-props="footer"
            :items-per-page="100"
            :search="search"
            :loading="loading"
            :show-select="roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate)"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @update:options="updateOptions"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #item.data-table-select="{ item }">
              <v-checkbox
                :key="item.glass_id"
                v-model="selectedGlass"
                :value="item"
                hide-details
              />
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #header.data-table-select>
              <v-checkbox
                v-model="allSelect"
                hide-details
                @click="selectAll()"
              />
            </template>

            <!-- <template #header.data-table-select /> -->
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.glass_thickness`]="{ item }">
              <text-highlight :queries="search">
                {{ item.glass_thickness ? item.glass_thickness : '-' }}
              </text-highlight>
            </template>
            <template #[`item.po_number`]="{ item }">
              <span class="font-weight-bold">
                <text-highlight :queries="search">
                  {{ item.po_number ? item.po_number : '' }}
                </text-highlight>
              </span>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <span
                class="font-weight-bold"
              >
                <text-highlight
                  style="font-size: 14px !important;"
                  :queries="search"
                >

                  {{ item.batch_number ? item.batch_number : '' }}
                </text-highlight></span>
            </template>
            <template #[`item.job_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.job_code ? item.job_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.g_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.g_code ? item.g_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.code ? item.code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.tag_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.tag_number ? item.tag_number : '-' }}
              </text-highlight>
            </template>
            <template #[`item.width`]="{ item }">
              <text-highlight :queries="search">
                {{ item.width ? (uomSwitch ? convertToFraction(item.width) : item.width) : '-' }}
              </text-highlight>
            </template>
            <template #[`item.height`]="{ item }">
              <text-highlight :queries="search">
                {{ item.height ? (uomSwitch ? convertToFraction(item.height) : item.height) : '-' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              <text-highlight :queries="search">
                {{ item.quantity ? item.quantity : '-' }}
              </text-highlight>
            </template>
            <template #[`item.produced_date`]="{ item }">
              <text-highlight :queries="search">
                {{ item.produced_date ? convertDateFormat(item.produced_date) : '-' }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item && item.status ? item.status.color : 'black'"
                text-color="white"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </template>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template #footer>
              <v-row v-if="glassItems && glassItems.length > 0">
                <v-col class="text-end mr-6">
                  <span style="font-weight: bold; font-size: 15px !important;"> Total Quantity: {{ glassItems.reduce((total, item) => total + parseInt(item.quantity, 10), 0) }}</span>
                </v-col>
              </v-row>
            </template>
            <!-- <template #[`item.actions`]="{ item }">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    :disabled="!item.batch_id"
                    class="ml-1 mt-1 mb-1"
                    color="orange"
                    outlined
                    small
                    v-on="on"
                    @click="viewBatch(item)"
                  >
                    Batch
                  </v-btn>
                </template>
                <span>View Batch</span>
              </v-tooltip>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate))"
                    :disabled="!item.batch_id || !(item.batch && item.batch.status_id == glassBatchStatusIdNew)"
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    v-on="on"
                    @click="removeGlassItem(item)"
                  >
                    REMOVE
                  </v-btn>
                </template>
                <span>Remove Glass Items from Batch</span>
              </v-tooltip>
            </template> -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-batch-insert-form
      ref="batchInsertForm"
      @success="success"
    />
    <glass-batch-manual-form
      ref="batchManualForm"
      @success="success"
    />

    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import GlassBatchInsertForm from '@/components/forms/glasses/GlassBatchInsertForm';
import GlassBatchManualForm from '@/components/forms/glasses/GlassBatchManualForm';

export default {
  components: {
    GlassBatchManualForm,
    GlassBatchInsertForm,
  },
  props: {
    jobCode: {
      type: Number,
      default: 0,
    },
    poNumber: {
      type: String,
      default: null,
    },
    batchId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    glassItems: [],
    poNumbers: [],
    jobCodes: [],
    selectedGlass: [],
    batches: [],
    glassHeaders: [
      {
        text: 'Class', value: 'inventory_class', align: 'center', width: '5%',
      },
      { text: 'Job', value: 'job', width: '20%' },
      { text: 'PO#', value: 'po_number', width: '7%' },
      { text: 'Batch', value: 'batch_number', width: '5%' },
      { text: 'G Code', value: 'g_code', width: '5%' },
      { text: 'Code', value: 'code', width: '5%' },
      { text: 'Glass Thickness', value: 'glass_thickness', width: '10%' },
      { text: 'Tag#', value: 'tag_number', width: '5%' },
      { text: 'Width', value: 'width', width: '7%' },
      { text: 'Height', value: 'height', width: '7%' },
      { text: 'Qty', value: 'quantity', width: '3%' },
      {
        text: 'Status', value: 'status', align: 'center', width: '7%',
      },
      { text: 'Produced At', value: 'produced_date', width: '10%' },
      // {
      //   text: 'Actions', value: 'actions', align: 'end', width: '15%',
      // },
    ],
    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    roles: [],
    types: [],
    loading: false,
    jobLoading: false,
    inventoryClassId: null,
    filterJob: 0,
    filterPo: 0,
    uomSwitch: true,
    filterBatchId: 0,
    allSelect: false,
    glassBatchStatusIdNew: 1,
    switchAssigned: false,
    topSpaceHeight: 305,
    windowHeight: 1000,
    pagination: {
      itemsPerPage: 100,
      page: 1,
      sortBy: 'po_number',
      sortDesc: true,
    },
    total: 0,
    inventoryClassIdVwdGlass: 7,
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdGlassHandler: 13,
    dateMenuFrom: false,
    filterDateFrom: null,
    filterStatusId: null,
    dateMenuTo: false,
    filterDateTo: null,
    statuses: [],
    loadingJobs: false,
    laodingPo: false,
    loadingBatch: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      user: (state) => state.user,
    }),
  },
  watch: {
    inventoryClassId() {
      this.selectedGlass = [];
      this.getGlass();
    },
    filterJob() {
      this.getGlass();
    },
    filterPo() {
      this.getGlass();
    },
    filterBatchId() {
      this.getGlass();
    },
    switchAssigned() {
      this.getGlass();
    },
    pagination() {
      this.getGlass();
    },
    search() {
      this.getGlass();
    },
    filterDateFrom(val) {
      this.$refs.menuFrom.save(val);
      this.getGlass();
    },
    filterDateTo(val) {
      this.$refs.menuTo.save(val);
      this.getGlass();
    },
    filterStatusId() {
      this.getGlass();
    },
  },
  created() {
    if (this.jobCode) this.filterJob = this.jobCode;
    if (this.poNumber) this.filterPo = this.poNumber;
    if (this.batchId) this.filterBatchId = this.batchId;
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getPoItems();
      this.getJobs();
      this.getBatches();
      this.getStatuses();
    },

    async getGlass() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.glassItems = [];
      this.total = 0;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_code: this.filterJob,
          po_number: this.filterPo,
          batch_id: this.filterBatchId,
          is_unassigned: this.switchAssigned ? 1 : 0,
          date_from: this.filterDateFrom,
          date_to: this.filterDateTo,
          status_id: this.filterStatusId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.glass.readGlass(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.init();
      if (!res || !res.data || res.data.length === 0) {
        this.glassItems = [];
        this.total = 0;
        return;
      }
      this.glassItems = res.data;
      this.total = res.total;
    },

    async getPoItems() {
      this.laodingPo = true;
      const res = await this.$api.glass.get(`po-numbers/${this.inventoryClassId}/${null}`);
      if (!res) {
        this.poNumbers = [];
        this.laodingPo = false;
        return;
      }
      this.poNumbers = res;
      this.laodingPo = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        this.loadingJobs = false;
        return;
      }
      this.jobCodes = res;
      this.loadingJobs = false;
    },

    async getBatches() {
      this.loadingBatch = true;
      this.batches = await this.$api.glassBatch.get('batch-numbers');
      this.loadingBatch = false;
    },

    async startSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to do a Data Sync? This action will bring new Glass Items into ERP and can take up to 2 minutes!',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.glass.get('sync');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getGlass();
      this.getPoItems();
      this.getJobs();
    },

    async removeGlassItem(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this record from Batch?',
      });
      if (!confirm) return;
      const res = await this.$api.glass.get(`remove/${item.glass_id}/${item.batch_id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getGlass();
      this.getBatches();
    },

    openNewBatch(isRemake = false) {
      if (this.selectedGlass.length === 0) return;
      const firstRecord = this.selectedGlass[0];
      // const wrongItem = this.selectedGlass.findIndex((x) => x.po_number !== firstRecord.po_number || x.inventory_class_id !== firstRecord.inventory_class_id);
      // if (wrongItem !== -1) {
      //   this.$bus.$emit('showError', 'Selected Glass Records must have the same Inventory Class and PO#!');
      //   return;
      // }
      const selectedIds = this.selectedGlass.map((x) => x.glass_id);
      this.$refs.batchManualForm.open(selectedIds, firstRecord.inventory_class_id, isRemake);
    },

    openAddBatch() {
      if (this.selectedGlass.length === 0) return;
      const firstRecord = this.selectedGlass[0];
      const wrongItem = this.selectedGlass.findIndex((x) => x.po_number !== firstRecord.po_number || x.inventory_class_id !== firstRecord.inventory_class_id || x.g_code !== firstRecord.g_code || x.glass_thickness !== firstRecord.glass_thickness);
      if (wrongItem !== -1) {
        this.$bus.$emit('showError', 'Selected Glass Records must have the same Inventory Class, PO#, G Code and Glass Thickness!');
        return;
      }
      const glassIds = this.selectedGlass.map((x) => x.glass_id);
      const glassQuantity = this.selectedGlass.reduce((result, item) => result + parseInt(item.quantity, 10), 0);
      this.$refs.batchInsertForm.open(firstRecord.inventory_class_id, firstRecord.po_number, firstRecord.g_code, firstRecord.glass_thickness, glassIds, glassQuantity);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getGlass();
      this.getPoItems();
      this.getJobs();
      this.getBatches();
      this.selectedGlass = [];
      this.allSelect = false;
    },

    viewBatch(item) {
      this.$router.push({
        name: 'GlassBatchView',
        params: {
          poNumber: item.po_number,
        },
      });
    },

    async getStatuses() {
      this.statuses = await this.$api.glassPoStatus.get();
    },

    selectAll() {
      if (this.allSelect) this.selectedGlass = this.glassItems.filter((x) => !x.batch_id);
      else this.selectedGlass = [];
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updateOptions(newOptions) {
      this.pagination = newOptions;
    },

    convertToFraction(decimal) {
      const fractions = {
        0.0625: '1/16',
        0.125: '1/8',
        0.1875: '3/16',
        0.25: '1/4',
        0.3125: '5/16',
        0.375: '3/8',
        0.4375: '7/16',
        0.5: '1/2',
        0.5625: '9/16',
        0.625: '5/8',
        0.6875: '11/16',
        0.75: '3/4',
        0.8125: '13/16',
        0.875: '7/8',
        0.9375: '15/16',
      };

      // If the decimal is an exact match to any fraction, return the fraction directly
      if (decimal in fractions) {
        return fractions[decimal];
      }

      const wholeNumber = Math.floor(decimal);
      // eslint-disable-next-line no-param-reassign
      decimal -= wholeNumber; // Remove the whole number part

      let closestFraction = Object.keys(fractions)[0];

      // eslint-disable-next-line no-restricted-syntax
      for (const fraction of Object.keys(fractions)) {
        if (Math.abs(decimal - fraction) < Math.abs(decimal - closestFraction)) {
          closestFraction = fraction;
        }
      }

      if (wholeNumber > 0) {
        return `${wholeNumber} - ${fractions[closestFraction]}"`;
      }
      return fractions[closestFraction];
    },

    convertDateFormat(inputDate) {
      // Parse the input date
      const date = new Date(inputDate);

      // Format the date as "YYYY-MM-DD h:mm a" (e.g., 2023-10-24 9:25 PM)
      const formattedDate = date.toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      return formattedDate;
    },

  },
};
</script>

<style scoped>
span
{
  font-size: 11px !important;
}
.v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
}
::v-deep .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
    color: rgba(0, 0, 0, 0.1) !important;
}
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th
{
  padding-right: 4px !important;
  padding-left: 4px !important;
}
</style>

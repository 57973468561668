<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="2">
        <v-btn
          text
          @click="goBack"
        >
          <v-icon>
            fas fa-arrow-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        md="3"
        sm="12"
        class="text-center mt-1"
      >
        <h3 color="primary">
          Supply to Line / Return Sheet #{{ consumption_id }}
        </h3>
      </v-col>
      <v-col
        cols="7"
        md="7"
        sm="12"
        class="text-right"
      >
        <v-btn
          color="#FF8C00"
          outlined
          class="mr-4"
          @click="openNewScrapRecordForm(null)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Scrap Record
        </v-btn>
        <v-btn
          color="#BF0000"
          outlined
          class="mr-4"
          @click="openNewRecordForm(null, 0)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Supply Record
        </v-btn>

        <v-btn
          color="#3BA424"
          outlined
          @click="openNewRecordForm(null, 1)"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Return Record
        </v-btn>
      </v-col>
    </v-row>
    <v-row :style="`background-color: ${hexToRgb('#E0E0E0')}; margin:10px 0 0 0`">
      <v-col>
        <v-row>
          <v-col
            cols="2"
            style="text-align:center"
          >
            <h5 class="h5">
              Class:
            </h5>
            <v-chip
              class="ml-1"
              small
              :color="consumption && consumption.class ? consumption.class.color : 'grey'"
              text-color="white"
            >
              {{ consumption && consumption.class ? consumption.class.name : 'All' }}
            </v-chip>
          </v-col>
          <v-col
            cols="4"
            style="text-align:center"
          >
            <h5 class="h5">
              Job:
            </h5> <span
              v-if="consumption"
              class="value"
            > {{ consumption.job_code }} - {{ consumption.job_name }}; {{ consumption.job_reference }}</span> <a @click="runWebhook">PDF</a>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Job Address:
            </h5> <span class="value"> {{ consumption != null ? consumption.address_simple : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Sheet Date:
            </h5> <span class="value"> {{ consumption != null ? consumption.sheet_date : '' }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Created At:
            </h5> <span class="value"> {{ consumption != null ? consumption.created_at : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Created By:
            </h5> <span class="value"> {{ consumption != null ? consumption.created_by : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              External Colour:
            </h5> <span class="value"> {{ color && color.ex_color ? color.ex_color.code : '' }}</span>
          </v-col>
          <v-col
            cols="3"
            style="text-align:center"
          >
            <h5 class="h5">
              Internal Colour:
            </h5> <span class="value"> {{ color && color.in_color ? color.in_color.code : '' }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="movements-table"
            :headers="profilesHeaders"
            :items="profiles"
            :footer-props="itemsTableFooterProps"
            :items-per-page="20"
            :loading="loading"
            dense
            fixed-header
            :search="search"
            :height="windowHeight"
            :item-class="itemRowBackground"
          >
            <template #[`item.class`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.photo`]="{ item }">
              <a
                @click="openItemShowImageForm(item.photo, item.mime_type, item.material_id)"
              >
                <v-img
                  :src="convertedPhoto(item.photo, item.mime_type)"
                  class="profile-photo"
                />
              </a>
            </template>

            <template #[`item.die`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item ? `${item.material_id}: ${item.name}` : '' }}
              </text-highlight>
            </template>
            <template #[`item.color`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.color ? item.color.code : '' }}
              </text-highlight>
            </template>
            <template #[`item.location`]="{ item }">
              <span>{{ item.tag && item.tag.location ? item.tag.location.name : '' }}, {{ item.tag && item.tag.inventory_location ? item.tag.inventory_location.name : '' }} </span>
              <v-icon
                small
                color="primary"
                @click="openLocationForm(item.inventory_tag)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template #[`item.quantity_supplied`]="{ item }">
              {{ !item.is_return ? item.quantity_supplied : '0' }}
            </template>
            <template #[`item.quantity_returned`]="{ item }">
              {{ item.is_return ? item.quantity_supplied : '0' }}
            </template>
            <template #[`item.quantity_missed`]="{ item }">
              {{ item.quantity_missed }}
            </template>
            <template #[`item.inventory_tag`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.inventory_tag }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item.created_by }}
            </template>
            <template #[`item.updated_at`]="{ item }">
              {{ item.updated_at }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditSupplyToLineSheetForm(item)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleted(item)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <Snackbar ref="snackbar" />

    <SupplyToLineSheetRecordForm
      ref="supplyToLineSheetForm"
      :is-return="isReturn"
      :is-scrap="isScrap"
      @profile-creation-success="notifyProfileCreationSuccess"
      @profile-creation-fail="notifyProfileCreationFail"
    />
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <supply-to-line-location-form
      ref="locationForm"
      @success="locationFormSuccess"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import SupplyToLineSheetRecordForm from '@/components/forms/supplyToLine/SupplyToLineSheetRecordForm';
import { mapState } from 'vuex';
import SupplyToLineLocationForm from '@/components/forms/supplyToLine/SupplyToLineLocationForm';
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';
import RecordsApi from '../../api/RecordsApi';
import Snackbar from '../../components/Snackbar';

export default {
  name: '',
  components: {
    Snackbar,
    SupplyToLineSheetRecordForm,
    InventoryItemShowImageForm,
    SupplyToLineLocationForm,
  },
  filters: {},
  data: () => ({
    loading: true,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    profiles: [],
    photo: null,
    photoMimeType: null,
    color: { ex_color: null, in_color: null },
    ex_color: null,
    in_color: null,
    consumption_id: null,
    consumption: null,
    isReturn: 0,
    isScrap: 0,
    topSpaceHeight: 315,
    windowHeight: 1000,
    webhook: null,

  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    profilesHeaders() {
      const headers = [];
      headers.push({ text: 'Class', value: 'class', width: '5%' });
      headers.push({ text: 'Image', value: 'photo', width: '5%' });
      headers.push({ text: 'Item', value: 'die', width: '18%' });
      if (this.invClassId !== this.classVWDGlass && this.invClassId !== this.classGlassId) { headers.push({ text: 'Colour', value: 'color', width: '12%' }); }
      headers.push({ text: 'Location', value: 'location', width: '10%' });
      headers.push({ text: 'Qty Sup.', value: 'quantity_supplied', width: '3%' });
      headers.push({ text: 'Qty Ret.', value: 'quantity_returned', width: '3%' });

      headers.push({ text: 'Qty Mis.', value: 'quantity_missed', width: '3%' });

      headers.push({ text: 'QR/UPC Code', value: 'inventory_tag', width: '5%' });
      headers.push({ text: 'Updated At', value: 'updated_at', width: '7%' });
      headers.push({ text: 'Created By', value: 'created_by', width: '7%' });
      headers.push({
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      });

      return headers;
    },
  },
  created() {
    this.consumption_id = this.$route.params.id;
    this.init();
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      this.getConsumption();
      this.getProfiles();
      this.getWebhook();
    },

    async getProfiles() {
      this.loading = true;
      const readModel = {
        filters:
        {
          sheet_id: this.consumption_id,
          is_scrap: 1,
        },
      };
      this.profiles = await this.$api.supplyToLineRecord.read(readModel);
      this.loading = false;
    },
    async getConsumption() {
      const readModel = {
        filters:
        {
          id: this.consumption_id,
        },
      };
      const res = await this.$api.supplyToLine.read(readModel);
      if (!res || res.length < 1 || !res[0]) {
        return;
      }
      const firstIndex = 0;
      this.consumption = res[firstIndex];

      this.color.ex_color = this.consumption.ex_color ? this.consumption.ex_color : null;
      this.color.in_color = this.consumption.in_color ? this.consumption.in_color : null;
    },

    async getWebhook() {
      const stlModuleId = 3;
      const readModel = {
        filters:
        {
          module_id: stlModuleId,
        },
      };
      const res = await this.$api.webhook.read(readModel);
      if (!res || !res[0]) return;
      const firstIndex = 0;
      this.webhook = res[firstIndex];
    },

    async deleted(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await RecordsApi.delete(item.id);

      if (res) {
        this.$refs.snackbar.showSuccess('Success!');
      } else {
        this.$refs.snackbar.showError('Something went wrong!');
      }
      this.getProfiles();
      this.$bus.$emit('loading');
    },

    async runWebhook() {
      this.$bus.$emit('loading');
      const stlModuleId = 3;
      const submitModel = { id: this.consumption.id, inventory_class_id: this.consumption.inventory_class_id, module_id: stlModuleId };
      const res = await this.$api.webhook.store(submitModel, 'run');
      if (!res) {
        this.$bus.$emit('loading');
        return;
      }
      const pdf = await this.$api.report.download(res.id, 'pdf');
      if (!pdf) {
        this.$bus.$emit('loading');
        this.$bus.$emit('showError', 'Report Failed');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Report_${res.report_title}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      saveAs(file, fileName);
    },

    notifyProfileCreationSuccess() {
      this.$refs.snackbar.showSuccess('Success!');
      this.getProfiles();
    },
    notifyProfileCreationFail() {
      this.$refs.snackbar.showError('Something went wrong!');
    },
    itemRowBackground(item) {
      if (item.is_scrap) return 'scrap-class';
      return item.is_return ? 'supply-class' : 'return-class';
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },

    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },

    openNewRecordForm(item, isReturn) {
      this.isReturn = isReturn;
      this.isScrap = 0;
      this.$refs.supplyToLineSheetForm.openDialog(item);
    },

    openNewScrapRecordForm(item) {
      this.isReturn = 0;
      this.isScrap = 1;
      this.$refs.supplyToLineSheetForm.openDialog(item);
    },

    openEditSupplyToLineSheetForm(item = null) {
      if (item !== null) {
        this.isReturn = item.is_return;
        this.isScrap = item.is_scrap;
      }
      this.$refs.supplyToLineSheetForm.openDialog(item);
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    goBack() {
      this.$router.push('/consumptions');
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    openLocationForm(tag) {
      this.$refs.locationForm.open(tag);
    },

    locationFormSuccess() {
      this.getProfiles();
    },
  },
};
</script>
<style scoped>
.profile-photo {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}

.h5,
.value {
  display: inline-block;
  font-size: 15px;
}

::v-deep .scrap-class{
  background-color: rgba(221, 44, 0, .1);
}

::v-deep .supply-class{
  background-color: rgba(197, 17, 98, .1);
}

::v-deep .return-class{
  background-color: rgba(0, 77, 64, .1)
}
</style>

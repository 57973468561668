<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Update'"
  >
    <template slot="header">
      <span>Update Note</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-text-field
            v-model="note"
            :rules="[(v) => !!v || 'Note is required']"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    id: null,
    note: '',
    inventoryClassId: 0,
  }),
  methods: {

    async submit() {
      const submitModule = {
        id: this.id,
        note: this.note,
        inventory_class_id: this.inventoryClassId,
      };
      const res = await this.$api.supplyToPaint.update(submitModule, 'note');

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id, note, inventoryClassId) {
      this.id = id;
      this.note = note;
      this.inventoryClassId = inventoryClassId;
      this.$refs.dialogBox.open();
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('inventory-class-select-element',{attrs:{"is-state":true},model:{value:(_vm.selectedClassId),callback:function ($$v) {_vm.selectedClassId=$$v},expression:"selectedClassId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Problem","items":_vm.problems,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.selectedProblemId),callback:function ($$v) {_vm.selectedProblemId=$$v},expression:"selectedProblemId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"Type","items":_vm.types,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.selectedTypeId),callback:function ($$v) {_vm.selectedTypeId=$$v},expression:"selectedTypeId"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.watchlistHeaders,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":100,"loading":_vm.loading,"dense":"","search":_vm.search,"server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{style:({ backgroundColor: item && item.problem ? _vm.hexToRgb(item.problem.color) : 'white' })},[_c('td',{staticClass:"text-center",staticStyle:{"font-weight":"bold"}},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : '')+" ")])],1),_c('td',{staticClass:"text-center",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(item && item.problem ? item.problem.name : '')+" ")]),_c('td',{staticClass:"text-center"},[_c('v-chip',{attrs:{"small":"","color":item && item.type ? item.type.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.type ? item.type.name : '')+" ")])],1),_c('td',{staticClass:"text-center"},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.name ? item.name : '')+" ")])],1),_c('td',[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.fpgest_name ? item.fpgest_name : '')+" ")])],1),_c('td',[(item.is_info)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.openDialog(item)}}},on),[_vm._v(" fas fa-exclamation-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.record_info))])]):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(item.created_at)+" ")]),_c('td',[_c('v-row',[_c('v-col',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[(item.status_tip)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"orange","dark":""}},on),[_vm._v(" fas fa-question-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status_tip))])]):_vm._e()],1)],1)],1),_c('td',{staticClass:"text-end"},[(item.is_delete)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v(" REMOVE ")]):_vm._e()],1)])]}}])})],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-4"},[_c('span',{staticClass:"headline"},[_vm._v("ERP Identifier "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.dialogERPIdentifier))])]),(_vm.dialogFPGESTIdentifier)?_c('span',{staticClass:"headline"},[_vm._v("FPGEST Identifier "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.dialogFPGESTIdentifier))])]):_vm._e()]),_c('v-card-text',_vm._l((_vm.dialogTextElements),function(it,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('span',{staticStyle:{"color":"black","font-size":"14px"}},[_vm._v(_vm._s(it))])])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"normal"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
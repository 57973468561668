<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card tile>
        <v-card-title>
          {{ dialogTitle }}
          <v-col class="text-right">
            <v-btn
              icon
              @click="close()"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row v-show="!editRecord">
            <v-col
              cols="2"
            >
              <v-text-field
                v-model="search"
                append-icon="fas fa-search"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
            <v-col
              cols="2"
            >
              <v-text-field
                v-model="searchInvTag"
                label="QR Code"
                single-line
                hide-details
              />
            </v-col>
            <v-col
              cols="2"
            >
              <inventory-class-select-element
                v-model="invClassId"
                :is4500="invClassId === class4500Id"
                :is4800="invClassId === class4800Id"
                :is-railings="invClassId === classRailingsId"
                :is-inst="invClassId === classInstId"
                :is-glass="invClassId === classGlassId"
                :is-commercial="invClassId === classCommercialId"
                :is-vwd-glass="invClassId === classVWDGlass"
                :is-panels="invClassId === classPanelsId"
                :is-sid="invClassId === classSidgId"
                :is-rail-part="invClassId === classPanelPartsId"
                :is-parts="invClassId === classPartsId"
                :is-add-all="false"
              />
            </v-col>
            <v-col
              cols="3"
            >
              <v-autocomplete
                v-model="filterItemId"
                label="Item"
                :items="filterItems"
                :item-text="item => `${item.material_id} ${item.name}`"
                item-value="id"
                hide-details="true"
                clearable
                :loading="loadingInventoryItem"
              >
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item && item.classes ? item.classes.color : 'white'"
                  >
                    {{ item && item.classes ? item.classes.name : '' }}
                  </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                </template>
                <!-- eslint-disable-next-line vue/no-template-shadow -->
                <template #selection="{ item }">
                  <v-chip
                    class="mt-1 mb-1 mr-3"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="item && item.classes ? item.classes.color : 'white'"
                  >
                    {{ item && item.classes ? item.classes.name : '' }}
                  </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              class="text-end"
            >
              <v-btn
                color="primary"
                outlined
                class="mr-4 mt-3"
                @click="openQrForm"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  fas fa-plus
                </v-icon>
                Scan QR
              </v-btn>
            </v-col>
          </v-row>
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="profilesHeaders"
              :footer-props="itemsTableFooterProps"
              :items-per-page="itemsPerPage"
              :items="inventoryItems"
              :loading="loading"
              fixed-header
              :height="windowHeight"
              dense
              :search="search"
              :server-items-length="total"
              @pagination="updatePagination"
            >
              <template #[`item.photo`]="{ item }">
                <a
                  v-if="item && item.photo"
                  @click="openItemShowImageForm(item ? item.photo : '', item ? item.mime_type : '', item ? item.material_id : '')"
                >
                  <v-img
                    :src="convertedPhoto(item ? item.photo : '', item ? item.mime_type : '')"
                    class="profile-photo"
                  />
                </a>
              </template>
              <template #[`item.full_name`]="{ item }">
                <text-highlight
                  class="font-weight-bold"
                  :queries="search"
                >
                  {{ item ? item.full_name : '' }}
                </text-highlight>
              </template>
              <template #[`item.color`]="{ item }">
                {{ item.color_name ? item.color_name : getColorName(item) }}
              </template>
              <template #[`item.location_name`]="{ item }">
                {{ item.location_name ? item.location_name : `${getLocation(item)}` }} {{ item.inventory_location_name ? item.inventory_location_name : getShelve(item) }}
                <v-icon
                  v-if="item.inventory_tag"
                  small
                  color="primary"
                  @click="openLocationForm(item.inventory_tag)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <template #[`item.profile`]="{ item }">
                {{ item ? item.profile : '' }}
              </template>
              <template #[`item.quantity_on_hand`]="{ item }">
                {{ item.quantity_on_hand ? item.quantity_on_hand : getQtyOnHand(item) }}
              </template>
              <template #[`item.quantity_supplied`]="{ item }">
                <v-text-field
                  v-model="item.quantity_supplied"
                  type="number"
                >
                  {{ item ? item.quantity_supplied : '' }}
                </v-text-field>
              </template>
              <template
                v-if="isReturn == 0"
                #[`item.quantity_missed`]="{ item }"
              >
                <v-text-field
                  v-model="item.quantity_missed"
                  type="number"
                />
              </template>
              <template
                v-if="isScrap"
                #[`item.scrap_reason`]="{ item }"
              >
                <v-select
                  v-model="item.scrap_reason_id"
                  :items="scrapReasons"
                  item-text="name"
                  item-value="id"
                >
                  <template slot="label">
                    Reason
                  </template>
                </v-select>
              </template>

              <template #[`item.inventory_tag`]="{ item }">
                <v-combobox
                  ref="form"
                  v-model="item.inventory_tag"
                  :items="item.inventory_tags"
                  item-text="inventory_tag"
                  text-val="item"
                  :return-object="false"
                  :rules="[(v) => !!v || 'QR/UPC is required']"
                  required
                >
                  <template #item="{ item }">
                    <span><span style="font-weight: bold;">{{ item.inventory_tag }}</span>, <span style="color: blue;">Location:</span> {{ item.location ? item.location.name : '-' }} "{{ item.inventory_location ? item.inventory_location.name : '-' }}", <span style="color: green;">Qty: </span> {{ item.quantity_on_hand }}</span>
                  </template>
                  <template slot="label">
                    QR/UPC<RedAsterisk />
                  </template>
                </v-combobox>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  class="ml-1 mt-1 mb-1"
                  color="success"
                  outlined
                  @click="submitForm(item)"
                >
                  {{ btnSubmitName }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="qrCodeDialog"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title>
          <v-col class="text-right">
            <v-btn
              icon
              @click="qrCodeDialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-container fluid>
          <inventory-qr
            :is-rm-supply="true"
            @return-qr-code="returnQrCode"
          />
        </v-container>
      </v-card>
    </v-dialog>

    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <supply-to-line-location-form
      ref="locationForm"
      @success="locationFormSuccess"
    />
  </v-row>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import RecordsApi from '@/api/RecordsApi';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import SupplyToLineLocationForm from '@/components/forms/supplyToLine/SupplyToLineLocationForm';

export default {
  components: {
    InventoryItemShowImageForm,
    TextHighlight,
    InventoryQr,
    SupplyToLineLocationForm,
  },
  props:
  {
    isReturn: {
      type: Number,
      default: 0,
    },
    isScrap: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    consumption: null,
    consumption_id: null,
    color: { ex_color: null, in_color: null },
    profiles: [],
    inventoryItems: [],
    itemsList: [],
    inventoryTags: [],
    scrapReasons: [],
    filteredProfiles: [],
    filterItems: [],
    quantitySupplied: 0,
    quantityMissing: 0,
    inventoryTag: '',
    userId: 0,
    loading: false,
    dialog: false,
    dialogTitle: '',
    editRecord: null,
    btnSubmitName: '',
    searchInvTag: '',
    qrCodeDialog: false,
    filterItemId: null,
    invClassId: null,
    itemsTableFooterProps: {
      'items-per-page-options': [10, 50, 100, -1],
    },
    itemsPerPage: 10,
    pagination: {
      itemsPerPage: 10,
      page: 1,
    },
    total: 0,
    search: '',
    class4500Id: 1,
    class4800Id: 2,
    classRailingsId: 3,
    classInstId: 4,
    classGlassId: 5,
    classCommercialId: 6,
    classVWDGlass: 7,
    classPanelsId: 8,
    classSidgId: 9,
    classPanelPartsId: 10,
    classPartsId: 11,
    topSpaceHeight: 235,
    windowHeight: 1000,
    loadingInventoryItem: false,
  }),
  computed: {
    profilesHeaders() {
      const headers = [];
      headers.push({ text: 'Image', value: 'photo', width: '3%' });
      headers.push({ text: 'Item', value: 'full_name', width: '10%' });
      if (this.invClassId !== this.classVWDGlass && this.invClassId !== this.classGlassId && this.invClassId !== this.classSidgId) { headers.push({ text: 'Colour', value: 'color', width: '10%' }); }
      headers.push({ text: 'Location', value: 'location_name', width: '10%' });
      headers.push({
        text: 'Qty On Hand', value: 'quantity_on_hand', width: '5%',
      });

      if (this.isReturn) {
        headers.push({ text: 'Qty Returned', value: 'quantity_supplied', width: '5%' });
      } else {
        headers.push({ text: 'Qty Supplied', value: 'quantity_supplied', width: '5%' });
      }

      if (this.isScrap) {
        headers.push({ text: 'Reason', value: 'scrap_reason', width: '10%' });
      }

      if (this.isReturn === 0 && !this.isScrap) headers.push({ text: 'Qty Missing', value: 'quantity_missed', width: '7%' });

      headers.push({ text: 'QR/UPC Code', value: 'inventory_tag', width: '20%' });
      headers.push({
        text: 'Actions', value: 'actions', align: 'end', width: '5%',
      });

      return headers;
    },

  },

  watch: {
    async editRecord() {
      this.getInventoryItems();
    },
    searchInvTag() {
      this.getInventoryItems();
    },
    pagination() {
      this.getInventoryItems();
    },
    search() {
      this.getInventoryItems();
    },
    invClassId() {
      this.getInventoryItems();
      this.getFilterItems();
    },
    filterItemId() {
      this.getInventoryItems();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.consumption_id = this.$route.params.id;
    }
    this.init();
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async init() {
      this.userId = this.$store.getters.user.id;
      await this.getConsumption();
      this.getInventoryItems();
      this.getScrapReasons();
      this.getFilterItems();
    },

    async getConsumption() {
      const readModel = {
        filters:
        {
          id: this.consumption_id,
        },
      };
      const res = await this.$api.supplyToLine.read(readModel);
      if (!res || res.length < 1 || !res[0]) {
        return;
      }
      const firstIndex = 0;
      this.consumption = res[firstIndex];
      this.invClassId = this.consumption.inventory_class_id;
      this.color.ex_color = this.consumption.ex_color ? this.consumption.ex_color : null;
      this.color.in_color = this.consumption.in_color ? this.consumption.in_color : null;
    },

    async getInventoryItems() {
      this.loading = true;
      this.loadingInventoryItem = true;
      this.inventoryItems = [];
      const readModel = {
        pagination: this.pagination,
        filters:
        {
          inventory_class_id: this.invClassId,
          is_rm_supply: true,
          id: this.filterItemId,
        },
        search: this.search,
        includes: {
          is_include_item_image: true,
        // is_include_class_inst: this.invClassId !== this.classVWDGlass && this.invClassId !== this.classGlassId
        },
      };

      if (this.consumption && this.isReturn) readModel.filters.job_id = this.consumption.job_id;
      if (this.editRecord) readModel.filters.rmc_consumption_record_id = this.editRecord.id;
      if (this.searchInvTag) readModel.filters.inventory_tag = this.searchInvTag;
      if (this.isReturn) readModel.filters.is_rm_return = true;

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        this.total = 0;
        return;
      }
      this.inventoryItems = res.data;
      this.total = res.total;
    },

    async getScrapReasons() {
      this.scrapReasons = await this.$api.supplyToLineScrapReason.get();
    },

    async getFilterItems() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.invClassId,
        },
      };

      const res = await this.$api.inventoryItem.readFilterData(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.filterItems = [];
        return;
      }
      this.filterItems = res.data;
    },

    async submitForm(item) {
      if (!item.inventory_tag || !item.quantity_supplied) {
        this.$bus.$emit('showError', 'Fields cannot be empty');
        return;
      }

      if (!this.isReturn || !this.editRecord) {
        // eslint-disable-next-line eqeqeq
        const tag = item.inventory_tags.find((x) => x.inventory_tag == item.inventory_tag);
        if (!tag) return;
        if (this.editRecord != null) {
          if ((item.quantity_supplied - this.editRecord.quantity_supplied) > parseInt(tag.quantity_on_hand, 10)) {
            this.$bus.$emit('showError', 'Supplied Quantity can\'t be greated than Quantity On Hand');
            return;
          }
        } else if (item.quantity_supplied > parseInt(tag.quantity_on_hand, 10)) {
          this.$bus.$emit('showError', 'Supplied Quantity can\'t be greated than Quantity On Hand');
          return;
        }
      }
      this.$bus.$emit('loading');

      let data;
      if (this.editRecord != null) {
        data = {
          id: item.rmc_consumption_record_id,
          inventory_item_id: item.id,
          inventory_tag: item.inventory_tag,
          consumption_id: this.consumption_id,
          quantity_supplied: item.quantity_supplied,
          quantity_missed: item.quantity_missed,
          created_by: this.userId,
          is_return: this.isReturn,
          is_scrap: this.isScrap,
          scrap_reason_id: item.scrap_reason_id,
        };
      } else {
        data = {
          inventory_item_id: item.id,
          consumption_id: this.consumption_id,
          inventory_tag: item.inventory_tag,
          quantity_supplied: item.quantity_supplied,
          quantity_missed: item.quantity_missed,
          created_by: this.userId,
          is_return: this.isReturn,
          is_scrap: this.isScrap,
          scrap_reason_id: item.scrap_reason_id,
        };
      }

      const res = await RecordsApi.store(data);
      this.$bus.$emit('loading');
      if (res) {
        this.$emit('profile-creation-success');
        this.dialog = false;
        this.clear();
      } else {
        this.$emit('profile-creation-fail');
      }
    },

    openDialog(item = null) {
      this.dialog = true;
      // Make sure the form is rendered and $refs are accessible
      this.$nextTick(async () => {
        if (item != null) {
          this.dialogTitle = `Edit Record #${item.id}`;
          this.btnSubmitName = 'UPDATE';
          this.editRecord = item;
          if (this.editRecord.inventory_tag) this.searchInvTag = this.editRecord.inventory_tag;
        } else {
          this.dialogTitle = '';
          if (this.isScrap) {
            this.dialogTitle = 'New Scrap Record';
            this.getInventoryItems();
          } else if (this.isReturn) {
            this.dialogTitle = 'New Return Record';
            this.getInventoryItems();
          } else {
            this.dialogTitle = 'New Supply Record';
            this.getInventoryItems();
          }
          this.btnSubmitName = 'ADD';
          this.editRecord = null;
        }

        this.useId = this.$store.getters.user.id;
      });
    },

    close() {
      this.clear();
      this.dialog = false;
    },

    convertedPhoto(photo, mimeType) {
      return `data:${mimeType};base64, ${photo}`;
    },

    clear() {
      this.search = '';
      this.searchInvTag = '';
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },

    openQrForm() {
      this.qrCodeDialog = true;
    },

    returnQrCode(qrCode) {
      this.qrCodeDialog = false;
      this.searchInvTag = qrCode;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    getColorName(item) {
      if (!item || !item.inventory_tags) return '';
      // eslint-disable-next-line eqeqeq
      const tag = item.inventory_tags.find((x) => x.inventory_tag == item.inventory_tag);
      if (!tag || !tag.color) return '';
      return tag.color.code;
    },

    getLocation(item) {
      if (!item || !item.inventory_tags) return '';
      // eslint-disable-next-line eqeqeq
      const tag = item.inventory_tags.find((x) => x.inventory_tag == item.inventory_tag);
      if (!tag || !tag.location) return '';
      return tag.location.name;
    },

    getShelve(item) {
      if (!item || !item.inventory_tags) return '';
      // eslint-disable-next-line eqeqeq
      const tag = item.inventory_tags.find((x) => x.inventory_tag == item.inventory_tag);
      if (!tag || !tag.inventory_location) return '';
      return tag.inventory_location.name;
    },

    getQtyOnHand(item) {
      if (!item || !item.inventory_tags) return '';
      // eslint-disable-next-line eqeqeq
      const tag = item.inventory_tags.find((x) => x.inventory_tag == item.inventory_tag);
      if (!tag) return '';
      return tag.quantity_on_hand;
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    openLocationForm(tag) {
      this.$refs.locationForm.open(tag);
    },

    locationFormSuccess() {
      this.getInventoryItems();
    },
  },
};

</script>
<style scoped>
.profile-photo {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
</style>

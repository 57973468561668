import axios from 'axios';

class Api {
  constructor(model, endpoint) {
    this.model = model;
    this.endpoint = endpoint;
  }

  async store(data, url = null, options = {}) {
    let res;
    const errorText = `Could not store ${this.model} record`;

    await axios.post(`/${this.endpoint}${url ? `/${url}` : ''}`, data, options)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log('Request made and server responded');
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log('The request was made but no response was received');
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Something happened in setting up the request that triggered an Error');
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async get(url = null, options = {}) {
    let res;
    const errorText = `Could not get ${this.model} list`;

    await axios.get(`/${this.endpoint}${url ? `/${url}` : ''}`, options)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log('Request made and server responded');
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log('The request was made but no response was received');
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Something happened in setting up the request that triggered an Error');
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = null;
      });
    return res;
  }

  async getList(url = null) {
    let res = [];
    const errorText = `Could not fetch ${this.model} list`;

    await axios.get(`/${this.endpoint}${url ? `/${url}` : ''}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log('Request made and server responded');
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log('The request was made but no response was received');
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Something happened in setting up the request that triggered an Error');
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = [];
      });
    return res;
  }

  async read(data, options = {}, url = null) {
    let res = [];
    const errorText = `Could not fetch ${this.model} read`;

    await axios.post(`/${this.endpoint}${url ? `/${url}` : '/read'}`, data, options)
      .then((response) => {
        if (!response || !response.data || response.data.length === 0) res = [];
        else { res = response.data; }
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (axios.isCancel(error)) {
          const cancelCode = 3001;
          res = cancelCode;
        } else {
          if (error.response) {
            if (error.response.status === 505) {
              res = {
                status: 505,
                message: error.response.data,
              };
              return res;
            }
            // Request made and server responded
            console.log('Request made and server responded');
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
          // The request was made but no response was received
            console.log('The request was made but no response was received');
            console.log(error.request);
          } else {
          // Something happened in setting up the request that triggered an Error
            console.log('Something happened in setting up the request that triggered an Error');
            console.log('Error', error.message);
          }
          console.log(errorText);

          res = [];
        }
      });

    return res;
  }

  async update(data, url = null) {
    let res;
    const errorText = `Could not update ${this.model}`;

    await axios.put(`/${this.endpoint}${url ? `/${url}` : ''}`, data)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async delete(id) {
    let res;
    const errorText = `Could not delete ${this.model} item`;

    await axios.delete(`/${this.endpoint}/${id}`)
      .then((response) => {
        res = response.data;
      })
      .catch((error) => {
        if (error.response) {
        // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
        // The request was made but no response was received
          console.log(error.request);
        } else {
        // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(errorText);
        res = false;
      });
    return res;
  }

  async storeFormData(data) {
    let res;
    const errorText = `Could not store ${this.model}`;

    try {
      res = await axios.post(`/${this.endpoint}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }

  async softDelete(id) {
    let res;
    const errorText = `Could not delete ${this.model}`;

    try {
      res = await axios.delete(`/${this.endpoint}/${id}`);
      if (res.status === 200) {
        res = true;
      } else {
        console.error(errorText);
        res = false;
      }
    } catch (e) {
      console.error(errorText);
      console.error(e);
      res = false;
    }

    return res;
  }
}

export default Api;

<template>
  <v-app>
    <template v-if="layout === 'login'">
      <router-view />
    </template>
    <template v-else>
      <NavigationDrawer ref="navDrawer" />
      <AppBar @toggle-drawer-size="toggleDrawerSize" />
      <v-main>
        <router-view />
      </v-main>
    </template>
    <Snackbar ref="snackbar" />
    <Spinner ref="loading" />
  </v-app>
</template>

<script>
import BusinessRulesApi from '@/api/BusinessRulesApi';
import PhysicalCountListApi from '@/api/PhysicalCountListApi';
import { mapGetters, mapState } from 'vuex';
import NavigationDrawer from './components/NavigationDrawer.vue';
import AppBar from './components/AppBar.vue';
import Snackbar from './components/Snackbar';
import Spinner from './components/Spinner';
import Bus from './bus';

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    AppBar,
    Snackbar,
    Spinner,
  },
  data: () => ({
    mini: false,
    clients: null,
    jobs: null,
    consumption: null,
    profiles: null,
    inventory: null,
    equipment: null,
  }),
  computed: {
    layout() {
      return this.$route.name === 'login' ? 'login' : 'default';
    },
    ...mapGetters([
      'isLoggedIn',
      'getUserRoute',
    ]),
    ...mapState({
      isDarkMode: 'isDarkMode',
    }),
  },

  watch: {
    isLoggedIn: {
      handler(value) {
        if (!value) return;
        this.init();
      },
    },
  },
  created() {
    this.$vuetify.theme.dark = this.isDarkMode;
    document.title = 'BALANCED+ ERP';
    if (!this.isLoggedIn) return;
    this.init();
  },
  methods: {
    async init() {
      const userRoute = this.getUserRoute;
      if (!userRoute) {
        this.$store.dispatch('logout');
        this.$router.push('/login');
        return;
      }

      Bus.$on('alert', (type, message) => {
        if (type === 'success') {
          this.$refs.snackbar.showSuccess(message);
        } else {
          this.$refs.snackbar.showError(message);
        }
      });

      if (this.layout === 'default') {
        this.initDrawer();
      }

      const res = await Promise.all([this.getModules(), this.getBusinessRules(), this.getActivePhysicalCount(), this.getInventoryClasses()]);
      if (!res) return;

      if (this.$route.path !== userRoute) this.$router.push(`${userRoute}`);
    },
    async initDrawer() {
      if (screen.width < 1500) {
        this.mini = true;
      }
      await this.$nextTick();
      this.$refs.navDrawer.miniVariant = this.mini;
    },
    toggleDrawerSize() {
      this.$refs.navDrawer.miniVariant = !this.$refs.navDrawer.miniVariant;
    },

    async getBusinessRules() {
      const businessRules = await BusinessRulesApi.getList();
      this.$store.commit('storeBusinessRules', businessRules);
    },

    async getModules() {
      const modules = await this.$api.adminModule.getList();
      this.$store.commit('storeAdminModules', modules);
    },

    async getActivePhysicalCount() {
      const physicalCountList = await PhysicalCountListApi.getActive();
      if (physicalCountList) this.$store.commit('storeActivatedPhysicalCount', physicalCountList);
    },

    async getInventoryClasses() {
      const submitData = {
        filters: {},
      };

      const res = await this.$api.inventoryClass.store(submitData, 'read');

      if (!res) return;

      this.$store.commit('storeInvClasses', res);
    },
  },
};
</script>
<style>
/* Last column Actions padding */
.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  padding-right: 5px;
}
/* Last column buttons padding */
.v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child{
  padding-right: 5px;
}
.v-data-table__wrapper > table > tbody > tr:last-child > td:last-child{
  padding-right: 5px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
    width: 11px;
}
::-webkit-scrollbar:horizontal {
    height: 11px;
}
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
}
::-webkit-scrollbar-track {
    background-color: #F5F5F5;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  background-color: #323232;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th > span {
  color: #FFFFFF;
}

.v-btn--outlined:hover
{
    background-color: currentColor;
    opacity: 1;
}

.v-btn--outlined:hover > span
{
    color: #ffffff !important;
    opacity: 1;
}

.theme--light.v-list-item .v-list-item__mask {
    color: rgba(0, 0, 0);
    background: #fc0 !important;
}
</style>

<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="2">
        <v-autocomplete
          v-model="customerFilter"
          :items="clients"
          item-text="name"
          item-value="id"
          clearable
          hide-details
        >
          <template slot="label">
            Customer
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="projectManagerFilter"
          :items="projectManagers"
          item-text="full_name"
          item-value="id"
          clearable
          hide-details
        >
          <template slot="label">
            Project Manager
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="estimatorFilter"
          :items="estimators"
          item-text="full_name"
          item-value="id"
          clearable
          hide-details
        >
          <template slot="label">
            Estimator
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
      >
        <v-menu
          ref="menu"
          v-model="dateMenu"
          :return-value.sync="filterDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              label="Created Date"
              readonly
              v-bind="attrs"
              hide-details
              clearable
              v-on="on"
              @change="$refs.menu.save(filterDate)"
            />
          </template>
          <v-date-picker
            v-model="filterDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDate = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Scope Of Project
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :expanded.sync="expandedItems"
            :loading="loading"
            show-expand
            dense
          >
            <template #item="{ item }">
              <tr
                :class="`${itemRowBackground(item)}`"
              >
                <td
                  class="pl-1 text-end pr-1 pl-1"
                  :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important; border-left: 1.5px solid #000000 !important;': ''}`"
                >
                  <v-icon
                    v-if="item.sub_items"
                    @click="toggleExpand(item)"
                  >
                    {{ expandedItems.includes(item) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                  </v-icon>
                </td>
                <td
                  :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`"
                >
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.group_id }}
                  </text-highlight>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.revision_number }}
                  </text-highlight>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <text-highlight
                    style="font-weight: bold;"
                    :queries="search"
                  >
                    {{ item.project_name }}
                  </text-highlight>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span v-on="on">
                        {{ item.client_name_short }}
                      </span>
                    </template>
                    <span>{{ item.client_name }}</span>
                  </v-tooltip>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <v-tooltip
                    top
                    color="black"
                  >
                    <template #activator="{ on }">
                      <span v-on="on">{{ item.address_simple ? item.address_simple : '' }}</span>
                    </template>

                    <text-highlight
                      :queries="search"
                    >
                      {{ item.address_full ? item.address_full : '' }}
                    </text-highlight>
                  </v-tooltip>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.project_manager_name }}
                  </text-highlight>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.estimator_name }}
                  </text-highlight>
                </td>
                <td :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important;': ''}`">
                  <text-highlight
                    :queries="search"
                  >
                    {{ item.created_at }}
                  </text-highlight>
                </td>
                <td
                  class="text-end pr-1"
                  :style="`${expandedItems.includes(item) ? 'border-top: 1.5px solid #000000 !important; border-right: 1.5px solid #000000 !important;': ''}`"
                >
                  <v-btn
                    color="purple"
                    class="ml-1 mt-1 mb-1"
                    outlined
                    small
                    @click="downloadPdf(item)"
                  >
                    PDF
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="primary"
                    outlined
                    small
                    @click="openEditForm(item.id)"
                  >
                    Edit
                  </v-btn>
                  <v-btn
                    class="ml-1 mt-1 mb-1"
                    color="error"
                    outlined
                    small
                    @click="softDelete(item)"
                  >
                    Delete
                  </v-btn>
                </td>
              </tr>
            </template>
            <template #expanded-item="{ headers, item }">
              <td
                v-if="item.sub_items && item.sub_items.length > 0"
                class="pl-0 pr-0"
                :colspan="10"
                style="border-bottom: 1.5px solid #000000 !important; border-right: 1.5px solid #000000 !important; border-left: 1.5px solid #000000 !important;"
              >
                <v-container
                  fluid
                  :style="`background-color: ${item.index % 2 === 0 ? 'white' : '#E8F5E9'} !important;`"
                >
                  <v-row
                    v-for="sub_item, index in item.sub_items"
                    :key="sub_item.id"
                    align="center"
                    :style="`border-bottom: ${index === item.sub_items.length - 1 ? 'none' : '1px solid #c1c1c1'};`"
                  >
                    <v-col
                      class="pt-1 pb-1 pl-1"
                      cols="auto"
                      :style="`width: ${headers[0].width} !important;`"
                    />
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[1].width} !important;`"
                    >
                      <text-highlight
                        :queries="search"
                        style="font-size: 14px;"
                      >
                        {{ sub_item.id }}
                      </text-highlight>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[2].width} !important;`"
                    >
                      <text-highlight
                        :queries="search"
                        style="font-size: 14px;"
                      >
                        {{ sub_item.revision_number }}
                      </text-highlight>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[3].width} !important;`"
                    >
                      <text-highlight
                        style="font-weight: bold; font-size: 14px;"
                        :queries="search"
                      >
                        {{ sub_item.project_name }}
                      </text-highlight>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[4].width} !important;`"
                    >
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on }">
                          <span
                            style="font-size: 14px;"
                            v-on="on"
                          >
                            {{ sub_item.client_name_short }}
                          </span>
                        </template>
                        <span>{{ sub_item.client_name }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[5].width} !important;`"
                    >
                      <v-tooltip
                        top
                        color="black"
                      >
                        <template #activator="{ on }">
                          <span
                            style="font-size: 14px;"
                            v-on="on"
                          >{{ sub_item.address_simple ? sub_item.address_simple : '' }}</span>
                        </template>

                        <text-highlight
                          :queries="search"
                          style="font-size: 14px;"
                        >
                          {{ sub_item.address_full ? sub_item.address_full : '' }}
                        </text-highlight>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[6].width} !important;`"
                    >
                      <text-highlight
                        :queries="search"
                        style="font-size: 14px;"
                      >
                        {{ sub_item.project_manager_name }}
                      </text-highlight>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[7].width} !important;`"
                    >
                      <text-highlight
                        :queries="search"
                        style="font-size: 14px;"
                      >
                        {{ sub_item.estimator_name }}
                      </text-highlight>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2 pl-4"
                      cols="auto"
                      :style="`width: ${headers[8].width} !important; font-size: 14px;`"
                    >
                      {{ sub_item.created_at }}
                    </v-col>
                    <v-col
                      class="pt-1 pb-1 pl-4 pr-1 text-end"
                      cols="auto"
                      :style="`width: ${headers[9].width} !important;`"
                    >
                      <v-btn
                        color="purple"
                        class="mr-1"
                        outlined
                        small
                        @click="downloadPdf(sub_item)"
                      >
                        PDF
                      </v-btn>
                      <v-btn
                        color="primary"
                        class=""
                        outlined
                        small
                        @click="openViewForm(sub_item.id)"
                      >
                        View
                      </v-btn>
                      <!-- <v-btn
                        color="error"
                        outlined
                        small
                        @click="softDeleteRevision(sub_item.id)"
                      >
                        DELETE
                      </v-btn> -->
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
    <scope-of-project-form
      ref="scopeOfProjectForm"
      @success="get"
    />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import ScopeOfProjectForm from '@/components/forms/scopeOfProjects/ScopeOfProjectForm';
import { mapState } from 'vuex';
import { saveAs } from 'file-saver';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    TextHighlight,
    ScopeOfProjectForm,
  },
  data: () => ({
    items: [],
    headers: [
      { text: '', value: 'data-table-expand', width: '2%' },
      { text: 'Id', value: 'id', width: '5%' },
      { text: 'Rev#', value: 'revision_number', width: '5%' },
      { text: 'Project Name', value: 'project_name', width: '15%' },
      { text: 'Client', value: 'client', width: '10%' },
      { text: 'Job Address', value: 'job_address', width: '16%' },
      { text: 'Project Manager', value: 'project_manager_name', width: '10%' },
      { text: 'Estimator', value: 'estimator_name', width: '10%' },
      { text: 'Created At', value: 'created_at', width: '10%' },
      {
        text: 'Actions', value: 'actions', width: '17%', align: 'end',
      },

    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    loading: false,
    expandedItems: [],
    clients: [],
    projectManagers: [],
    estimators: [],
    customerFilter: null,
    projectManagerFilter: null,
    estimatorFilter: null,
    filterDate: null,
    dateMenu: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
      isDarkMode: 'isDarkMode',
    }),
  },
  watch: {
    customerFilter() {
      this.get();
    },
    projectManagerFilter() {
      this.get();
    },
    estimatorFilter() {
      this.get();
    },
    filterDate(val) {
      this.$refs.menu.save(val);
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.get();
      this.getClients();
      this.getProjectManagers();
      this.getEstimators();
    },
    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          is_sub_items: true,
          client_id: this.customerFilter,
          project_manager_id: this.projectManagerFilter,
          estimator_id: this.estimatorFilter,
          date: this.filterDate,
        },
      };
      const res = await this.$api.scopeOfProject.read(submitModel);
      if (!res || !res.data) {
        this.loading = false;
        return;
      }
      this.items = res.data;
      this.expandedItems = [];
      this.loading = false;
    },

    async getClients() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };
      this.clients = await this.$api.client.read(readModel);
    },

    async getProjectManagers() {
      const roleIdProjectManager = 20;
      const submitModel = {
        filters: {
          role_id: roleIdProjectManager,
        },
      };
      this.projectManagers = await this.$api.user.read(submitModel);
    },

    async getEstimators() {
      const roleIdEstimator = 19;
      const submitModel = {
        filters: {
          role_id: roleIdEstimator,
        },
      };
      this.estimators = await this.$api.user.read(submitModel);
    },

    openForm() {
      this.$refs.scopeOfProjectForm.open();
    },

    openEditForm(id) {
      this.$refs.scopeOfProjectForm.open(id);
    },

    openViewForm(id) {
      this.$refs.scopeOfProjectForm.open(id, true);
    },

    itemRowBackground(item) {
      let itemClass = '';
      const rowEven = this.isDarkMode ? 'row-even-dark' : 'row-even';
      const rowOdd = this.isDarkMode ? 'row-odd-dark' : 'row-odd';
      itemClass += item.index % 2 === 0 ? rowEven : rowOdd;
      itemClass += ` ${item.status_id === 1 ? 'hide-icon' : ''}`;
      return itemClass;
    },

    async softDelete(groupId) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this Scope of Project record and it\'s revisions?',
      });
      if (!confirm) return;
      if (!groupId) return;
      const res = await this.$api.scopeOfProject.delete(groupId);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async softDeleteRevision(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this revision?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.scopeOfProject.delete(`revision/${id}`);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    async downloadPdf(item) {
      if (!item) return;
      const pdf = await this.$api.scopeOfProject.get(`download/${item.id}`, { responseType: 'blob' });
      if (!pdf) {
        this.$bus.$emit('showError');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.project_name}_Scope_Of_Project_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
    },

    toggleExpand(item) {
      if (!item) return;
      const index = this.expandedItems.indexOf(item);
      if (index > -1) {
        this.expandedItems.splice(index, 1);
      } else {
        this.expandedItems.push(item);
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    /* -webkit-box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%); */
    box-shadow: none !important;
}

::v-deep .row-odd{
  background-color: #E8F5E9 !important;
}

::v-deep .row-odd-dark{
  background-color: rgba(0, 77, 64, .1) !important;
}

::v-deep .row-even{
  background-color: white !important;
}

::v-deep .row-even-dark{
  background-color: #000 !important;
}

::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}
</style>

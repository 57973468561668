<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :max-width="maxWidth + 'px'"
      :max-height="maxHeight + 'px'"
      :fullscreen="fullScreen"
      scrollable
      :persistent="persistent"
    >
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-card>
          <v-card-title>
            <slot name="header" />
          </v-card-title>
          <v-card-text>
            <slot name="body" />
          </v-card-text>
          <v-card-actions>
            <span
              v-if="warningText"
              style="color: red;"
              class="pl-5"
            >{{ warningText }}</span>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="close"
            >
              {{ cancelBtnText }}
            </v-btn>
            <v-btn
              v-if="isSubmitBtn"
              color="primary"
              :loading="btnLoading"
              :disabled="submitBtnDisabled"
              @click="submitFunc"
            >
              {{ submitBtnText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props:
    {
      maxWidth: {
        type: Number,
        default: 500,
      },
      maxHeight: {
        type: Number,
        default: 1000,
      },
      submit: {
        type: Function,
        default: () => false,
      },
      submitBtnText: {
        type: String,
        default: 'Create',
      },
      cancelBtnText: {
        type: String,
        default: 'Cancel',
      },
      warningText: {
        type: String,
        default: '',
      },
      fullScreen: {
        type: Boolean,
        default: false,
      },
      isSubmitBtn: {
        type: Boolean,
        default: true,
      },
      persistent: {
        type: Boolean,
        default: false,
      },
      btnLoading: {
        type: Boolean,
        default: false,
      },
      submitBtnDisabled: {
        type: Boolean,
        default: false,
      },
    },
  data: () => ({
    dialog: false,
  }),
  methods: {

    async submitFunc() {
      if (!await this.$refs.form.validate()) return;
      const res = await this.submit();
      if (!res) return;
      this.close();
    },

    open() {
      if (this.$refs.form) { this.$refs.form.reset(); }
      this.dialog = true;
    },

    close() {
      if (this.$refs.form) { this.$refs.form.reset(); }
      this.dialog = false;
    },
  },
};
</script>

<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="700"
    :submit-btn-text="item.id ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ item.id ? 'Update' : 'Create' }} Supplier</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col class="pb-0 pt-5">
          <span style="color: #000000; font-size: 18px; font-weight: 400;">Main information</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <inventory-class-select-element
            v-model="item.inventory_class_ids"
            :multiple="true"
            :is-required="true"
            :is-add-all="false"
          />
        </v-col>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.code"
            :rules="[(v) => !!v || `Code is required`]"
          >
            <template slot="label">
              Code<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.name"
            :rules="[(v) => !!v || `Name is required`]"
          >
            <template slot="label">
              Name<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.contact"
            :rules="[(v) => !!v || `Contact is required`]"
          >
            <template slot="label">
              Contact<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="5"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.phone_number"
            label="Phone Number"
          />
        </v-col>
        <v-col
          cols="3"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.term"
            label="Term"
            type="number"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 pt-5">
          <span style="color: #000000; font-size: 18px; font-weight: 400;">Address</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="3"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.unit"
            label="Unit"
            required
            autocomplete="preventAutocomplete"
          />
        </v-col>
        <v-col
          cols="3"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.building"
            :rules="[(v) => !!v || 'Building is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Building<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.street"
            :rules="[(v) => !!v || 'Street is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Street<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.city"
            :rules="[(v) => !!v || 'City is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              City<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.address.region_id"
            :items="regions"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Province/State is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Province/State<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-text-field
            v-model="item.address.postal_code"
            label="Postal Code"
            autocomplete="preventAutocomplete"
          />
        </v-col>
        <v-col
          cols="6"
          class="pb-0 pt-0"
        >
          <v-autocomplete
            v-model="item.address.country_id"
            :items="countries"
            item-text="name"
            item-value="id"
            :rules="[(v) => !!v || 'Country is required']"
            required
            autocomplete="preventAutocomplete"
          >
            <template slot="label">
              Country<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      name: null,
      code: null,
      phone_number: null,
      term: null,
      contact: null,
      inventory_class_ids: [],
      address: {
        unit: null,
        building: null,
        street: null,
        city: null,
        region_id: 9,
        postal_code: null,
        country_id: 1,
      },
    },
    regions: [],
    countries: [],
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
    this.getCountries();
    this.getRegions();
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.supplier.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getCountries() {
      this.countries = await this.$api.country.get();
    },
    async getRegions() {
      this.regions = await this.$api.region.get();
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.supplier.update(this.item);
      } else {
        res = await this.$api.supplier.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.$refs.dialogBox.open();
    },

    formatPhoneNumber() {
      this.item.phone_number = FormatHelpers.formatPhoneNumber(this.item.phone_number);
    },
  },
};
</script>

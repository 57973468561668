<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-btn
          text
          @click="goBack"
        >
          <v-icon>
            fas fa-arrow-left
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        md="3"
        sm="12"
        class="text-center mt-1"
      >
        <h3 color="primary">
          Paint Purchase Order #{{ poNumber }}
        </h3>
      </v-col>
      <v-col
        cols="7"
        md="7"
        sm="12"
        class="text-right"
      >
        <v-btn
          color="primary"
          outlined
          @click="scanQr"
        >
          SCAN QR
        </v-btn>
      </v-col>
    </v-row>
    <v-row :style="`background-color: ${hexToRgb('#E0E0E0')}; margin:10px 0 0 0`">
      <v-col>
        <v-row>
          <v-col
            cols="2"
            class="text-center"
          >
            <span class="h5 font-weight-bold">
              Class:
            </span> <v-chip
              v-if="purchaseOrder.class"
              class="ml-1"
              small
              :color="purchaseOrder && purchaseOrder.class ? purchaseOrder.class.color : 'grey'"
              text-color="white"
            >
              {{ purchaseOrder && purchaseOrder.class ? purchaseOrder.class.name : '' }}
            </v-chip>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span class="h5 font-weight-bold">
              Repaint to Color:
            </span> <span class="value"> {{ colorCode }}</span>
          </v-col>
          <v-col
            cols="4"
            class="text-center"
          >
            <span class="h5 font-weight-bold">
              Job:
            </span> <span class="value"> {{ purchaseOrder ? purchaseOrder.job_name : '-' }}</span>
          </v-col>
          <v-col
            cols="3"
          >
            <span class="h5 font-weight-bold">
              Created At:
            </span> <span class="value"> {{ purchaseOrder ? purchaseOrder.created_at : '' }}</span>
          </v-col>
        </v-row>
        <v-row v-if="purchaseOrderLoading">
          <v-col>
            <v-progress-linear
              indeterminate
              rounded
              height="4"
              color="primary"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="tableHeaders"
            :items="purchaseOrderItems"
            :footer-props="footer"
            :items-per-page="100"
            :search="search"
            :loading="loading"
            dense
            show-expand
            :expanded.sync="expandedItems"
            :item-class="itemRowBackground"
          >
            <template #[`item.item_name`]="{ item }">
              <span style="font-weight: bold;"><text-highlight :queries="search">
                {{ item.item_name ? item.item_name : '' }}
              </text-highlight>
              </span>
            </template>
            <template #[`item.length_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.length_name ? item.length_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.uom_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.uom_name ? item.uom_name : '' }}
              </text-highlight>
            </template>
            <template #[`item.quantity_required`]="{ item }">
              {{ item.quantity_required }}
            </template>
            <template #[`item.quantity_remained`]="{ item }">
              <span style="color: red;">{{ item.quantity_remained }}</span>
            </template>
            <template #[`item.quantity_fulfilled`]="{ item }">
              <span>{{ Math.abs(item.quantity_required - item.quantity_remained) }}</span>
            </template>
            <template #[`item.note`]="{ item }">
              <text-highlight :queries="search">
                {{ item.note ? item.note : 'MISSING' }}
              </text-highlight>
              <v-icon
                small
                color="primary"
                @click="openNoteEdit(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item && item.status ? item.status.color : ''"
                text-color="white"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-row v-if="item.quantity_remained != 0">
                <v-col>
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="openLocateForm(item)"
                  >
                    Locate
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template #expanded-item="{ headers, item }">
              <td
                v-if="item.status_id != 1"
                :colspan="headers.length"
                class="pl-0 pr-0"
              >
                <v-container
                  fluid
                  :style="`background-color: ${item.index % 2 === 0 ? 'white' : '#E8F5E9'} !important;`"
                >
                  <v-row
                    v-for="supply_to_paint_tag, index in item.supply_to_paint_tags"
                    :key="supply_to_paint_tag.id"
                    align="center"
                    justify="center"
                    :style="`border-bottom: ${index === item.supply_to_paint_tags.length - 1 ? 'none' : '1px solid #c1c1c1'};`"
                  >
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Qr Code: </span><span style="font-size: 16px; font-weight: bold">{{ supply_to_paint_tag.inventory_tag }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Color: </span><span style="font-weight: bold">{{ supply_to_paint_tag.tag && supply_to_paint_tag.tag.color ? supply_to_paint_tag.tag.color.code : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Location: </span><span style="font-weight: bold">{{ supply_to_paint_tag.tag && supply_to_paint_tag.tag.location ? supply_to_paint_tag.tag.location.name : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Shelf: </span><span style="font-weight: bold">{{ supply_to_paint_tag.tag && supply_to_paint_tag.tag.inventory_location ? supply_to_paint_tag.tag.inventory_location.name : '' }}</span>
                    </v-col>
                    <v-col
                      class="pt-2 pb-2"
                      cols="2"
                    >
                      <span>Quantity Used: </span><span style="font-weight: bold">{{ supply_to_paint_tag.quantity }}</span>
                    </v-col>
                    <v-spacer />
                    <v-col
                      class="pt-0 pb-0"
                      cols="auto"
                    >
                      <v-btn
                        color="error"
                        outlined
                        small
                        @click="deleteTag(supply_to_paint_tag.id)"
                      >
                        DELETE
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="qrCodeDialog"
      fullscreen
      persistent
      hide-overlay
    >
      <v-card>
        <v-card-title>
          <v-col class="text-right">
            <v-btn
              icon
              @click="qrCodeDialog = false"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-container fluid>
          <inventory-qr
            :is-paint-supply="true"
            @return-qr-code="returnQrCode"
          />
        </v-container>
      </v-card>
    </v-dialog>
    <supply-to-paint-qr-form
      ref="paintQrForm"
      @success="getPurchaseOrderItems"
    />
    <supply-to-paint-note-form
      ref="noteForm"
      @success="getPurchaseOrderItems"
    />
    <supply-to-paint-locate-form
      ref="locateForm"
      @success="getPurchaseOrderItems"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InventoryQr from '@/views/InventoryQrs/InventoryQr';
import SupplyToPaintQrForm from '@/components/forms/supplyToPaints/SupplyToPaintQrForm';
import SupplyToPaintNoteForm from '@/components/forms/supplyToPaints/SupplyToPaintNoteForm';
import SupplyToPaintLocateForm from '@/components/forms/supplyToPaints/SupplyToPaintLocateForm';

export default {
  components: {
    InventoryQr,
    SupplyToPaintQrForm,
    SupplyToPaintNoteForm,
    SupplyToPaintLocateForm,
  },
  props: {

  },
  data: () => ({
    purchaseOrderItems: [],
    purchaseOrder: {},
    colorCode: '',
    poNumber: null,
    inventoryClassId: null,
    loading: false,
    qrCodeDialog: false,
    expandedItems: [],
    tableHeaders: [
      { text: 'Item', value: 'item_name' },
      { text: 'Note', value: 'note' },
      { text: 'Length', value: 'length_name' },
      { text: 'UOM', value: 'uom_name' },
      { text: 'Qty Required', value: 'quantity_required' },
      { text: 'Qty Not Fulfilled', value: 'quantity_remained' },
      { text: 'Qty Fulfilled', value: 'quantity_fulfilled' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Actions', value: 'actions', align: 'center' },
      { text: '', value: 'data-table-expand' },
    ],
    footer: {
      'items-per-page-options': [100, 500, 1000, -1],
    },
    purchaseOrderLoading: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  created() {
    this.poNumber = this.$route.params.po_number;
    this.inventoryClassId = this.$route.params.inventory_class_id;
    this.init();
  },
  methods: {
    init() {
      this.getPurchaseOrderItems();
      this.getPurchaseOrder();
    },

    async getPurchaseOrderItems() {
      this.purchaseOrderItems = [];
      if (!this.poNumber || !this.inventoryClassId) return;
      this.loading = true;
      const readModel = {
        filters:
        {
          po_number: this.poNumber,
          inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.supplyToPaint.readPurchaseOrderItem(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.purchaseOrderItems = res;

      this.purchaseOrderItems.forEach((x, i) => {
        // eslint-disable-next-line no-param-reassign
        x.index = i;
      });
      // all items are repainted to the same color
      this.colorCode = res[0].color_code;

      this.expandedItems = res.filter((x) => x.supply_to_paint_tags.length > 0);
      this.loading = false;
    },

    async getPurchaseOrder() {
      if (!this.poNumber || !this.inventoryClassId) return;
      this.purchaseOrderLoading = true;
      const readModel = {
        filters:
        {
          po_number: this.poNumber,
          inventory_class_id: this.inventoryClassId,
        },
      };
      const res = await this.$api.supplyToPaint.readPurchaseOrder(readModel);
      if (!res || res.length === 0) return;
      const firstIndex = 0;
      this.purchaseOrder = res.data[firstIndex];
      this.purchaseOrderLoading = false;
    },

    async deleteTag(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.supplyToPaint.delete(id);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getPurchaseOrderItems();
    },

    scanQr() {
      this.qrCodeDialog = true;
    },

    goBack() {
      this.$router.push('/supply-to-paints');
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },

    returnQrCode(qrCode) {
      this.qrCodeDialog = false;
      this.$refs.paintQrForm.open(qrCode, this.poNumber, this.inventoryClassId);
    },

    itemRowBackground(item) {
      let itemClass = '';
      itemClass += item.index % 2 === 0 ? 'row-even' : 'row-odd';
      itemClass += ` ${item.status_id === 1 ? 'hide-icon' : ''}`;
      return itemClass;
    },

    openNoteEdit(item) {
      this.$refs.noteForm.open(item.id, item.note, item.inventory_class_id);
    },

    openLocateForm(item) {
      this.$refs.locateForm.open(item.id);
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    /* -webkit-box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 75%), inset 0px -4px 8px -5px rgb(50 50 50 / 75%); */
    box-shadow: none !important;
}

::v-deep .row-odd{
  background-color: #E8F5E9 !important;
}

::v-deep .row-even{
  background-color: white !important;
}

::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .hide-icon.v-icon.v-icon.v-icon--link {
    display: none !important;
}
</style>

<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row align="center">
      <v-col
        cols="2"
        class="pb-0 pt-2"
      >
        <inventory-class-select-element
          v-model="inventoryClasses"
          :is-state="true"
          :multiple="true"
        />
      </v-col>
      <v-spacer />
      <v-col
        class="text-end pt-2"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Supplier
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="100"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.classes`]="{ item }">
              <v-row v-if="item.inventory_classes && item.inventory_classes.length === 11">
                <v-col
                  cols="auto"
                  class="pr-0"
                >
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    color="#000000"
                  >
                    ALL
                  </v-chip>
                </v-col>
              </v-row>
              <v-tooltip
                v-else-if="item.inventory_classes && item.inventory_classes.length > 2"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <span
                    style="color: #1976d2;"
                    v-on="on"
                  >CLASSES</span>
                </template>
                <v-row>
                  <v-col class="text-center pt-6 pb-6">
                    <v-row
                      v-for="inventroyClass in item.inventory_classes"
                      :key="inventroyClass.id"
                    >
                      <v-col
                        cols="auto"
                        class="pr-1 pb-1 pt-1"
                      >
                        <v-chip
                          class="mt-1 mb-1"
                          small
                          text-color="white"
                          style="cursor: pointer;"
                          :color="inventroyClass ? inventroyClass.color : 'white'"
                        >
                          {{ inventroyClass ? inventroyClass.name : '' }}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tooltip>
              <v-row v-else>
                <v-col
                  v-for="inventroyClass in item.inventory_classes"
                  :key="inventroyClass.id"
                  cols="auto"
                  class="pr-0"
                >
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    text-color="white"
                    style="cursor: pointer;"
                    :color="inventroyClass ? inventroyClass.color : 'white'"
                  >
                    {{ inventroyClass ? inventroyClass.name : '' }}
                  </v-chip>
                </v-col>
              </v-row>
            </template>
            <template #[`item.full_name`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-weight: bold;"
              >
                {{ item.full_name }}
              </text-highlight>
            </template>
            <template #[`item.full_address`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.full_address }}
              </text-highlight>
            </template>
            <template #[`item.phone`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.phone_number }}
              </text-highlight>
            </template>
            <template #[`item.contact`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.contact }}
              </text-highlight>
            </template>
            <template #[`item.term`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.term }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEdit(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <supplier-form
      ref="itemForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import SupplierForm from '@/components/forms/SupplierForm';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    SupplierForm,
  },
  data: () => ({
    items: [],
    headers: [
      { text: 'Classes', value: 'classes' },
      { text: 'Name', value: 'full_name' },
      { text: 'Address', value: 'full_address' },
      { text: 'Phone', value: 'phone_number' },
      { text: 'Contact', value: 'contact' },
      { text: 'Term', value: 'term' },
      { text: 'Created At', value: 'created_at_short' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, 100, 500, 10000, -1],
    },
    loading: false,
    inventoryClasses: [],
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([
    ]),
  },
  watch: {
    inventoryClasses() {
      this.get();
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.get();
    },

    async get() {
      this.loading = true;
      const submitModel = {
        filters: {
          inventory_classes: this.inventoryClasses,
        },
      };
      this.items = await this.$api.supplier.read(submitModel);
      this.loading = false;
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.supplier.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.itemForm.open();
    },

    openEdit(id) {
      this.$refs.itemForm.open(id);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>

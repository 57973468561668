<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="itemId ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ itemId ? 'Update' : 'Create' }} Glass Code Max Uqntity</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.g_code"
            :items="gCodes"
            :rules="[(v) => !!v || 'G Code is required']"
          >
            <template slot="label">
              G Code<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="item.quantity"
            type="number"
            :rules="[(v) => !!v || 'Max Quantity is required']"
          >
            <template slot="label">
              Max Quantity<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>

export default {
  data: () => ({
    item: null,
    itemId: 0,
    itemDto: {
      id: 0,
      g_code: null,
      quantity: 0,
    },
    gCodes: [],
  }),
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.glassCodeMaxQuantity.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getGlassCodes() {
      const submitModel = {
        filters: {
        },
      };
      const res = await this.$api.glass.read(submitModel);
      if (!res) return;
      if (this.itemId) {
        this.gCodes = res.map((x) => x.row);
      } else {
        this.gCodes = res.filter((x) => !(x.max_quantity && x.max_quantity.is_deleted === 0) || !x.max_quantity).map((x) => x.row);
      }
      this.gCodes = this.gCodes.filter((x) => x !== null && x !== '');
    },

    async submit() {
      if (!this.item) return false;
      const submitModel = {
        id: this.itemId,
        g_code: this.item.g_code,
        quantity: this.item.quantity,
        created_by: this.$store.getters.user.id,
      };
      let res;
      if (this.itemId) {
        res = await this.$api.glassCodeMaxQuantity.update(submitModel);
      } else {
        res = await this.$api.glassCodeMaxQuantity.store(submitModel);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$emit('success');
      return res;
    },

    open(id) {
      this.itemId = id;
      if (id) {
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
      }
      this.getGlassCodes();
      this.$refs.dialogBox.open();
    },
  },
};
</script>

<template>
  <v-container>
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
    >
      <v-card style="padding: 10px !important; border-radius: 0px !important;">
        <v-row>
          <v-col class="headline">
            Add QR Code to Paint Purchase Order
          </v-col>
          <v-col
            cols="1"
            class="text-end"
          >
            <v-btn
              icon
              @click="closeForm"
            >
              <v-icon
                large
                dark
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
          :style="`background-color: ${hexToRgb('#E0E0E0')}; margin:10px 0 0 0`"
        >
          <v-col
            cols="3"
            class="text-center"
          >
            <span class="headline">QR Code: <span class="font-weight-bold">{{ qrCode }}</span></span>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span class="headline">Color: <span class="font-weight-bold">{{ inventoryTag ? inventoryTag.color_code : '' }}</span> </span>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span class="headline">Location: <span class="font-weight-bold">{{ inventoryTag ? inventoryTag.location_name : '' }}</span> </span>
          </v-col>
          <v-col
            cols="3"
            class="text-center"
          >
            <span class="headline">Shelf: <span class="font-weight-bold">{{ inventoryTag ? inventoryTag.inventory_location_name : '' }}</span> </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <v-form
              ref="form"
            >
              <v-data-table
                class="elevation-1"
                :headers="tableHeaders"
                :items="purchaseOrderItems"
                hide-default-footer
                :loading="loading"
                dense
              >
                <template
                  #[`item.class`]="{ item }"
                >
                  <v-chip
                    small
                    :color="item && item.class ? item.class.color : ''"
                    text-color="white"
                  >
                    {{ item && item.class ? item.class.name : 'All' }}
                  </v-chip>
                </template>
                <template #[`item.item_name`]="{ item }">
                  {{ item.item_name ? item.item_name : '' }}
                </template>
                <template #[`item.quantity`]="{ item }">
                  {{ item.quantity ? item.quantity_required : '' }}
                </template>
                <template #[`item.quantity_remained`]="{ item }">
                  <span style="color: red;">{{ item.quantity_remained }}</span>
                </template>
                <template #[`item.quantity_fulfilled`]="{ item }">
                  <span>{{ Math.abs(item.quantity_required - item.quantity_remained) }}</span>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template #[`item.quantity_on_hand`]="{ item }">
                  {{ inventoryTag ? inventoryTag.quantity_on_hand : '' }}
                </template>
                <template #[`item.length_name`]="{ item }">
                  {{ item.length_name ? item.length_name : '' }}
                </template>
                <template #[`item.uom_name`]="{ item }">
                  {{ item.uom_name ? item.uom_name : '' }}
                </template>
                <template #[`item.note`]="{ item }">
                  {{ item.note ? item.note : '' }}
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-row>
                    <v-col
                      class="pt-7 text-start pl-0"
                    >
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="success"
                        outlined
                        @click="getInFull(item)"
                      >
                        GET IN FULL
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-4"
                    >
                      <v-form
                        ref="quantityForm"
                      >
                        <v-text-field
                          v-model="item.quantity"
                          style="max-width: 100px;"
                          type="number"
                        />
                      </v-form>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-7 pr-5"
                    >
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        @click="submit(item)"
                      >
                        Add
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-end pt-7">
            <v-btn
              color="normal"
              class="mr-2"
              @click="closeForm"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>

export default {
  data: () => ({
    qrCode: null,
    poNumber: null,
    inventoryClassId: null,
    inventoryTag: null,
    purchaseOrderItems: [],
    dialog: false,
    tableHeaders: [
      { text: 'Class', value: 'class' },
      { text: 'Item', value: 'item_name' },
      { text: 'Note', value: 'note' },
      { text: 'Length', value: 'length_name' },
      { text: 'UOM', value: 'uom_name' },
      { text: 'Qty Required', value: 'quantity' },
      { text: 'Qty Not Fulfilled', value: 'quantity_remained' },
      { text: 'Qty Fulfilled', value: 'quantity_fulfilled' },
      { text: 'Qty on Hand', value: 'quantity_on_hand' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '40%',
      },
    ],
    loading: false,
  }),
  methods: {

    async getPurchaseOrderItems() {
      this.purchaseOrderItems = [];
      if (!this.poNumber || !this.inventoryClassId || !this.inventoryTag) return;
      this.loading = true;
      const readModel = {
        filters:
        {
          po_number: this.poNumber,
          inventory_class_id: this.inventoryClassId,
          inventory_item_id: this.inventoryTag.inventory_item_id,
          length_id: this.inventoryTag.length_id,
          uom_id: this.inventoryTag.uom_id,
          is_not_quantity_zero: 1,
        },
      };
      const res = await this.$api.supplyToPaint.readPurchaseOrderItem(readModel);
      this.loading = false;
      if (!res || res.length === 0) {
        this.$bus.$emit('showError', 'There are no Paint Items for this QR Code');
        this.dialog = false;
        return;
      }

      this.purchaseOrderItems = res;
    },

    async getInventoryTag() {
      if (!this.qrCode) return;

      const submitModel = {
        filters: {
          inventory_tag: this.qrCode,
        },
      };

      const res = await this.$api.inventoryTag.read(submitModel);
      if (!res || !res.data || !res.data[0]) {
        this.$bus.$emit('showError', 'This QR Code doesn\'t exist');
        return;
      }

      const firstIndex = 0;
      this.inventoryTag = res.data[firstIndex];
      this.dialog = true;
      this.getPurchaseOrderItems();
    },

    getInFull(item) {
      const submitItem = item;
      if (submitItem.quantity_remained !== item.quantity_required) {
        submitItem.quantity = item.quantity_remained;
      } else { submitItem.quantity = item.quantity_required; }
      this.submit(submitItem);
    },

    async submit(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to get material form this QR Code?',
      });
      if (!confirm) return;
      if (!await this.$refs.quantityForm.validate()) return;
      if (parseInt(item.quantity, 10) > item.quantity_required) {
        this.$bus.$emit('showError', 'Quantity can\'t be greated than Quantity Required');
        return;
      }
      if (parseInt(item.quantity, 10) > this.inventoryTag.quantity_on_hand) {
        this.$bus.$emit('showError', 'Quantity can\'t be greated than Quantity On Hand');
        return;
      }

      const submitItem = {
        inventory_tag: this.inventoryTag.inventory_tag,
        paint_purchase_order_item_id: item.id,
        quantity: parseInt(item.quantity, 10),
        is_return: 0,
        inventory_class_id: item.inventory_class_id,
      };
      const res = await this.$api.supplyToPaint.store(submitItem);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');

      const itemIndex = this.purchaseOrderItems.findIndex((x) => x.id === item.id);
      if (itemIndex === -1) {
        this.$bus.$emit('showError');
        return;
      }
      // if used quantity = quantity remained, remove item from the table

      if (parseInt(item.quantity, 10) === item.quantity_remained) {
        const purchaseOrderItems = [...this.purchaseOrderItems];
        purchaseOrderItems.splice(itemIndex, 1);
        if (purchaseOrderItems.length === 0) {
          this.dialog = false;
          this.$emit('success');
          return;
        }
      }

      // if remained inventory tag quantity less than 0, close form
      this.inventoryTag.quantity_on_hand -= parseInt(item.quantity, 10);
      if (this.inventoryTag.quantity_on_hand <= 0) {
        this.dialog = false;
        this.$emit('success');
      }
      this.getPurchaseOrderItems();
    },

    async open(qrCode, poNumber, inventoryClassId) {
      this.qrCode = qrCode;
      this.poNumber = poNumber;
      this.inventoryClassId = inventoryClassId;
      this.getInventoryTag();
    },

    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      if (result) {
        const rgba = `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, .5)`;
        return rgba;
      }
      return null;
    },

    closeForm() {
      this.$emit('success');
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .v-dialog--fullscreen {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
}
</style>

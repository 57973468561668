<template>
  <v-container fluid>
    <v-card
      elevation="0"
      outlined
    >
      <v-card-title>Cut Sheets</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
        >
          <v-row>
            <v-col cols="3">
              <v-text-field
                v-model="name"
                :rules="[(v) => (!!v || 'Work Order is required')]"
              >
                <template slot="label">
                  Work Orde<RedAsterisk />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                class="mt-3"
                color="primary"
                outlined
                @click="submit"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col class="mt-5">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.name }}
              </text-highlight>
            </template>
            <template #[`item.created_by`]="{ item }">
              {{ item.user? item.user.first_name + ' ' + item.user.last_name : '' }}
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="orange"
                outlined
                small
                @click="download(item, true)"
              >
                PANEL PDF
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="orange"
                outlined
                small
                @click="download(item, false)"
              >
                PROFILE PDF
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="softDelete(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import * as FormatHelpers from '@/helpers/FormatHelpers';
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';

export default {
  data: () => ({
    name: '',
    items: [],
    headers: [
      { text: 'Id', value: 'id' },
      { text: 'Work Order', value: 'name' },
      { text: 'Created By', value: 'created_by' },
      { text: 'Created At', value: 'updated_at' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '70%',
      },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    types: [],
    loading: false,
  }),

  computed:
  {
    ...mapState({
      search: (state) => state.search,
    }),
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.get();
    },
    async get() {
      this.loading = true;
      const submitModel = {};
      this.items = await this.$api.cutSheet.read(submitModel);
      this.loading = false;
    },

    async submit() {
      if (!await this.$refs.form.validate()) return;

      this.$bus.$emit('loading');

      const submitData = {
        name: this.name,
      };

      const res = await this.$api.cutSheet.store(submitData);

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      if (res === '1001') {
        this.$bus.$emit('showError', `Cut Sheet for Work Order ${this.name} already exists`);
        return;
      }

      this.$bus.$emit('showSuccess');
      this.parser = {
        name: '',
      };

      await this.$refs.form.reset();
      this.get();
    },

    async download(item, isPanel) {
      this.$bus.$emit('loading');
      const submitData = {
        path: isPanel ? item.panel_report_path : item.profile_report_path,
      };
      const pdf = await this.$api.cutSheet.store(submitData, 'download', { responseType: 'blob' });
      if (!pdf) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.name}_${isPanel ? 'Panel_Report' : 'Profile_Report'}_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
      this.$bus.$emit('loading');
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this item?',
      });
      if (!confirm) return;
      const res = await this.$api.cutSheet.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },
  },
};
</script>

<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-select
          v-model="filterStatusId"
          label="Status"
          item-text="name"
          item-value="id"
          :items="glassBatchStatuses"
          hide-details="true"
          clearable
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="3"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterJob"
          label="Job"
          :items="jobCodes"
          hide-details="true"
          item-value="code"
          :item-text="item => `${item.code} - ${item.name}; ${item.reference}`"
          clearable
          :loading="loadingJobs"
        >
          <template #selection="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>

          <template #item="{ item }">
            <span>
              <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
        class="pb-0"
      >
        <v-autocomplete
          v-model="filterPo"
          label="PO#"
          :items="poNumbers"
          hide-details="true"
          clearable
          :loading="loadingPo"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-menu
          ref="menu"
          v-model="dateMenu"
          :return-value.sync="filterDate"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="filterDate"
              label="Date Created"
              readonly
              v-bind="attrs"
              v-on="on"
              @change="$refs.menu.save(filterDate)"
            />
          </template>
          <v-date-picker
            v-model="filterDate"
            no-title
            scrollable
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="filterDate = null"
            >
              Clear
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col
        cols="auto"
        class="pt-0 pr-0"
      >
        <v-btn
          color="success"
          cols="auto"
          outlined
          @click="dialogCoverSheet = true"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                class="pr-2"
                v-on="on"
              >
                fas fa-file
              </v-icon>
              <span
                style="font-size: 14px;"
                v-on="on"
              >Cover Sheet</span>
            </template>
            <span>Download Cover Sheet</span>
          </v-tooltip>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="pt-0 pr-0"
      >
        <v-btn
          color="#00ACC1"
          cols="auto"
          outlined
          @click="dialogSourceFile = true"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-icon
                small
                class="pr-2"
                v-on="on"
              >
                mdi-arrow-collapse-down
              </v-icon><span
                style="font-size: 14px;"
                v-on="on"
              >CSV</span>
            </template>
            <span>Download CSV Source File</span>
          </v-tooltip>
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="pt-0 pr-0"
      >
        <v-btn
          color="orange"
          outlined
          @click="openHistory()"
        >
          History
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="pt-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="open"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Auto Batch
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="100"
            :search="search"
            :loading="loading"
            dense
            fixed-header
            :height="windowHeight"
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template #[`item.inventory_class_id`]="{ item }">
              <v-chip
                small
                :color="item && item.class ? item.class.color : 'black'"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : '' }}
              </v-chip>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <span
                class="font-weight-bold"
              >
                <text-highlight
                  style="font-size: 14px !important;"
                  :queries="search"
                >

                  {{ item.batch_number }}
                </text-highlight></span>
            </template>
            <template #[`item.po_number`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.po_number }}
              </text-highlight>
            </template>
            <template #[`item.g_code`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.g_code }}
              </text-highlight>
            </template>
            <template #[`item.glass_thickness`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.glass_thickness }}
              </text-highlight>
            </template>
            <template #[`item.total_glass`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.total_glass }}
              </text-highlight>
            </template>
            <template #[`item.user_full_name`]="{ item }">
              <span><text-highlight
                :queries="search"
              >{{ item.user_full_name }}</text-highlight></span>
            </template>
            <template #[`item.job`]="{ item }">
              <span>
                <span style="font-weight: bold;"><text-highlight :queries="search">{{ item.job_code ? `${item.job_code}` : '' }}</text-highlight></span> - <text-highlight :queries="search">{{ item.job_name ? item.job_name : item.job_name }}</text-highlight> <span style="color: #1976D2;"><text-highlight :queries="search">{{ item.job_reference ? `; ${item.job_reference}` : '' }}</text-highlight></span>
              </span>
            </template>
            <template #[`item.created_at`]="{ item }">
              <span><text-highlight
                :queries="search"
              >{{ item.created_at }}</text-highlight></span>
            </template>
            <template #[`item.status`]="{ item }">
              <!-- <v-select
                v-model="item.status_id"
                :items="glassBatchStatuses"
                hide-details
                item-text="name"
                item-value="id"
                dense
                solo
                class="select-status"
                :background-color="item.status.color"
                style="max-width: 140px; border-radius: 20px; font-size: 12px; color: white !important;"
                @change="changeStatus(item)"
              >
                <template #item="{ item }">
                  <v-chip
                    class="mt-1 mb-1"
                    small
                    style="cursor: pointer;"
                    :color="item ? item.color : 'white'"
                  >
                    <span style="white-space:nowrap; color: #FFFFFF">{{ item ? item.name : '' }}</span>
                  </v-chip>
                </template>
                <template #selection="{ item }">
                  <span style="color: white; white-space:nowrap;">{{ item.name }}</span>
                </template>
              </v-select> -->
              <v-chip
                small
                :color="item && item.status ? item.status.color : 'black'"
                text-color="white"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-row
                align="center"
              >
                <v-col
                  class="pl-1 pr-0 text-end"
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        color="purple"
                        outlined
                        small
                        v-on="on"
                        @click="viewPoItems(item)"
                      >
                        POs
                      </v-btn>
                    </template>
                    <span>View POs</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="auto"
                  class="pl-1 pr-0"
                >
                  <v-tooltip top>
                    <template #activator="{ on }">
                      <v-btn
                        color="success"
                        outlined
                        small
                        v-on="on"
                        @click="viewGlass(item)"
                      >
                        GLASS
                      </v-btn>
                    </template>
                    <span>View GLASS</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="auto"
                  class="pl-1"
                >
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mb-1"
                        elevation="0"
                        fab
                        outlined
                        x-small
                        v-bind="attrs"
                        tile
                        color="primary"
                        style="border-radius: 7px; width: 28px !important; height: 28px !important;"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                      class="pa-3"
                    >
                      <v-list-item
                        :disabled="!item.cover_sheet_report_id"
                        @click="downloadCoverSheet(item)"
                      >
                        <v-list-item-title style="color: green; font-weight: 600;">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                color="green"
                              >
                                fas fa-file
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              COVER SHEET
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="!item.file_id"
                        @click="download(item)"
                      >
                        <v-list-item-title style="color: #00ACC1; font-weight: 600;">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                              style="white-space: nowrap;"
                            >
                              <v-icon
                                style="font-size: 18px;"
                                color="#00ACC1"
                              >
                                mdi-arrow-collapse-down
                              </v-icon>
                            </v-col>
                            <v-col class="pl-2 pt-4">
                              CSV SOURCE FILE
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="openHistory(item.id)"
                      >
                        <v-list-item-title style="color: orange; font-weight: 600;">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0"
                            >
                              <v-icon
                                style="font-size: 16px;"
                                color="orange"
                              >
                                fas fa-history
                              </v-icon>
                            </v-col>
                            <v-col class="pt-4">
                              HISTORY
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        :disabled="item.status_id !== glassBatchStatusIdNew"
                        @click="softDelete(item.id)"
                      >
                        <v-list-item-title style="color: #ff5252; font-weight: 600;">
                          <v-row align="center">
                            <v-col
                              cols="auto"
                              class="pr-0 pt-2"
                            >
                              <v-icon
                                color="error"
                                style="font-size: 16px;"
                              >
                                fa-trash
                              </v-icon>
                            </v-col>
                            <v-col>
                              DELETE
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogCoverSheet"
      max-width="500px"
      :persistent="true"
    >
      <v-form
        ref="coverSheetForm"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Download PO Cover Sheets</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="downloadPoNumber"
                  label="PO#"
                  :rules="[(v) => !!v || 'PO# is required']"
                >
                  <template slot="label">
                    PO#<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialogCoverSheet = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadCoverSheetsPo()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="dialogSourceFile"
      max-width="500px"
      :persistent="true"
    >
      <v-form
        ref="sourceFileForm"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Download CSV Source File</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="downloadSourceFilePoNumber"
                  label="PO#"
                  :rules="[(v) => !!v || 'PO# is required']"
                >
                  <template slot="label">
                    PO#<RedAsterisk />
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialogSourceFile = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadSourceFilePO()"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <glass-batch-form
      ref="batchForm"
      @success="success"
    />
    <glass-batch-history-form
      ref="batchHistoryForm"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import GlassBatchForm from '@/components/forms/glasses/GlassBatchForm';
import GlassBatchHistoryForm from '@/components/forms/glasses/GlassBatchHistoryForm';
import { saveAs } from 'file-saver';
import { mapState } from 'vuex';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  components: {
    TextHighlight,
    GlassBatchForm,
    GlassBatchHistoryForm,
  },
  props: {
    jobCode: {
      type: Number,
      default: 0,
    },
    poNumber: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    items: [],
    poNumbers: [],
    jobCodes: [],
    glassBatchStatuses: [],
    selectedLocationId: 0,
    headers: [
      {
        text: 'Class', value: 'inventory_class_id', align: 'center', class: 'pl-1', width: '5%',
      },
      {
        text: 'Job', value: 'job', class: 'pl-1', width: '15%',
      },
      {
        text: 'PO#', value: 'po_number', class: 'pl-1', width: '5%',
      },
      {
        text: 'Batch#', value: 'batch_number', class: 'pl-1', width: '3%',
      },
      {
        text: 'Glass Code', value: 'g_code', class: 'pl-1', width: '5%',
      },
      {
        text: 'Glass Thickness', value: 'glass_thickness', class: 'pl-1', width: '7%',
      },
      {
        text: 'Qty', value: 'total_glass', class: 'pl-1', width: '2%',
      },
      {
        text: 'Created At', value: 'created_at', class: 'pl-1', width: '8%',
      },
      {
        text: 'Created By', value: 'user_full_name', class: 'pl-1', width: '10%',
      },
      {
        text: 'Status', value: 'status', class: 'pl-1', width: '10%', align: 'center',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '10%',
      },
    ],
    footer: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    loading: false,
    inventoryClassId: null,
    filterJob: null,
    filterPo: null,
    dateMenu: false,
    filterDate: null,
    glassBatchStatusIdNew: 1,
    filterStatusId: null,
    topSpaceHeight: 270,
    windowHeight: 1000,
    jobLoading: false,
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    total: 0,
    downloadPoNumber: null,
    dialogCoverSheet: false,
    downloadSourceFilePoNumber: null,
    dialogSourceFile: false,
    loadingPo: false,
    loadingJobs: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  watch: {
    inventoryClassId() {
      this.get();
    },
    filterJob() {
      this.get();
    },
    filterPo() {
      this.get();
    },
    filterStatusId() {
      this.get();
    },
    filterDate(val) {
      this.$refs.menu.save(val);
      this.get();
    },
    pagination() {
      this.get();
    },
    search() {
      this.get();
    },
  },
  created() {
    if (this.jobCode) { this.filterJob = this.jobCode; }
    if (this.poNumber) this.filterPo = this.poNumber;
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    init() {
      this.getStatuses();
      this.getPoItems();
      this.getJobs();
    },

    async get() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.items = [];
      this.total = 0;
      const submitModel = {
        filters: {
          inventory_class_id: this.inventoryClassId,
          job_code: this.filterJob,
          po_number: this.filterPo,
          date: this.filterDate,
          status_id: this.filterStatusId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.glassBatch.readBatches(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      this.init();
      if (!res || !res.data || res.data.length === 0) {
        this.items = [];
        this.total = 0;
        return;
      }
      this.items = res.data;
      this.total = res.total;
    },

    async getPoItems() {
      this.loadingPo = true;
      const res = await this.$api.glass.get(`po-numbers/${this.inventoryClassId}/${null}`);
      if (!res) {
        this.poNumbers = [];
        this.loadingPo = false;
        return;
      }
      this.poNumbers = res;
      this.loadingPo = false;
    },

    async getJobs() {
      this.loadingJobs = true;
      const submitModel = {
        filters: { inventory_class_id: this.inventoryClassId },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res) {
        this.jobCodes = [];
        this.loadingJobs = false;
        return;
      }
      this.jobCodes = res;
      this.loadingJobs = false;
    },

    async getStatuses() {
      this.glassBatchStatuses = await this.$api.glassBatchStatus.get();
    },

    async softDelete(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.glassBatch.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.get();
    },

    open() {
      this.$refs.batchForm.open(this.inventoryClassId, this.filterPo);
    },

    openHistory(batchId = null) {
      this.$refs.batchHistoryForm.open(batchId);
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.get();
    },

    viewGlass(item) {
      this.$router.push({
        name: 'GlassItems',
        params: {
          batchId: item.id,
        },
      });
    },

    viewPoItems(item) {
      this.$router.push({
        name: 'GlassPoView',
        params: {
          batchId: item.id,
        },
      });
    },
    async changeStatus(item) {
      const res = await this.$api.glassBatch.get(`status/${item.id}/${item.status_id}`);
      if (!res) return;
      this.get();
    },

    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    async download(item) {
      const csv = await this.$api.glassBatch.get(`download/${item.file_id}`);
      if (!csv) {
        this.$bus.$emit('showError');
        return;
      }
      const file = new Blob([csv], {
        type: 'text/csv',
      });
      saveAs(file, item.file_name);
    },

    // async downloadSourceFilePO() {
    //   if (!this.downloadSourceFilePoNumber) return;
    //   if (!await this.$refs.sourceFileForm.validate()) return;
    //   this.$bus.$emit('loading');
    //   const poItems = this.items.filter((x) => x.po_number === this.downloadSourceFilePoNumber);
    //   poItems.forEach((item) => {
    //     this.download(item);
    //   });
    //   this.$bus.$emit('loading');
    //   this.dialogSourceFile = false;
    // },

    async downloadSourceFilePO() {
      if (!this.downloadSourceFilePoNumber) return;
      if (!await this.$refs.sourceFileForm.validate()) return;
      this.$bus.$emit('loading');
      const zip = await this.$api.glassBatch.get(`download-po/${this.downloadSourceFilePoNumber}`, { responseType: 'blob' });
      const file = new Blob([zip], {
        type: 'application/zip',
      });
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${this.downloadSourceFilePoNumber}_Source_File_Reports_${now}`;
      saveAs(file, fileName);
      this.$bus.$emit('loading');
      this.dialogCoverSheet = false;
    },

    async downloadCoverSheetsPo() {
      if (!this.downloadPoNumber) return;
      if (!await this.$refs.coverSheetForm.validate()) return;
      this.$bus.$emit('loading');
      const zip = await this.$api.glassBatch.get(`download-cover-sheet/${this.downloadPoNumber}`, { responseType: 'blob' });
      const file = new Blob([zip], {
        type: 'application/zip',
      });
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${this.downloadPoNumber}_Cover_Sheet_Reports_${now}`;
      saveAs(file, fileName);
      this.$bus.$emit('loading');
      this.dialogCoverSheet = false;
    },

    async downloadCoverSheet(item) {
      if (!item || !item.cover_sheet_report_path) return;
      const submitData = {
        path: item.cover_sheet_report_path,
      };
      const pdf = await this.$api.glassBatch.store(submitData, 'download-cover-sheet', { responseType: 'blob' });
      if (!pdf) {
        this.$bus.$emit('showError');
        return;
      }
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `${item.batch_number}_Cover_Sheet_Report_${now}`;
      const file = new Blob([pdf], {
        type: 'application/pdf',
      });
      saveAs(file, fileName);
    },
  },
};
</script>
<style scoped>
span
{
  font-size: 12px;
}
/* ::v-deep .status-select .v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none !important;
}
::v-deep  .status-select .fa-caret-down:before {
    content: "\f0d7";
    font-size: 16px;
    padding-left: 10px;
} */
::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 20px;
}
::v-deep .fa-caret-down:before {
    font-size: 14px;
}
::v-deep .v-select.v-input--dense .v-select__selection--comma {
    margin: 5px 0 3px 0;
}
.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-left: 0px !important;
}
::v-deep .v-text-field.v-input--dense .v-input__prepend-inner, .v-text-field.v-input--dense .v-input__append-inner {
    width: 15px;
}
::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    padding-left: 10px;
    padding-right: 10px;
}
::v-deep .v-text-field.v-text-field--solo .v-input__append-inner, .v-text-field.v-text-field--solo .v-input__prepend-inner {
    width: 12px;
}
::v-deep .v-input__icon {
    min-width: 12px;
    width: 12px;
}

::v-deep .select-status .theme--light.v-icon {
    color: white;
}

::v-deep .fa-times-circle:before {
    padding-right: 10px;
}

::v-deep .v-data-table__wrapper {
    overflow-x: hidden !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td
{
  padding: 0 4px;
}
</style>

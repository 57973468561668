/* eslint-disable vue/no-unused-vars */
<template>
  <v-container
    class="pa-0 mt-0"
    fluid
  >
    <v-row>
      <v-col
        cols="auto"
        class="pb-0 mt-4"
      >
        <v-checkbox
          v-model="isHideQtyOnHand"
          class="mt-0 mb-0"
          label="Hide Zero 'Qty On Hand'"
          hide-details
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-add-all="false"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="2"
      >
        <v-autocomplete
          v-model="filterItemId"
          label="Item"
          :items="inventoryItems"
          :item-text="item => `${item.material_id} ${item.name}`"
          item-value="id"
          hide-details="true"
          clearable
          :loading="loadingInventoryItem"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1 mr-3"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item && item.classes ? item.classes.color : 'white'"
            >
              {{ item && item.classes ? item.classes.name : '' }}
            </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="2"
      >
        <v-autocomplete
          v-model="filterColorId"
          label="Colour"
          :items="colors"
          item-text="code"
          item-value="id"
          hide-details="true"
          clearable
        >
          <template #selection="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
          <template #item="{ item }">
            <span>
              {{ item.code }}
            </span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="1"
      >
        <v-autocomplete
          v-model="filterLengthId"
          label="Length"
          :items="lengths"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        cols="1"
      >
        <v-autocomplete
          v-model="filterUomId"
          label="UOM"
          :items="uoms"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        cols="1"
      >
        <v-autocomplete
          v-model="filterLocationId"
          label="Location"
          :items="locations"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
        />
      </v-col>
      <v-col
        cols="1"
      >
        <v-autocomplete
          v-model="filterShelfId"
          label="Shelf"
          :items="shelves"
          item-text="name"
          item-value="id"
          hide-details="true"
          clearable
          no-data-text="Select a Location First"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="pt-0"
      >
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            id="inventory-tags-table"
            :headers="inventoryTagsHeaders"
            :items="inventoryTags"
            :footer-props="inventoryTagsTableFooterProps"
            :items-per-page="inventoryTagsPerPage"
            :search="search"
            :loading="loading"
            fixed-header
            :height="windowHeight"
            dense
            :server-items-length="total"
            disable-sort
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortByDesc"
            @pagination="updatePagination"
          >
            <template
              v-for="h in inventoryTagsHeaders"
              #[`header.${h.value}`]="{ header }"
            >
              <v-tooltip
                :key="h.value"
                bottom
              >
                <template #activator="{ on }">
                  <span v-on="on">{{ header.text }}</span>
                </template>
                <span>{{ header.fullText }}</span>
              </v-tooltip>
            </template>
            <template #[`item.inventory_class`]="{ item }">
              <v-chip
                class="mt-0 mb-0"
                small
                :color="item.item && item.item.classes ? item.item.classes.color : 'black'"
                text-color="white"
              >
                {{ item.item && item.item.classes ? item.item.classes.name : '' }}
              </v-chip>
            </template>
            <template #[`item.unique_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.unique_code ? item.unique_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.item_full_name`]="{ item }">
              <text-highlight :queries="search">
                {{ item.item_full_name }}
              </text-highlight>
            </template>
            <template #[`item.inventory_tag`]="{ item }">
              <span style="font-weight: bold;">
                <v-tooltip
                  top
                  color="black"
                >
                  <template #activator="{ on }">
                    <span
                      :style="{color: item && parseInt(item.inventory_tag, 10) > 99999 && parseInt(item.inventory_tag, 10) < 199999 ? '#0288D1' : ''}"
                      class="font-weight-bold"
                      v-on="on"
                    ><text-highlight :queries="search">{{ item.inventory_tag ? item.inventory_tag : '-' }}</text-highlight></span>
                  </template>
                  <span
                    v-if="item && parseInt(item.inventory_tag, 10) > 99999 && parseInt(item.inventory_tag, 10) < 199999"
                  > UPC Code</span>
                  <span v-else>QR Code</span>
                </v-tooltip>
              </span>
            </template>
            <template #[`item.manufacturers`]="{ item }">
              {{ item.manufacturer ? item.manufacturer : '-' }}
            </template>
            <template #[`item.material_property`]="{ item }">
              {{ item.material_property ? item.material_property: '-' }}
            </template>
            <template #[`item.length`]="{ item }">
              {{ item.length_name ? item.length_name : '-' }}
            </template>
            <template #[`item.color_code`]="{ item }">
              <text-highlight :queries="search">
                {{ item.color_code ? item.color_code : '-' }}
              </text-highlight>
            </template>
            <template #[`item.finishing`]="{ item }">
              {{ item.finishing ? item.finishing : '-' }}
            </template>
            <template #[`item.uom`]="{ item }">
              {{ item.uom_name }}
            </template>
            <template #[`item.door_size`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_size? item.door_size.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.door_hinge`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_hinge ? item.door_hinge.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.door_swing`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_swing ? item.door_swing.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.door_lite`]="{ item }">
              <text-highlight :queries="search">
                {{ item.door_lite ? item.door_lite.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.batch_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.batch_number ? item.batch_number: '' }}
              </text-highlight>
            </template>
            <template #[`item.production_date_simple`]="{ item }">
              <text-highlight :queries="search">
                {{ item.production_date_simple ? item.production_date_simple: '' }}
              </text-highlight>
            </template>
            <template #[`item.in_color`]="{ item }">
              <text-highlight :queries="search">
                {{ item.in_color ? item.in_color.code: '' }}
              </text-highlight>
            </template>
            <template #[`item.ex_color`]="{ item }">
              <text-highlight :queries="search">
                {{ item.ex_color ? item.ex_color.code: '' }}
              </text-highlight>
            </template>
            <template #[`item.project`]="{ item }">
              <text-highlight :queries="search">
                {{ item.project ? item.project.name: '' }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              {{ item.quantity }}
            </template>
            <template #[`item.quantity_stl`]="{ item }">
              {{ item.quantity_stl }}
            </template>

            <template #[`item.quantity_on_hand`]="{ item }">
              <v-row>
                <v-col
                  cols="6"
                  class="text-center"
                >
                  <span style="white-space:nowrap;">{{ item.quantity_on_hand }}</span>
                </v-col>
                <v-col cols="6">
                  <v-icon
                    v-if="item.quantity_on_hand != item.quantity"
                    color="primary"
                    class="mr-1"
                    @click="openSupplyForm(item)"
                  >
                    fa-info-circle
                  </v-icon>
                </v-col>
              </v-row>
            </template>

            <template #[`item.quantity_available`]="{ item }">
              {{ item.quantity_available ? item.quantity_available : '-' }}
            </template>
            <template #[`item.inventory_location`]="{ item }">
              <text-highlight :queries="search">
                {{ item.inventory_location_name ? item.inventory_location_name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.location_code`]="{ item }">
              {{ item.location_code ? item.location_code : '-' }}
            </template>
            <template #[`item.po_number`]="{ item }">
              <text-highlight :queries="search">
                {{ item.po_number ? item.po_number : '-' }}
              </text-highlight>
            </template>
            <template #[`item.supplier_label`]="{ item }">
              <a
                v-if="item.supplier_label_name"
                class=""
                @click="getSupplierLabel(item.inventory_tag)"
              >
                <v-icon
                  small
                  color="primary"
                >
                  fa-image
                </v-icon>
              </a>
            </template>
            <template #[`item.actions`]="{ item }">
              <a
                v-show="roles.includes(roleIdAdmin)"
                class=""
                @click="deleteTag(item.inventory_tag)"
              ><v-icon
                color="error"
                small
              >
                fa-trash
              </v-icon></a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <supply-inventory-tag-table-form
      ref="supplyForm"
      :inventory-tag="selectedInvTag"
      :qr-code="selectedQrCode"
      :material-id="selectedMaterialId"
    />
    <inventory-tag-column-setup-form
      ref="columnSetupForm"
      @success="getColumnSetup"
    />
    <InventoryItemShowImageForm
      ref="itemShowImageForm"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';
import SupplyInventoryTagTableForm from '@/components/forms/inventoryTags/SupplyInventoryTagTableForm';
import InventoryTagColumnSetupForm from '@/components/forms/inventoryTags/InventoryTagColumnSetupForm';
import ExcelHelper from '@/helpers/ExcelHelper';
import PdfHelper from '@/helpers/PdfHelper';
import * as FormatHelpers from '@/helpers/FormatHelpers';

export default {
  name: 'InventoryTags',
  components: {
    SupplyInventoryTagTableForm,
    InventoryItemShowImageForm,
    InventoryTagColumnSetupForm,
  },
  data: () => ({
    // Page
    search: '',
    loading: true,
    inventoryTags: [],
    inventoryTagsTableFooterProps: {
      'items-per-page-options': [20, 50, 100, 500, 1000],
    },
    inventoryTagsPerPage: 20,
    selectedInvTag: 0,
    selectedQrCode: 0,
    selectedMaterialId: '',
    readModel: null,
    pagination: {
      itemsPerPage: 20,
      page: 1,
    },
    sort: {
      order_by_first: '',
      direction_sort: 0,
    },
    filters: null,
    total: 0,
    inventoryClasses: [],
    inventoryClassId: null,
    inventoryItems: [],
    lengths: [],
    colors: [],
    uoms: [],
    locations: [],
    shelves: [],
    filterItemId: null,
    filterLengthId: null,
    filterColorId: null,
    filterUomId: null,
    filterLocationId: null,
    filterShelfId: null,
    topSpaceHeight: 280,
    windowHeight: 1000,
    loadingInventoryItem: false,
    roleIdAssociate: 6,
    roleIdAdmin: 3,
    columnSetup: null,
    inventoryClassIdGlass: 5,
    inventoryClassIdVWDGlass: 7,
    inventoryClassIdPanels: 8,
    inventoryClassIdSID: 9,
    inventoryClassIdPanelParts: 10,
  }),

  computed: {
    ...mapGetters([
      'businessRulesGetBoolean',
    ]),
    ...mapState({
      invClasses: 'invClasses',
      inventoryTagsState: (state) => state.inventoryTagsState,
      user: (state) => state.user,
    }),

    isHideQtyOnHand: {
      get() { return this.$store.state.isInvTagQtyOnHandEqualsToZero; },
      set(value) { this.$store.commit('storeisInvTagQtyOnHandEqualsToZero', value); this.get(); },
    },
    inventoryTagsHeaders() {
      const headers = [];
      // headers.push({
      //   text: 'Class', value: 'inventory_class', width: '5%', sortable: false, align: 'center',
      // });
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_inventory_tag)) {
        headers.push({
          text: 'QR Code', value: 'inventory_tag', filterable: true, width: `${this.columnSetup && this.columnSetup.inventory_tag_width ? this.columnSetup.inventory_tag_width : '5'}%`, sortable: false,
        });
      }

      const uniqueItemCodeBRName = 'Unique Item Code';
      const uniqueItemCodeBRValue = this.businessRulesGetBoolean(uniqueItemCodeBRName);
      if (uniqueItemCodeBRValue != null && uniqueItemCodeBRValue !== 0) headers.push({ text: 'Unique Item Code', value: 'unique_code', class: 'unique_item_code' });

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_inventory_item)) {
        headers.push({
          text: 'Inv. Item', value: 'item_full_name', width: `${this.columnSetup && this.columnSetup.inventory_item_width ? this.columnSetup.inventory_item_width : '17'}%`, sortable: false,
        });
      }

      const manufacturerIdBRName = 'Manufacturer';
      const manufacturerIdBRValue = this.businessRulesGetBoolean(manufacturerIdBRName);
      if (manufacturerIdBRValue != null && manufacturerIdBRValue !== 0) {
        headers.push({
          text: 'Manufacturer', value: 'manufacturer', filterable: false, sortable: false,
        });
      }

      const materialPropertyIdBRName = 'Material Property';
      const materialPropertyIdBRValue = this.businessRulesGetBoolean(materialPropertyIdBRName);
      if (materialPropertyIdBRValue != null && materialPropertyIdBRValue !== 0) {
        headers.push({
          text: 'Material Property', value: 'material_property', filterable: false, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_color)) && this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass) {
        headers.push({
          text: 'Colour', value: 'color_code', filterable: false, width: `${this.columnSetup && this.columnSetup.color_width ? this.columnSetup.color_width : '15'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_length)) && this.inventoryClassId !== this.inventoryClassIdSID && this.inventoryClassId !== this.inventoryClassIdGlass && this.inventoryClassId !== this.inventoryClassIdVWDGlass && this.inventoryClassId !== this.inventoryClassIdPanels) {
        headers.push({
          text: 'Length', value: 'length', filterable: false, width: `${this.columnSetup && this.columnSetup.length_width ? this.columnSetup.length_width : '5'}%`, sortable: false,
        });
      }

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_uom)) {
        headers.push({
          text: 'UOM', value: 'uom', width: `${this.columnSetup && this.columnSetup.uom_width ? this.columnSetup.uom_width : '5'}%`, sortable: false,
        });
      }

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_location)) {
        headers.push({
          text: 'Location', fullText: 'Location Code', value: 'location_code', filterable: false, width: `${this.columnSetup && this.columnSetup.location_width ? this.columnSetup.location_width : '4'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_shelf)) {
        headers.push({
          text: 'Shelf', value: 'inventory_location', filterable: false, width: `${this.columnSetup && this.columnSetup.shelf_width ? this.columnSetup.shelf_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_size)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Size', value: 'door_size', filterable: false, width: `${this.columnSetup && this.columnSetup.size_width ? this.columnSetup.size_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_hinge)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Hinge', value: 'door_hinge', filterable: false, width: `${this.columnSetup && this.columnSetup.hinge_width ? this.columnSetup.hinge_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_swing)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Swing', value: 'door_swing', filterable: false, width: `${this.columnSetup && this.columnSetup.swing_width ? this.columnSetup.swing_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_lite)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Lite', value: 'door_lite', filterable: false, width: `${this.columnSetup && this.columnSetup.lite_width ? this.columnSetup.lite_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_in_color)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'In Color', value: 'in_color', filterable: false, width: `${this.columnSetup && this.columnSetup.in_color_width ? this.columnSetup.in_color_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_ex_color)) && this.inventoryClassId === this.inventoryClassIdSID) {
        headers.push({
          text: 'Ex Color', value: 'ex_color', filterable: false, width: `${this.columnSetup && this.columnSetup.ex_color_width ? this.columnSetup.ex_color_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_batch)) && (this.inventoryClassId === this.inventoryClassIdGlass || this.inventoryClassId === this.inventoryClassIdVWDGlass)) {
        headers.push({
          text: 'Vendor Batch#', value: 'batch_number', filterable: false, width: `${this.columnSetup && this.columnSetup.batch_width ? this.columnSetup.batch_width : '8'}%`, sortable: false,
        });
      }

      if ((!this.columnSetup || (this.columnSetup && this.columnSetup.is_prod_date)) && (this.inventoryClassId === this.inventoryClassIdGlass || this.inventoryClassId === this.inventoryClassIdVWDGlass)) {
        headers.push({
          text: 'Prod. Date', value: 'production_date_simple', filterable: false, width: `${this.columnSetup && this.columnSetup.prod_date_width ? this.columnSetup.prod_date_width : '8'}%`, sortable: false,
        });
      }

      const finishingIdBRName = 'Finishing';
      const finishingIdBRValue = this.businessRulesGetBoolean(finishingIdBRName);
      if (finishingIdBRValue != null && finishingIdBRValue !== 0) {
        headers.push({
          text: 'Finishing', value: 'finishing', filterable: false, sortable: false,
        });
      }

      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_quantity_received)) {
        headers.push({
          text: 'ERP Received', fullText: 'ERP Quantity Received ', value: 'quantity', align: 'center', width: `${this.columnSetup && this.columnSetup.quantity_received_width ? this.columnSetup.quantity_received_width : '5'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_quantity_stl)) {
        headers.push({
          text: 'ERP STL', fullText: 'ERP Quantity Supply To Line', align: 'center', value: 'quantity_stl', width: `${this.columnSetup && this.columnSetup.quantity_stl_width ? this.columnSetup.quantity_stl_width : '5'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_quantity_on_hand)) {
        headers.push({
          text: 'ERP On Hand', fullText: 'ERP Quantity On Hand', value: 'quantity_on_hand', width: `${this.columnSetup && this.columnSetup.quantity_on_hand_width ? this.columnSetup.quantity_on_hand_width : '5'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_po)) {
        headers.push({
          text: 'PO', value: 'po_number', filterable: false, width: `${this.columnSetup && this.columnSetup.po_width ? this.columnSetup.po_width : '4'}%`, sortable: false,
        });
      }

      headers.push({
        text: 'Project', value: 'project', filterable: false, width: '8%', sortable: false,
      });
      // if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_created)) {
      //   headers.push({
      //     text: 'Created', value: 'created_at', width: `${this.columnSetup && this.columnSetup.created_width ? this.columnSetup.created_width : '10'}%`, sortable: false,
      //   });
      // }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_updated)) {
        headers.push({
          text: 'Updated', value: 'updated_at', width: `${this.columnSetup && this.columnSetup.updated_width ? this.columnSetup.updated_width : '10'}%`, sortable: false,
        });
      }
      if (!this.columnSetup || (this.columnSetup && this.columnSetup.is_supplier_label)) {
        headers.push({
          text: '', value: 'supplier_label', filterable: false, width: `${this.columnSetup && this.columnSetup.supplier_label_width ? this.columnSetup.supplier_label_width : '3'}%`, sortable: false,
        });
      }
      headers.push({
        text: '', value: 'actions', filterable: false, width: '3%', align: 'end', sortable: false,
      });

      return headers;
    },
  },
  watch: {
    inventoryClassId(value) {
      const state = this.inventoryTagsState;
      state.class_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
      this.getItems();
    },
    search() {
      this.get();
    },
    pagination() {
      const state = this.inventoryTagsState;
      state.perPage = this.pagination.itemsPerPage;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    sort: {
      handler() {
        this.get();
      },
      deep: true,
    },
    filterItemId(value) {
      const state = this.inventoryTagsState;
      state.inventory_item_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterLengthId(value) {
      const state = this.inventoryTagsState;
      state.length_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterColorId(value) {
      const state = this.inventoryTagsState;
      state.color_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterUomId(value) {
      const state = this.inventoryTagsState;
      state.uom_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
    filterLocationId(value) {
      const state = this.inventoryTagsState;
      state.location_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
      this.getShelves();
    },
    filterShelfId(value) {
      const state = this.inventoryTagsState;
      state.shelf_id = value;
      this.$store.commit('storeInventoryTagsState', state);
      this.get();
    },
  },

  created() {
    this.initialize();
    if (this.inventoryTagsState) {
      if (this.inventoryTagsState.perPage) { this.inventoryTagsPerPage = this.inventoryTagsState.perPage; }
      if (this.inventoryTagsState.inventory_item_id) { this.filterItemId = this.inventoryTagsState.inventory_item_id; }
      if (this.inventoryTagsState.length_id) { this.filterLengthId = this.inventoryTagsState.length_id; }
      if (this.inventoryTagsState.color_id) { this.filterColorId = this.inventoryTagsState.color_id; }
      if (this.inventoryTagsState.uom_id) { this.filterUomId = this.inventoryTagsState.uom_id; }
      if (this.inventoryTagsState.location_id) { this.filterLocationId = this.inventoryTagsState.location_id; }
      if (this.inventoryTagsState.shelf_id) { this.filterShelfId = this.inventoryTagsState.shelf_id; }
      if (this.inventoryTagsState.class_id) { this.inventoryClassId = this.inventoryTagsState.class_id; }
    }
    window.scrollTo(0, document.body.scrollHeight);
    this.windowHeight = window.innerHeight - this.topSpaceHeight;
    this.roles = this.user.roles.map((x) => x.id);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async initialize() {
      this.get();
      this.getItems();
      this.getUoms();
      this.getColors();
      this.getLengths();
      this.getShelves();
      this.getLocations();
      this.getColumnSetup();
    },

    async get() {
      if (this.inventoryClassId === null) return;
      this.loading = true;
      this.inventoryTags = [];
      const readModel = {
        pagination: this.pagination,
        filters:
        {
          is_qty_on_hand_o: this.isHideQtyOnHand === true ? 1 : 0,
          inventory_class_id: this.inventoryClassId,
          inventory_item_id: this.filterItemId,
          color_id: this.filterColorId,
          length_id: this.filterLengthId,
          location_id: this.filterLocationId,
          shelf_id: this.filterShelfId,
          uom_id: this.filterUomId,
        },
        sort: this.sort,
        search: this.search,
      };
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryTags = [];
        this.total = 0;
        return;
      }
      this.inventoryTags = res.data;
      this.total = res.total;
    },

    async getSupplierLabel(tag) {
      const supplierLabel = await this.$api.inventoryTag.get(`supplier-label/${tag}`);
      if (!supplierLabel) return;
      this.openItemShowImageForm(supplierLabel.supplier_label, supplierLabel.supplier_label_mime_type, tag);
    },

    async getItems() {
      this.loadingInventoryItem = true;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },
    async getUoms() {
      this.uoms = await this.$api.uom.getList();
    },
    async getColors() {
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
    },
    async getLengths() {
      this.lengths = await this.$api.length.getList();
    },
    async getShelves() {
      if (!this.filterLocationId) return;
      this.$nextTick(async () => {
        const readModel = {
          filters:
          {
            location_id: this.filterLocationId,
          },
        };
        this.shelves = await this.$api.inventoryLocation.read(readModel);
      });
    },
    async getLocations() {
      this.locations = await this.$api.location.getList();
    },

    async getColumnSetup() {
      const res = await this.$api.inventoryTagColumnSetup.get();
      if (!res || !res.id) return;
      this.columnSetup = res;
    },

    async deleteTag(tag) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const res = await this.$api.inventoryTag.delete(tag);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
      this.get();
    },

    updateSearch(search) {
      this.search = search;
    },
    updateSortBy(sortBy) {
      if (!sortBy || !sortBy[0]) return;
      const firstIndex = 0;
      this.sort.order_by_first = sortBy[firstIndex];
    },
    updateSortByDesc(isDesc) {
      if (!isDesc || !isDesc[0]) return;
      const firstIndex = 0;
      const direction = isDesc[firstIndex];
      if (direction) {
        this.sort.direction_sort = 'DESC';
      } else {
        this.sort.direction_sort = 'ASC';
      }
    },
    generateExcel() {
      const excelData = this.inventoryTags.map((el) => ({
        Class: el.item && el.item.classes ? el.item.classes.name : '',
        'QR Code': el.inventory_tag,
        'Die #': el.material_id,
        'Item Name': el.name,
        Color: el.color_code,
        Length: el.length_name,
        UOM: el.uom_name,
        Location: el.location_name,
        Shelf: el.inventory_location_name,
        'ERP Received Quantity': parseInt(el.quantity ? el.quantity : 0, 10),
        'ERP STL Quantity': parseInt(el.quantity_stl ? el.quantity_stl : 0, 10),
        'ERP Quantity On Hand': parseInt(el.quantity_on_hand ? el.quantity_on_hand : 0, 10),
        'PO #': el.po_number,
        'Created At': el.created_at,
      }));
      const excelWorkbook = ExcelHelper.writeJson(excelData, 'Inventory Tags');
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Inventory-Tags_${now}`;
      ExcelHelper.saveExcelToBrowser(excelWorkbook, fileName);
    },
    async generatePdf() {
      await this.$nextTick();
      const pdf = PdfHelper.generateFromTable(
        '#inventory-tags-table > div > table',
        'landscape',
        'Inventory Tags',
        'inventory-tags',
      );
      const now = FormatHelpers.getFileTimestamp();
      const fileName = `Inventory-Tags_${now}`;
      PdfHelper.savePdfToBrowser(pdf, fileName);
    },

    openSupplyForm(record) {
      if (!record) return;
      this.selectedInvTag = record.inventory_tag;
      this.selectedQrCode = record.qr_code ? parseInt(record.qr_code, 10) : 0;
      this.selectedMaterialId = record.material_id;
      this.$refs.supplyForm.open();
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    openItemShowImageForm(photo, type, code) {
      this.$refs.itemShowImageForm.openDialog(photo, type, code);
    },
    convertedPhoto(photo, mimeType) {
      if (!photo || !mimeType) return '';
      return `data:${mimeType};base64, ${photo}`;
    },
    onResize() {
      this.windowHeight = window.innerHeight - this.topSpaceHeight;
    },

    openColumnSetup() {
      this.$refs.columnSetupForm.open();
    },
  },
};
</script>
<style>
.profile-photo-mini{
  max-width: 40px;
}
.text-bold{
  background: red;
}

</style>

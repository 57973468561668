<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="2"
        class="pb-0"
      >
        <inventory-class-select-element
          v-model="inventoryClassId"
          :is-state="true"
        />
      </v-col>
      <v-col
        cols="2"
        class="pb-0"
      >
        <v-select
          v-model="selectedStatusId"
          :items="statuses"
          item-text="name"
          item-value="id"
          label="Status"
        >
          <template #item="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #selection="{ item }">
            <v-chip
              class="mt-1 mb-1"
              small
              text-color="white"
              style="cursor: pointer;"
              :color="item ? item.color : 'white'"
            >
              {{ item ? item.name : '' }}
            </v-chip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="text-end pb-0 mt-4"
      >
        <v-btn
          class="ml-1 mt-1 mb-1"
          color="purple"
          outlined
          @click="sync()"
        >
          SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="purchaseOrders"
            :footer-props="footer"
            :items-per-page="-1"
            :search="search"
            :loading="loading"
            dense
            :server-items-length="total"
            @pagination="updatePagination"
          >
            <template
              #[`item.class`]="{ item }"
            >
              <v-chip
                small
                :color="item && item.class ? item.class.color : ''"
                text-color="white"
              >
                {{ item && item.class ? item.class.name : 'All' }}
              </v-chip>
            </template>
            <template #[`item.po_number`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.po_number }}
              </text-highlight>
            </template>
            <template #[`item.job`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.job_name ? item.job_name : '-' }}
              </text-highlight>
            </template>
            <template #[`item.status`]="{ item }">
              <v-chip
                small
                :color="item && item.status ? item.status.color : ''"
                text-color="white"
              >
                {{ item && item.status ? item.status.name : '' }}
              </v-chip>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="purple"
                outlined
                small
                @click="hide(item.is_active, item.po_number, item.inventory_class_id)"
              >
                {{ item.is_active ? 'HIDE' : 'SHOW' }}
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="open(item.po_number, item.inventory_class_id)"
              >
                OPEN
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    purchaseOrders: [],
    loading: false,
    inventoryClassId: null,
    statuses: [],
    selectedStatusId: 0,
    headers: [
      { text: 'Class', value: 'class' },
      { text: 'PO#', value: 'po_number' },
      { text: 'Job', value: 'job' },
      { text: 'Total Items', value: 'total_items' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 100, -1],
    },
    pagination: {
      itemsPerPage: 100,
      page: 1,
    },
    total: 0,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    inventoryClassId() {
      this.getPurchaseOrders();
    },
    selectedStatusId() {
      this.getPurchaseOrders();
    },
    pagination() {
      this.getPurchaseOrders();
    },
    search() {
      this.getPurchaseOrders();
    },

  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getPurchaseOrders();
      this.getStatus();
    },

    async getPurchaseOrders() {
      this.purchaseOrders = [];
      this.loading = true;
      if (this.inventoryClassId === null) return;
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
          status_id: this.selectedStatusId,
        },
        pagination: this.pagination,
        search: this.search,
      };
      const res = await this.$api.supplyToPaint.readPurchaseOrder(readModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.loading = false;
      if (!res || !res.data || res.data.length === 0) {
        this.purchaseOrders = [];
        this.total = 0;
        return;
      }
      this.purchaseOrders = res.data;
      this.total = res.total;
    },

    async getStatus() {
      this.statuses = [];
      this.statuses.push({ id: 0, name: 'ALL', color: 'gray' });
      const res = await this.$api.supplyToPaintStatus.get();
      this.statuses.push(...res);
    },

    open(poNumber, classId) {
      this.$router.push(`/supply-to-paints/${poNumber}/${classId}`);
    },

    updatePagination(pagination) {
      if (pagination.page !== this.pagination.page || pagination.itemsPerPage !== this.pagination.itemsPerPage) { this.pagination = pagination; }
    },

    async hide(isActive, poNumber, classId) {
      const confirm = await this.$refs.confirmForm.open({
        title: `Are you sure that you want to ${isActive ? 'hide' : 'show'} this record?`,
      });
      if (!confirm) return;
      const res = await this.$api.supplyToPaint.get(`toggle/${isActive === 1 ? 0 : 1}/${classId}/${poNumber}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getPurchaseOrders();
    },

    async sync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to do a Data Sync? This action will bring new Glass Items into ERP and can take up to 5 minutes!',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');
      const res = await this.$api.dataSync.get('paint-purchase-order');
      this.$bus.$emit('loading');
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
    },
  },
};
</script>

<style scoped>

</style>

<template>
  <v-container>
    <v-row>
      <v-col>
        <span style="font-size: 18px;">Data Sync process is done every night at 12:00 pm and synchronizes records with ERP automatically</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mt-2"
      >
        <span>
          Manual Data Sync from FP GEST:
        </span>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="getStock()"
        >
          FP GEST SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span> <span style="color:red;">Important!</span> Data Sync will update QR Codes, Inventory Tags, Raw Materials, Colours, Lengths, Locations, UOMs, Clients, Jobs, Glass Records and Paint Purchase Orders.</span>
      </v-col>
    </v-row>
    <v-row v-if="lastDataImport">
      <v-col>
        <span class="font-weight-bold">Inventory Sync Results</span><br>
        <span>Data Sync Name: {{ lastDataImport.record4500 ? lastDataImport.record4500.report_name : '' }}</span><br><br>
        <span class="font-weight-bold">FP GEST:</span><br>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold ml-5">4500:</span><br>
            <span class="ml-5">{{ lastDataImport.record4500 ? lastDataImport.record4500.other_records_processed : '' }} records processed</span><br>
            <span class="ml-5">{{ lastDataImport.record4500 ? lastDataImport.record4500.other_watchlist_records : '' }} records in Watchlist</span><br>
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold ml-5">4800:</span><br>
            <span class="ml-5">{{ lastDataImport.record4800 ? lastDataImport.record4800.other_records_processed : '' }} records processed</span><br>
            <span class="ml-5">{{ lastDataImport.record4800 ? lastDataImport.record4800.other_watchlist_records : '' }} records in Watchlist</span><br><br>
          </v-col>
        </v-row>

        <span class="font-weight-bold">ERP:</span><br>
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold ml-5">4500:</span><br>
            <span class="ml-5">{{ lastDataImport.record4500 ? lastDataImport.record4500.erp_records_processed : '' }} records processed</span><br>
            <span class="ml-5">{{ lastDataImport.record4500 ? lastDataImport.record4500.erp_watchlist_records : '' }} records in Watchlist</span><br>
            <span class="ml-5">{{ lastDataImport.record4500 ? lastDataImport.record4500.erp_new_records_quantity : '' }} new records added</span><br>
            <span class="ml-5">{{ lastDataImport.record4500 ? lastDataImport.record4500.created_at : '' }}</span><br>
          </v-col>
          <v-col cols="3">
            <span class="font-weight-bold ml-5">4800:</span><br>
            <span class="ml-5">{{ lastDataImport.record4800 ? lastDataImport.record4800.erp_records_processed : '' }} records processed</span><br>
            <span class="ml-5">{{ lastDataImport.record4800 ? lastDataImport.record4800.erp_watchlist_records : '' }} records in Watchlist</span><br>
            <span class="ml-5">{{ lastDataImport.record4800 ? lastDataImport.record4800.erp_new_records_quantity : '' }} new records added</span><br>
            <span class="ml-5">{{ lastDataImport.record4800 ? lastDataImport.record4800.created_at : '' }}</span><br>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px; font-weight: 500;">Asset Management Data Sync</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px;">Asset Management Data Sync process is done every 30 minutes and synchronizes records with ERP automatically</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mt-2"
      >
        <span>
          Manual Data Sync from Asset Management:
        </span>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="assetManagementSync()"
        >
          ASSET MANAGEMENT SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="lastDataImport">
      <v-col>
        <v-row>
          <v-col class="pb-0">
            <span class="font-weight-bold">ASSET MANAGEMENT:</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-8">
            <v-row>
              <v-col>
                <span class="font-weight-bold">99 VINYL COURT:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.erp_records_processed : '0' }} records processed</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.erp_new_records_quantity : '0' }} records added</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.skipped_records : '0' }} records skipped</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <span class="font-weight-bold">141 ADESSO:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.second_records_processed : '0' }} records processed</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.second_records_added : '0' }} records added</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.second_skipped_records : '0' }} records skipped</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <span class="font-weight-bold">300 CHRISLEA:</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.third_records_processed : '0' }} records processed</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.third_records_added : '0' }} records added</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <span>{{ lastDataImport.asset_management ? lastDataImport.asset_management.third_skipped_records : '0' }} records skipped</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="lastDataImport.asset_management">
          <v-col
            cols="auto"
            class="pt-0 pl-8"
          >
            <span style="font-weight: 500;">Last Updated: {{ lastDataImport.asset_management ? lastDataImport.asset_management.created_at : '' }}</span>
          </v-col>
          <v-col class="pt-0 ">
            <span style="font-weight: 500;">Time Spent: {{ lastDataImport.asset_management.time_spent_seconds ? secondsToMinutes(lastDataImport.asset_management.time_spent_seconds) : '' }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px; font-weight: 500;">Optima Data Sync</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px;">Optima Data Sync process is done every 30 minutes and synchronizes records with ERP automatically</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mt-2"
      >
        <span>
          Manual Data Sync from Optima:
        </span>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="optimaSync()"
        >
          OPTIMA SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="lastDataImport">
      <v-col>
        <v-row>
          <v-col class="pb-0">
            <span class="font-weight-bold">OPTIMA:</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pl-8">
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.optima ? lastDataImport.optima.erp_records_processed : '0' }} records processed</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.optima ? lastDataImport.optima.erp_new_records_quantity : '0' }} records added</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="lastDataImport.optima">
          <v-col
            class="pt-0 pl-8"
            cols="auto"
          >
            <span style="font-weight: 500;">Last Updated: {{ lastDataImport.optima ? lastDataImport.optima.created_at : '' }}</span>
          </v-col>
          <v-col class="pt-0">
            <span style="font-weight: 500;">Time Spent: {{ lastDataImport.optima.time_spent_seconds ? secondsToMinutes(lastDataImport.optima.time_spent_seconds) : '' }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px; font-weight: 500;">Glass Data Sync</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px;">Glass Data Sync process is done every 30 minutes and synchronizes records with ERP automatically</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mt-2"
      >
        <span>
          Manual Data Sync from Glass:
        </span>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="glassSync()"
        >
          GLASS SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <span class="font-weight-bold">GLASS:</span>
      </v-col>
    </v-row>
    <v-row v-if="lastDataImport">
      <v-col class="pl-8">
        <v-row>
          <v-col cols="3">
            <span class="font-weight-bold">ERP:</span><br>
            <span>{{ lastDataImport.glass ? lastDataImport.glass.other_records_processed : '' }} records processed</span><br>
            <span>{{ lastDataImport.glass ? lastDataImport.glass.other_watchlist_records : '' }} records in Watchlist</span><br>
            <span>{{ lastDataImport.glass ? lastDataImport.glass.erp_new_records_quantity : '' }} new records added</span><br>
          </v-col>
          <v-col>
            <span class="font-weight-bold">FP GEST:</span><br>
            <span>{{ lastDataImport.glass ? lastDataImport.glass.other_records_processed : '' }} records processed</span><br>
            <span>{{ lastDataImport.glass ? lastDataImport.glass.other_watchlist_records : '' }} records in Watchlist</span><br>
          </v-col>
        </v-row>
        <v-row v-if="lastDataImport.glass">
          <v-col cols="auto">
            <span style="font-weight: 500;">Last Updated: {{ lastDataImport.glass ? lastDataImport.glass.created_at : '' }}</span>
          </v-col>
          <v-col>
            <span style="font-weight: 500;">Time Spent: {{ lastDataImport.glass.time_spent_seconds ? secondsToMinutes(lastDataImport.glass.time_spent_seconds) : '' }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px; font-weight: 500;">Jobs Data Sync</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px;">Jobs Data Sync process is done every 30 minutes and synchronizes records with ERP automatically</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mt-2"
      >
        <span>
          Manual Data Sync from Jobs:
        </span>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="jobSync()"
        >
          Jobs SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <span class="font-weight-bold">Jobs:</span>
      </v-col>
    </v-row>
    <v-row v-if="lastDataImport">
      <v-col class="pl-8">
        <v-row>
          <v-col>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.job ? lastDataImport.job.erp_records_processed : '0' }} records processed</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.job ? lastDataImport.job.erp_new_records_quantity : '0' }} records added</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="lastDataImport.job">
          <v-col cols="auto">
            <span style="font-weight: 500;">Last Updated: {{ lastDataImport.job ? lastDataImport.job.created_at : '' }}</span>
          </v-col>
          <v-col cols="auto">
            <span style="font-weight: 500;">Time Spent: {{ lastDataImport.job.time_spent_seconds ? secondsToMinutes(lastDataImport.job.time_spent_seconds) : '' }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <hr
          role="separator"
          aria-orientation="horizontal"
          class="v-divider"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px; font-weight: 500;">Production Schedule Windows Breakdown Sync</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <span style="font-size: 18px;">Production Schedule Windows Breakdown Data Sync process is done every hour and synchronizes records automatically</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="auto"
        class="mt-2"
      >
        <span>
          Manual Data Sync from Production Schedule Windows Breakdown:
        </span>
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          @click="windowBreakdownSync()"
        >
          Windows Breakdown SYNC
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <span class="font-weight-bold">Production Schedule Windows Breakdown:</span>
      </v-col>
    </v-row>
    <v-row v-if="lastDataImport">
      <v-col class="pl-8">
        <v-row>
          <v-col>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.window_breakdown ? lastDataImport.window_breakdown.erp_records_processed : '0' }} records processed</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-0">
                <span>{{ lastDataImport.window_breakdown ? lastDataImport.window_breakdown.erp_new_records_quantity : '0' }} records added</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="lastDataImport.window_breakdown">
          <v-col cols="auto">
            <span style="font-weight: 500;">Last Updated: {{ lastDataImport.window_breakdown ? lastDataImport.window_breakdown.created_at : '' }}</span>
          </v-col>
          <v-col cols="auto">
            <span style="font-weight: 500;">Time Spent: {{ lastDataImport.window_breakdown.time_spent_seconds ? secondsToMinutes(lastDataImport.window_breakdown.time_spent_seconds) : '' }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  data: () => ({
    itemsTableFooterProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    watchlistHeaders: [
      { text: 'Type', value: 'data_type' },
      { text: 'ERP Value', value: 'name' },
      { text: 'FP GEST Value', value: 'fpgest_name' },
      { text: 'Class', value: 'inventory_class' },
      { text: 'Record Info', value: 'record_info' },
      { text: 'Date', value: 'created_at' },
      { text: 'Status', value: 'status' },
      { text: 'Actions', value: 'action', align: 'end' },
    ],
    loading: false,
    lastTimeUpdated: '',
    lastDataImport: null,
    inventoryClassId: 0,
    inventoryClasses: [],
    readModel: null,

  }),
  computed: {
    ...mapState({
      invClasses: 'invClasses',
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getDataImport();
      this.getInvClasses();
    },
    selectFile() {
      const excelFile = this.$refs.file;

      if (excelFile.files && excelFile.files.length !== 0) {
        this.convertExcel(excelFile.files[0]);
      }
    },

    async getStock() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'IMPORTANT! Data Sync will update QR Codes, Inventory Tags, Raw Materials, Colours, Lengths, Locations and UOMs. Data Sync can take up to 5 minutes',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');

      const res = await this.$api.dataSync.get();

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError', 'Something went wrong! Please, make sure there are no empty fields.');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getDataImport();
    },

    async assetManagementSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'IMPORTANT! Asset Management Data Sync will update Production Schedule. It can take up to 5 minutes',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');

      const res = await this.$api.dataSync.get('asset-management');

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError', 'Something went wrong! Please, make sure there are no empty fields.');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getDataImport();
    },

    async optimaSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'IMPORTANT! Optima Data Sync will update Glass Records. It can take up to 5 minutes',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');

      const res = await this.$api.dataSync.get('optima');

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError', 'Something went wrong! Please, make sure there are no empty fields.');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getDataImport();
    },

    async glassSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'IMPORTANT! Glass Data Sync will update Glass Records. It can take up to 5 minutes',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');

      const res = await this.$api.dataSync.get('glass');

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError', 'Something went wrong! Please, make sure there are no empty fields.');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getDataImport();
    },

    async jobSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'IMPORTANT! Job Data Sync will update Glass Records. It can take up to 5 minutes',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');

      const res = await this.$api.dataSync.get('jobs');

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError', 'Something went wrong! Please, make sure there are no empty fields.');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getDataImport();
    },

    async windowBreakdownSync() {
      const confirm = await this.$refs.confirmForm.open({
        title: 'IMPORTANT! Production Schedule Windows Breakdown Data Sync will update Glass Records. It can take up to 5 minutes',
      });
      if (!confirm) return;
      this.$bus.$emit('loading');

      const res = await this.$api.dataSync.get('window-breakdown');

      this.$bus.$emit('loading');

      if (!res) {
        this.$bus.$emit('showError', 'Something went wrong! Please, make sure there are no empty fields.');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getDataImport();
    },

    async getDataImport() {
      this.lastDataImport = await this.$api.dataImport.get();
    },

    async getInvClasses() {
      const allClasses = { name: 'All', id: 0 };
      this.inventoryClasses.push(allClasses);
      this.inventoryClasses.push(...this.invClasses);
    },

    getLastTimeExecuted(date4500, date4800) {
      if (!date4500 || !date4800) return '';
      if (date4500 > date4800) return date4500;
      return date4800;
    },

    secondsToMinutes(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    },
  },
};

</script>

<style scoped>

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('inventory-class-select-element',{model:{value:(_vm.inventoryClassId),callback:function ($$v) {_vm.inventoryClassId=$$v},expression:"inventoryClassId"}})],1),_c('v-col',{staticClass:"text-end mt-4 pb-0"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function () {
            _vm.$refs.itemForm.openDialog();
          }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" new item ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.itemsHeaders,"items":_vm.items,"footer-props":_vm.itemsTableFooterProps,"items-per-page":100,"search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.inventory_class",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"mt-0 mb-0",attrs:{"small":"","color":item && item.classes ? item.classes.color : 'black',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.classes ? item.classes.name : '')+" ")])]}},{key:"item.photo",fn:function(ref){
          var item = ref.item;
return [(item.photo)?_c('a',{on:{"click":function($event){return _vm.openItemShowImageForm(item.photo, item.mime_type, item.material_id)}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" fa-image ")])],1):_vm._e()]}},{key:"item.material_id",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item ? item.material_id : '')+" ")])]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item ? item.name : '-')+" ")])]}},{key:"item.category",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item ? item.category : '-')+" ")])]}},{key:"item.manufacturers",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.manufacturers ? item.manufacturers : '-')+" ")])]}},{key:"item.uoms",fn:function(ref){
          var item = ref.item;
return [(item.uom_items && item.uom_items.length > 2)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("UOMS")])]}}],null,true)},_vm._l((item.uom_items),function(p){return _c('span',{key:p.id},[_vm._v(" "+_vm._s(p.name && p.name !== '-'? ((p.name) + " ") : '')),_c('br')])}),0):_c('span',_vm._l((item.uom_items),function(p){return _c('span',{key:p.id},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(p.name && p.name !== '-' ? ((p.name) + " ") : ''))]),_c('br')],1)}),0)]}},{key:"item.material_properties",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.material_properties ? item.material_properties : '-')+" ")])]}},{key:"item.lengths",fn:function(ref){
          var item = ref.item;
return [(item.length_items && item.length_items.length > 2)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("LENGTHS")])]}}],null,true)},_vm._l((item.length_items),function(p){return _c('span',{key:p.id},[_vm._v(" "+_vm._s(p.name && p.name !== '-'? ((p.name) + " ") : '')),_c('br')])}),0):_c('span',_vm._l((item.length_items),function(p){return _c('span',{key:p.id},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(p.name && p.name !== '-' ? ((p.name) + " ") : ''))])],1)}),0)]}},{key:"item.colors",fn:function(ref){
          var item = ref.item;
return [(item.colors && item.colors.length > 2)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"color":"#1976d2"}},on),[_vm._v("COLORS")])]}}],null,true)},_vm._l((item.colors),function(p){return _c('span',{key:p.id},[_vm._v(" "+_vm._s(p.name && p.name !== '-'? ((p.name) + " ") : '')+" "+_vm._s(p.code ? p.code : '')),_c('br')])}),0):_c('span',_vm._l((item.colors),function(p){return _c('span',{key:p.id},[_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(_vm._s(p.name && p.name !== '-' ? ((p.name) + " ") : '')+" "+_vm._s(p.code ? p.code : ''))]),_c('br')],1)}),0)]}},{key:"item.finishings",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.finishings ? item.finishings : '-')+" ")])]}},{key:"item.updated_at",fn:function(ref){
          var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.updated_at)+" ")])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openItemForm(item)}}},[_vm._v(" EDIT ")]),_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" DELETE ")])]}}],null,true)})],1)],1)],1),_c('InventoryItemForm',{ref:"itemForm",on:{"item-creation-success":_vm.notifyItemCreationSuccess,"item-creation-fail":_vm.notifyItemCreationFail}}),_c('InventoryItemShowImageForm',{ref:"itemShowImageForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-0 pt-0"},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_vm._v(" ACTIVE ")]),_c('v-tab',[_vm._v(" ARCHIVED ")])],1)],1),_c('v-spacer'),(_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate) || _vm.roles.includes(_vm.userRoleIdRequester))?_c('v-col',{staticClass:"text-end pb-0"},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.open}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fas fa-plus ")]),_vm._v(" New Pick List ")],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.statuses,"label":"Status","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : ''}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"mt-1 mb-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item ? item.color : 'white'}},[_vm._v(" "+_vm._s(item ? item.name : '')+" ")])]}}]),model:{value:(_vm.statusId),callback:function ($$v) {_vm.statusId=$$v},expression:"statusId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.projects,"item-value":"id","item-text":"name","label":"Project"},model:{value:(_vm.projectId),callback:function ($$v) {_vm.projectId=$$v},expression:"projectId"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.departments,"item-value":"id","label":"Department","item-text":"name"},model:{value:(_vm.departmentId),callback:function ($$v) {_vm.departmentId=$$v},expression:"departmentId"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menuFrom",attrs:{"return-value":_vm.filterDateFrom,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.filterDateFrom=$event},"update:return-value":function($event){_vm.filterDateFrom=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Requested At","readonly":""},on:{"change":function($event){return _vm.$refs.menuFrom.save(_vm.filterDateFrom)}},model:{value:(_vm.filterDateFrom),callback:function ($$v) {_vm.filterDateFrom=$$v},expression:"filterDateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenuFrom),callback:function ($$v) {_vm.dateMenuFrom=$$v},expression:"dateMenuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filterDateFrom),callback:function ($$v) {_vm.filterDateFrom=$$v},expression:"filterDateFrom"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuFrom = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.filterDateFrom = null}}},[_vm._v(" Clear ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":_vm.footer,"items-per-page":100,"search":_vm.search,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.windowHeight,"server-items-length":_vm.total},on:{"pagination":_vm.updatePagination},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{staticStyle:{"font-weight":"bold"},attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.project_name)+" ")])]}},{key:"item.department_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.department_name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.created_at)+" ")])]}},{key:"item.user_full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.user_full_name)+" ")])]}},{key:"item.supplied_at",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.supplied_at)+" ")])]}},{key:"item.supplier_full_name",fn:function(ref){
var item = ref.item;
return [_c('text-highlight',{attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(item.supplier_full_name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{staticClass:"mt-1 mb-1 mr-3",staticStyle:{"cursor":"pointer"},attrs:{"small":"","text-color":"white","color":item && item.status ? item.status.color : 'white'}},[_vm._v(" "+_vm._s(item && item.status ? item.status.name : '')+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [((_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate) || _vm.roles.includes(_vm.userRoleIdPicker)) && item.status_id != _vm.statusIdSupplied)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_vm._v(" PICK ")]):_vm._e(),((_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate) || _vm.roles.includes(_vm.userRoleIdRequester)) && item.status_id != _vm.statusIdPartiallySupplied && item.status_id != _vm.statusIdSupplied)?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.openEdit(item.id)}}},[_vm._v(" EDIT ")]):_vm._e(),(_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate) || _vm.roles.includes(_vm.userRoleIdRequester))?_c('v-btn',{staticClass:"ml-1 mt-1 mb-1 mr-1",attrs:{"color":"error","outlined":"","small":""},on:{"click":function($event){return _vm.softDelete(item.id)}}},[_vm._v(" DELETE ")]):_vm._e(),(_vm.roles.includes(_vm.userRoleIdAdmin) || _vm.roles.includes(_vm.userRoleIdAssociate) || _vm.roles.includes(_vm.userRoleIdRequester))?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 mb-1",staticStyle:{"border-radius":"7px","width":"28px !important","height":"28px !important"},attrs:{"elevation":"0","fab":"","outlined":"","x-small":"","tile":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-3"},[_c('v-list-item',{on:{"click":function($event){return _vm.toggle(item)}}},[_c('v-list-item-title',{style:("color: purple; font-weight: 600;")},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pt-4"},[_vm._v(" "+_vm._s(item.is_archived === 0 ? 'ARCHIVE' : 'ACTIVATE')+" ")])],1)],1)],1)],1)],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('part-pick-list-form',{ref:"partForm",on:{"success":_vm.success}}),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
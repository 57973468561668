import moment from 'moment';

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export const getFileTimestamp = () => moment().format('YYYY-MM-DD_HH-mm-ss');

export const getFileTimestamp2 = () => moment().format('YYYYMMDDHHmmss');

export const currentDateTimeSimple = () => moment().format('MMM Do h:mm a');

export default {
  formatPhoneNumber,
  getFileTimestamp,
  currentDateTimeSimple,
};

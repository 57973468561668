<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :submit-btn-text="'Submit'"
    :full-screen="true"
    persistent
  >
    <template slot="header">
      <span>{{ item.id ? '' : 'Create' }} Part Pick List {{ item.id ? `#${item.id}` : '' }}</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          class="pb-0"
          cols="4"
        >
          <v-autocomplete
            v-model="item.project_id"
            :items="projects"
            item-value="id"
            item-text="name"
            label="Project"
            :rules="[(v) => !!v || 'Project is required']"
            :disabled="!roles.includes(userRoleIdAdmin) && !roles.includes(userRoleIdAssociate) && !roles.includes(userRoleIdRequester)"
          >
            <template #label>
              Project<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="pb-0"
          cols="4"
        >
          <v-autocomplete
            v-model="item.department_id"
            :items="departments"
            item-value="id"
            label="Department"
            item-text="name"
            :rules="[(v) => !!v || 'Department is required']"
            :disabled="!roles.includes(userRoleIdAdmin) && !roles.includes(userRoleIdAssociate) && !roles.includes(userRoleIdRequester)"
          >
            <template #label>
              Department<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-spacer />
        <v-col
          v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && item.status_id != statusIdPartiallySupplied && item.status_id != statusIdSupplied"
          class="text-end mt-4 pb-0"
        >
          <v-btn
            color="primary"
            outlined
            @click="add"
          >
            <v-icon
              small
              class="mr-1"
            >
              fas fa-plus
            </v-icon>
            New Part
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <v-card
            elevation="0"
            outlined
          >
            <v-data-table
              :headers="headers"
              :items="item.items"
              hide-default-footer
              dense
              item-key="index"
              :loading="loading"
            >
              <template #[`item.inventory_tag`]="{ item }">
                <span><span style="font-weight: bold;">{{ item.inventory_tag ? getQRCode(item.inventory_tag) : '' }}</span> - {{ item.inventory_tag ? getItemName(item.inventory_tag) : '' }}</span>
              </template>
              <template #[`item.quantity`]="{ item }">
                <span>{{ item.quantity }}</span>
              </template>
              <template #[`item.photo`]="{ item }">
                <a
                  @click="openItemShowImageForm(item.inventory_tag)"
                >
                  <v-img
                    :src="convertedPhoto(item.inventory_tag)"
                    class="profile-photo"
                  />
                </a>
              </template>
              <template #[`item.status`]="{ item }">
                <v-chip
                  class="mt-1 mb-1 mr-3"
                  small
                  text-color="white"
                  style="cursor: pointer;"
                  :color="item.is_supplied ? '#9C27B0' : '#F57F17'"
                >
                  {{ item.is_supplied ? 'SUPPLIED' : 'NOT SUPPLIED' }}
                </v-chip>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdPicker)) && item.id"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="pick(item)"
                >
                  PICK
                </v-btn>
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && item.status_id != statusIdPartiallySupplied && item.status_id != statusIdSupplied"
                  class="ml-1 mt-1 mb-1"
                  color="primary"
                  outlined
                  small
                  @click="openEditItem(item.index)"
                >
                  EDIT
                </v-btn>
                <v-btn
                  v-if="(roles.includes(userRoleIdAdmin) || roles.includes(userRoleIdAssociate) || roles.includes(userRoleIdRequester)) && item.status_id != statusIdPartiallySupplied && item.status_id != statusIdSupplied"
                  class="ml-1 mt-1 mb-1"
                  color="error"
                  outlined
                  small
                  @click="deleteItem(item.index)"
                >
                  DELETE
                </v-btn>
              </template>
              <template slot="body.append">
                <tr>
                  <th />
                  <th class="text-end">
                    <span style="color: #000000; font-weight: bold; font-size: 14px;">Totals:</span>
                  </th>
                  <th>
                    <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ getTotalRequired() }}</span>
                  </th>
                  <th>
                    <span style="color: #000000; font-weight: bold; font-size: 14px;">{{ getTotalSupplied() }}</span>
                  </th>
                  <th />
                  <th />
                  <th />
                  <th />
                  <th />
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <part-pick-list-item-form
        ref="itemForm"
        @success="save"
      />
      <part-pick-list-item-view-form
        ref="itemViewForm"
        @success="get(item.id)"
      />
      <confirm-form ref="confirmForm" />
      <inventory-item-show-image-form
        ref="itemShowImageForm"
      />
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PartPickListItemForm from '@/components/forms/partPickLists/PartPickListItemForm';
import PartPickListItemViewForm from '@/components/forms/partPickLists/PartPickListItemViewForm';
import InventoryItemShowImageForm from '@/components/forms/InventoryItemShowImageForm';

export default {
  components: {
    PartPickListItemForm,
    PartPickListItemViewForm,
    InventoryItemShowImageForm,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      project_id: null,
      department_id: null,
      items: [],
    },
    departments: [],
    projects: [],
    editIndex: null,
    qrCodes: [],
    inventoryClassIdParts: 11,
    roles: [],
    userRoleIdAdmin: 3,
    userRoleIdAssociate: 6,
    userRoleIdRequester: 15,
    userRoleIdPicker: 23,
    loading: false,
    statusIdNew: 1,
    statusIdPartiallySupplied: 2,
    statusIdSupplied: 3,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters([

    ]),
    headers() {
      const items = [];

      items.push({ text: 'Image', value: 'photo', width: '5%' });
      items.push({ text: 'Item', value: 'inventory_tag', width: '25%' });
      items.push({ text: 'Qty', value: 'quantity_required', width: '5%' });
      items.push({ text: 'Qty Supplied', value: 'quantity_supplied', width: '10%' });
      items.push({ text: 'Requested At', value: 'created_at', width: '8%' });
      items.push({ text: 'Requested By', value: 'user_full_name', width: '8%' });
      items.push({ text: 'Supplied At', value: 'supplied_at', width: '8%' });
      items.push({ text: 'Supplied By', value: 'supplied_by_full_name', width: '8%' });
      items.push({ text: 'Status', value: 'status', width: '8%' });
      items.push({
        text: 'Actions', value: 'actions', align: 'end', width: '15%',
      });

      return items;
    },
  },
  watch: {

  },
  created() {
    this.roles = this.user.roles.map((x) => x.id);
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      this.loading = true;
      this.item = { ...this.itemDto };
      const submitModel = {
        filters: {
          id,
          include_items: true,
        },
      };
      const res = await this.$api.partPickList.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      this.loading = false;
      if (!res || !res.data) {
        this.item = { ...this.itemDto };
        return;
      }
      const firstIndex = 0;
      this.item = res.data[firstIndex];
      if (this.item.items.length > 0) {
        this.item.items.forEach((item, index) => {
          // eslint-disable-next-line no-param-reassign
          item.index = index;
        });
      }
    },

    async getProjects() {
      this.loading = true;
      const submitModel = {
        filters: {

        },
      };
      const res = await this.$api.project.read(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) { return; }
      if (!res || !res.data) {
        this.projects = [];
        return;
      }
      this.projects = res.data;
      this.loading = false;
    },

    async getDepartments() {
      this.departments = await this.$api.department.get();
    },

    async submit() {
      if (this.item.status_id === this.statusIdPartiallySupplied || this.item.status_id === this.statusIdSupplied) {
        // const res = await this.$api.partPickList.get(`supply/${this.item.id}`);
        // if (!res) {
        //   this.$bus.$emit('showError');
        //   return false;
        // }
        this.$emit('success');
        return true;
      }

      if (this.item.items.length === 0) {
        this.$bus.$emit('showError', 'Please add at least one part.');
        return false;
      }
      let res;
      if (this.item.id) {
        res = await this.$api.partPickList.update(this.item);
      } else {
        res = await this.$api.partPickList.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    async supply() {
      const res = await this.$api.partPickList.get(`supply/${this.item.id}`);
      if (!res) {
        this.$bus.$emit('showError');
        return;
      }

      this.$bus.$emit('showSuccess');
    },

    open(id) {
      if (id) {
        this.item.id = id;
        this.get(id);
      } else {
        this.item = { ...this.itemDto };
        this.item.items = [];
      }
      this.$refs.dialogBox.open();
      this.getProjects();
      this.getDepartments();
      this.getQrCodes();
    },

    add() {
      this.editIndex = this.item.items.length;
      this.$refs.itemForm.open({
        id: 0,
        inventory_tag: null,
        quantity_required: null,
        index: this.item.items.length,
      });
    },

    save(item) {
      if (!item) return;
      const existingItem = this.item.items.find((x) => x.inventory_tag === item.inventory_tag);
      if (existingItem) {
        this.$bus.$emit('showError', 'Item already exists.');
        return;
      }
      this.$set(this.item.items, this.editIndex, item);
      this.editIndex = null;
    },

    openEditItem(index) {
      const item = this.item.items[index];
      if (!item) return;
      this.editIndex = index;
      this.$refs.itemForm.open(item);
    },

    async deleteItem(index) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      const itemIndex = this.item.items.findIndex((x) => x.index === index);
      if (itemIndex === -1) return;
      this.item.items.splice(itemIndex, 1);
    },

    getQRCode(qrCode) {
      // eslint-disable-next-line eqeqeq
      const item = this.qrCodes.find((x) => x.inventory_tag == qrCode);
      if (!item) return '';
      return item.inventory_tag;
    },

    getItemName(qrCode) {
      // eslint-disable-next-line eqeqeq
      const item = this.qrCodes.find((x) => x.inventory_tag == qrCode);
      if (!item) return '';
      return item.item_full_name;
    },

    async getQrCodes() {
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassIdParts,
        },
        includes: {
          is_include_item_image: true,
          is_include_supplier_label: true,
        },
      };
      const res = await this.$api.inventoryTag.readData(readModel);

      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.qrCodes = [];
        return;
      }
      this.qrCodes = res.data;
    },

    pick(item) {
      this.$refs.itemViewForm.open(item);
    },

    getTotalRequired() {
      let total = 0;
      this.item.items.forEach((item) => {
        if (item.quantity_required) total += parseInt(item.quantity_required, 10);
      });
      return total;
    },

    getTotalSupplied() {
      let total = 0;
      this.item.items.forEach((item) => {
        if (item.quantity_supplied) total += parseInt(item.quantity_supplied, 10);
      });
      return total;
    },

    openItemShowImageForm(qrCode) {
      const item = this.qrCodes.find((x) => x.inventory_tag === qrCode);
      if (!item || !item.item) return;
      this.$refs.itemShowImageForm.openDialog(item.item.photo, item.item.mimeType, qrCode);
    },

    convertedPhoto(qrCode) {
      const item = this.qrCodes.find((x) => x.inventory_tag === qrCode);
      if (!item || !item.item) return '';

      return `data:${item.item.mimeType};base64, ${item.item.photo}`;
    },
  },
};
</script>

<style scoped>
.profile-photo {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  display: inline-block;
}
</style>

<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="1000"
    :submit-btn-text="!isNew ? 'Update' : 'Create'"
  >
    <template slot="header">
      <span>{{ !isNew ? 'Update' : 'Create' }} Inventory PO Item</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col
          md="3"
          sm="2"
          class="pb-0"
        >
          <inventory-class-select-element
            v-model="inventoryClassId"
            :is-add-all="false"
            :is-required="true"
            :is4500="inventoryClassId === class4500Id"
            :is4800="inventoryClassId === class4800Id"
            :is-railings="inventoryClassId === classRailingsId"
            :is-inst="inventoryClassId === classInstId"
            :is-glass="inventoryClassId === classGlassId"
            :is-commercial="inventoryClassId === classCommercialId"
            :is-vwd-glass="inventoryClassId === classVWDGlass"
            :is-panels="inventoryClassId === classPanelsId"
            :is-sid="inventoryClassId === classSidgId"
            :is-rail-part="inventoryClassId === classPanelPartsId"
            :is-parts="inventoryClassId === classPartsId"
          />
        </v-col>
        <v-col
          md="6"
          sm="8"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.inventory_item_id"
            :items="inventoryItems"
            item-value="id"
            :loading="loadingInventoryItem"
            :item-text="
              (item) => item.material_id + ': ' + item.name
            "
            :rules="[(v) => !!v || 'Inventory Item is required']"
            no-data-text="Select a Class first"
          >
            <template slot="label">
              Inventory Item<RedAsterisk />
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.classes ? item.classes.color : 'white'"
              >
                {{ item && item.classes ? item.classes.name : '' }}
              </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <v-chip
                class="mt-1 mb-1 mr-3"
                small
                text-color="white"
                style="cursor: pointer;"
                :color="item && item.classes ? item.classes.color : 'white'"
              >
                {{ item && item.classes ? item.classes.name : '' }}
              </v-chip><span style="font-weight: bold;">{{ item.material_id }}</span><span>{{ ': ' + item.name }}</span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          md="3"
          sm="6"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.uom_id"
            :items="uoms"
            auto-select-first
            item-text="name"
            item-value="id"
            :disabled="!item.inventory_item_id"
            :loading="loadingUom"
            :rules="[(v) => !!v || 'UOM is required']"
          >
            <template slot="label">
              UOM<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="3"
          md="3"
          sm="6"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.color_id"
            :items="colors"
            item-text="code"
            item-value="id"
            :clearable="true"
            :loading="loadingColors"
          >
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #selection="{ item }">
              <span>
                {{ item.code }}
              </span>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template #item="{ item }">
              <span>
                {{ item.code }}
              </span>
            </template>
            <template slot="label">
              Color
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="3"
          md="3"
          sm="6"
          class="pb-0"
        >
          <v-autocomplete
            v-model="item.location_id"
            :items="locations"
            auto-select-first
            item-text="name"
            item-value="id"
            :loading="loadingLocations"
            :rules="[(v) => !!v || 'Ship To is required']"
          >
            <template slot="label">
              Ship To<RedAsterisk />
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="2"
          md="2"
          sm="6"
          class="pb-0"
        >
          <v-text-field
            v-model="item.quantity"
            :rules="[(v) => !!v || 'Quantity is required']"
            type="number"
          >
            <template slot="label">
              Quantity<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="4"
          md="4"
          sm="6"
          class="pb-0"
        >
          <v-text-field
            v-model="item.price"
            :rules="[(v) => !!v || 'Unit Price is required']"
            type="number"
            :suffix="`Ontario HST 13%: $${(item.price * 0.13)}`"
          >
            <template slot="label">
              Unit Price<RedAsterisk />
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0"
        >
          <span style="font-size: 18px;">Note</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pt-0"
        >
          <v-textarea
            v-model="item.note"
            outlined
            rows="3"
          />
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      inventory_item_id: null,
      color_id: null,
      uom_id: null,
      quantity: null,
      price: null,
      location_id: null,
      note: null,
      temp_id: null,
    },
    isInit: false,
    inventoryClassId: null,
    loadingInventoryItem: false,
    loadingUom: false,
    loadingColors: false,
    loadingLocations: false,
    uoms: [],
    colors: [],
    lengths: [],
    locations: [],
    inventoryItems: [],
    class4500Id: 1,
    class4800Id: 2,
    classRailingsId: 3,
    classInstId: 4,
    classGlassId: 5,
    classCommercialId: 6,
    classVWDGlass: 7,
    classPanelsId: 8,
    classSidgId: 9,
    classPanelPartsId: 10,
    classPartsId: 11,
    isNew: false,

  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_item_id': function () {
      this.getUoms();
    },
  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get(id) {
      if (!id) return;
      const submitModel = {
        filters: {
          id,
        },
      };
      const res = await this.$api.inventoryPoItem.read(submitModel);
      if (!res || res.length < 1 || !res[0]) return;
      const firstIndex = 0;
      this.item = res[firstIndex];
    },

    async getUoms() {
      const submitData = {
        filters:
        {
          inventory_item_id: this.item.inventory_item_id,
        },
      };
      this.uoms = await this.$api.uom.read(submitData);
    },

    async getColors() {
      this.loadingColors = true;
      const submitModel = {
        filters: {
        },
      };

      this.colors = await this.$api.color.read(submitModel);
      this.loadingColors = false;
    },

    async getInventoryItems() {
      this.loadingInventoryItem = true;
      this.inventoryItems = [];
      const readModel = {
        filters:
        {
          inventory_class_id: this.inventoryClassId,
        },
      };

      const res = await this.$api.inventoryItem.readData(readModel);
      this.loadingInventoryItem = false;
      const cancelCode = 3001;
      if (res === cancelCode) return;
      if (!res || !res.data || res.data.length === 0) {
        this.inventoryItems = [];
        return;
      }
      this.inventoryItems = res.data;
    },

    async getLocations() {
      this.loadingLocations = true;
      this.locations = await this.$api.location.getList();
      this.loadingLocations = false;
    },

    async submit() {
      const inventoryItem = this.inventoryItems.find((x) => x.id === this.item.inventory_item_id);
      if (inventoryItem) {
        this.item.item_code = inventoryItem.material_id;
        this.item.item_name = inventoryItem.name;
      }

      const color = this.colors.find((x) => x.id === this.item.color_id);
      if (color) {
        this.item.color_code = color.code;
      }

      const uom = this.uoms.find((x) => x.id === this.item.uom_id);
      if (uom) {
        this.item.uom_name = uom.name;
      }

      const location = this.locations.find((x) => x.id === this.item.location_id);
      if (location) {
        this.item.location_name = location.name;
      }

      this.item.total = this.item.quantity * this.item.price;
      this.$bus.$emit('showSuccess');
      this.$emit('success', { ...this.item });
      return true;
    },

    open(item, inventoryClassId) {
      this.isInit = true;
      this.$refs.dialogBox.open();
      if (item) {
        this.item = { ...item };
        this.isNew = false;
      } else {
        this.item = { ...this.itemDto };
        this.item.temp_id = Date.now();
        this.isNew = true;
        this.$nextTick(async () => {
          this.isInit = false;
        });
      }
      this.inventoryClassId = inventoryClassId;
      this.getInventoryItems();
      this.getColors();
      this.getUoms();
      this.getLocations();
    },
  },
};
</script>

<template>
  <the-dialog-box
    ref="dialogBox"
    :submit="submit"
    :max-width="500"
    :submit-btn-text="'Apply'"
  >
    <template slot="header">
      <span>Column Setup</span>
    </template>
    <template slot="body">
      <v-row>
        <v-col>
          <v-card
            elevation="0"
            outlined
            class="pa-6"
          >
            <v-row>
              <v-col
                cols="3"
                class="pr-0"
              >
                <span style="font-size: 16px;">Display</span>
              </v-col>
              <v-col
                cols="5"
                class="pl-0"
              >
                <span style="font-size: 16px;">Column Name</span>
              </v-col>
              <v-col
                cols="4"
              >
                <span style="font-size: 16px;">Column Width</span>
              </v-col>
            </v-row>

            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_inventory_tag"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">QR Code</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.inventory_tag_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>

            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_inventory_item"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Inventory Item</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.inventory_item_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_color"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Colour</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.color_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_length"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Length</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.length_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_uom"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">UOM</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.uom_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_location"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Location</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.location_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_shelf"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Shelf</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.shelf_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_size"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Size</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.size_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_hinge"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Hinge</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.hinge_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_swing"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Swing</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.swing_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_lite"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Lite</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.lite_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_in_color"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">In Color</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.in_color_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_ex_color"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Ex Color</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.ex_color_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_batch"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Vendor Batch#</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.batch_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_prod_date"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Production Date</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.prod_date_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_po"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">PO#</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.po_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_quantity_received"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">ERP Received</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.quantity_received_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_quantity_stl"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">ERP STL</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.quantity_stl_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_quantity_on_hand"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">ERP On Hand</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.quantity_on_hand_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_created"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Created Date</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.created_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            <v-row
              align="center"
              style="border-top: 1px solid rgba(0,0,0,0.2);"
            >
              <v-col
                cols="3"
                class="pt-2 pr-0"
              >
                <v-checkbox
                  v-model="item.is_updated"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col
                cols="5"
                class="pt-4 pl-0"
              >
                <span style="font-size: 18px; color: #000;">Updated Date</span>
              </v-col>
              <v-col
                cols="4"
                class="pt-4"
              >
                <v-text-field
                  v-model="item.updated_width"
                  type="number"
                  solo
                  hide-details
                  class="pt-0"
                  style="border: 1px solid rgba(0,0,0,0.6);"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </the-dialog-box>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  components: {

  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      is_inventory_tag: 1,
      inventory_tag_width: 5,
      is_inventory_item: 1,
      inventory_item_width: 17,
      is_color: 1,
      color_width: 15,
      is_length: 1,
      length_width: 5,
      is_uom: 1,
      uom_width: 5,
      is_location: 1,
      location_width: 4,
      is_shelf: 1,
      shelf_width: 8,
      is_quantity_received: 1,
      quantity_received_width: 5,
      is_quantity_stl: 1,
      quantity_stl_width: 5,
      is_quantity_on_hand: 1,
      quantity_on_hand_width: 5,
      is_po: 1,
      po_width: 4,
      is_created: 1,
      created_width: 10,
      is_updated: 1,
      updated_width: 10,
      is_size: 1,
      size_width: 8,
      is_supplier_label: 1,
      supplier_label_width: 3,
      is_hinge: 1,
      hinge_width: 8,
      is_swing: 1,
      swing_width: 8,
      is_lite: 1,
      lite_width: 8,
      is_ex_color: 1,
      ex_color_width: 8,
      is_in_color: 1,
      in_color_width: 8,
      is_batch: 1,
      batch_width: 8,
      is_prod_date: 1,
      prod_date_width: 8,

    },
  }),
  computed: {
    ...mapState({

    }),
    ...mapGetters([

    ]),
  },
  watch: {

  },
  created() {
    this.item = { ...this.itemDto };
  },
  methods: {

    async get() {
      const res = await this.$api.inventoryTagColumnSetup.get();
      if (!res || !res.id) { this.item = { ...this.itemDto }; } else { this.item = res; }
    },

    async submit() {
      if (!this.item) return false;
      let res;
      if (this.item.id) {
        res = await this.$api.inventoryTagColumnSetup.update(this.item);
      } else {
        res = await this.$api.inventoryTagColumnSetup.store(this.item);
      }

      if (!res) {
        this.$bus.$emit('showError');
        return false;
      }

      this.$bus.$emit('showSuccess');
      this.$emit('success');
      return res;
    },

    open() {
      this.get();
      this.$refs.dialogBox.open();
    },
  },
};
</script>

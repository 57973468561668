<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col class="mt-4">
        <h4>Glass Codes Max Quantities</h4>
      </v-col>
      <v-spacer />
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openMaxQtyForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Max Quantity
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="glassCodesHeaders"
            :items="gCodes"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            dense
          >
            <template #[`item.g_code`]="{ item }">
              <text-highlight
                style="font-size: 14px !important;"
                :queries="search"
              >
                {{ item.g_code }}
              </text-highlight>
            </template>
            <template #[`item.quantity`]="{ item }">
              <text-highlight
                style="font-size: 14px !important;"
                :queries="search"
              >
                {{ item.quantity }}
              </text-highlight>
            </template>
            <template #[`item.created_at`]="{ item }">
              <text-highlight
                style="font-size: 14px !important;"
                :queries="search"
              >
                {{ item.created_at }}
              </text-highlight>
            </template>
            <template #[`item.user_full_name`]="{ item }">
              <text-highlight
                :queries="search"
                style="font-size: 14px !important;"
              >
                {{ item.user_full_name }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="primary"
                outlined
                small
                @click="openEditMaxQtyForm(item.id)"
              >
                EDIT
              </v-btn>
              <v-btn
                class="ml-1 mt-1 mb-1"
                color="error"
                outlined
                small
                @click="deleteMaxQty(item.id)"
              >
                DELETE
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-4">
        <h4>Glass Codes</h4>
      </v-col>
      <v-spacer />
      <v-col
        class="text-end mt-4"
      >
        <v-btn
          color="primary"
          outlined
          @click="openGlassForm()"
        >
          <v-icon
            small
            class="mr-1"
          >
            fas fa-plus
          </v-icon>
          New Glass Code
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card
          elevation="0"
          outlined
        >
          <v-data-table
            :headers="headers"
            :items="items"
            :footer-props="footer"
            :items-per-page="20"
            :search="search"
            :loading="loading"
          >
            <template #[`item.activated`]="{ item }">
              <v-tooltip
                v-if="!item.is_activated"
                top
                color="black"
              >
                <template #activator="{ on }">
                  <v-icon
                    color="error"
                    dark
                    v-on="on"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </template>
                <span>IMPORTANT! Record is missing Details</span>
              </v-tooltip>
            </template>
            <template #[`item.row`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.row }}
              </text-highlight>
            </template>
            <template #[`item.col`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.col }}
              </text-highlight>
            </template>
            <template #[`item.part_number_suffix`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.part_number_suffix }}
              </text-highlight>
            </template>
            <template #[`item.awd_gas`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.awd_gas }}
              </text-highlight>
            </template>
            <template #[`item.awd_spacer`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.awd_spacer }}
              </text-highlight>
            </template>
            <template #[`item.cen_heat_treatment`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.cen_heat_treatment }}
              </text-highlight>
            </template>
            <template #[`item.center_glass`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.center_glass }}
              </text-highlight>
            </template>
            <template #[`item.double_triple`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.double_triple }}
              </text-highlight>
            </template>
            <template #[`item.ext_heat_treatment`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.ext_heat_treatment }}
              </text-highlight>
            </template>
            <template #[`item.exterior_glass`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.exterior_glass }}
              </text-highlight>
            </template>
            <template #[`item.fritted`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.fritted }}
              </text-highlight>
            </template>
            <template #[`item.glass_code`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.glass_code }}
              </text-highlight>
            </template>
            <template #[`item.int_heat_treatment`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.int_heat_treatment }}
              </text-highlight>
            </template>
            <template #[`item.interior_glass`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.interior_glass }}
              </text-highlight>
            </template>
            <template #[`item.le_surface`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.le_surface }}
              </text-highlight>
            </template>
            <template #[`item.middle_glass`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.middle_glass }}
              </text-highlight>
            </template>
            <template #[`item.sgl_heat_treatment`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.sgl_heat_treatment }}
              </text-highlight>
            </template>
            <template #[`item.single_glass`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.single_glass }}
              </text-highlight>
            </template>
            <template #[`item.spandrel_paint`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.spandrel_paint }}
              </text-highlight>
            </template>
            <template #[`item.stc`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.stc }}
              </text-highlight>
            </template>
            <template #[`item.vwd_code`]="{ item }">
              <text-highlight
                :queries="search"
              >
                {{ item.vwd_code }}
              </text-highlight>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                color="primary"
                small
                icon
                @click="openGlassEditForm(item.id)"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Record</span>
                </v-tooltip>
              </v-btn>
              <v-btn
                color="error"
                small
                icon
                @click="deleteGlass(item.id)"
              >
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >
                      fa-trash
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <glass-code-max-quantity-form
      ref="codeQtyForm"
      @success="success"
    />
    <glass-code-form
      ref="glassForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-container>
</template>

<script>
import TextHighlight from 'vue-text-highlight';
import GlassCodeMaxQuantityForm from '@/components/forms/glasses/GlassCodeMaxQuantityForm';
import GlassCodeForm from '@/components/forms/glasses/GlassCodeForm';
import { mapState } from 'vuex';

export default {
  components: {
    TextHighlight,
    GlassCodeMaxQuantityForm,
    GlassCodeForm,
  },
  data: () => ({
    items: [],
    gCodes: [],
    headers: [
      { text: '', value: 'activated', width: '1%' },
      { text: 'G Code', value: 'row' },
      { text: 'Col', value: 'col' },
      { text: 'Part Number Suffix', value: 'part_number_suffix', width: '1%' },
      { text: 'Awd Gas', value: 'awd_gas' },
      { text: 'Awd Spacer', value: 'awd_spacer' },
      { text: 'Cen Heat Treatment', value: 'cen_heat_treatment' },
      { text: 'Center Glass', value: 'center_glass' },
      { text: 'Double Triple', value: 'double_triple' },
      { text: 'Ext Heat Treatment', value: 'ext_heat_treatment' },
      { text: 'Exterior Glass', value: 'exterior_glass' },
      { text: 'Fritted', value: 'fritted' },
      { text: 'Glass Code', value: 'glass_code' },
      { text: 'Int heat Treatment', value: 'int_heat_treatment' },
      { text: 'Interior Glass', value: 'interior_glass' },
      { text: 'Le Surface', value: 'le_surface' },
      { text: 'Middle Glass', value: 'middle_glass' },
      { text: 'Sgl Heat Treatment', value: 'sgl_heat_treatment' },
      { text: 'Single Glass', value: 'single_glass' },
      { text: 'Spandel Paint', value: 'spandrel_paint' },
      { text: 'Stc', value: 'stc' },
      { text: 'VWD Code', value: 'vwd_code' },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '5%',
      },
    ],
    glassCodesHeaders: [
      { text: 'G Code', value: 'g_code' },
      { text: 'Max Quantity', value: 'quantity' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Created By', value: 'user_full_name' },
      { text: 'Actions', value: 'actions', align: 'end' },
    ],
    footer: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    loading: false,
  }),
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getGlass();
      this.getMaxQuantities();
    },

    async getGlass() {
      this.loading = true;
      this.items = [];
      this.items = await this.$api.glass.read({});
      this.loading = false;
    },

    async getMaxQuantities() {
      this.gCodes = await this.$api.glassCodeMaxQuantity.read({});
    },

    openMaxQtyForm() {
      this.$refs.codeQtyForm.open();
    },

    openEditMaxQtyForm(id) {
      this.$refs.codeQtyForm.open(id);
    },

    openGlassForm() {
      this.$refs.glassForm.open();
    },

    openGlassEditForm(id) {
      this.$refs.glassForm.open(id);
    },

    async deleteGlass(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.glass.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getGlass();
    },

    async deleteMaxQty(id) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to delete this record?',
      });
      if (!confirm) return;
      if (!id) return;

      const res = await this.$api.glassCodeMaxQuantity.delete(id);

      if (!res) {
        this.$bus.$emit('showError');
        return;
      }
      this.$bus.$emit('showSuccess');
      this.getMaxQuantities();
    },

    success() {
      this.$bus.$emit('showSuccess');
      this.getMaxQuantities();
      this.getGlass();
    },
  },
};
</script>

<style scoped>
span
{
  font-size: 11px !important;
}
</style>

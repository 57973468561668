var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"padding":"10px !important","border-radius":"0px !important"}},[_c('v-row',[_c('v-col',{staticClass:"headline"},[_vm._v(" Add QR Code to Paint Purchase Order ")]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeForm}},[_c('v-icon',{attrs:{"large":"","dark":""}},[_vm._v(" mdi-close ")])],1)],1)],1),_c('v-row',{style:(("background-color: " + (_vm.hexToRgb('#E0E0E0')) + "; margin:10px 0 0 0")),attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"headline"},[_vm._v("QR Code: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.qrCode))])])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"headline"},[_vm._v("Color: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.inventoryTag ? _vm.inventoryTag.color_code : ''))])])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"headline"},[_vm._v("Location: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.inventoryTag ? _vm.inventoryTag.location_name : ''))])])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"3"}},[_c('span',{staticClass:"headline"},[_vm._v("Shelf: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.inventoryTag ? _vm.inventoryTag.inventory_location_name : ''))])])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-form',{ref:"form"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.purchaseOrderItems,"hide-default-footer":"","loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.class",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item && item.class ? item.class.color : '',"text-color":"white"}},[_vm._v(" "+_vm._s(item && item.class ? item.class.name : 'All')+" ")])]}},{key:"item.item_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item_name ? item.item_name : '')+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity ? item.quantity_required : '')+" ")]}},{key:"item.quantity_remained",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.quantity_remained))])]}},{key:"item.quantity_fulfilled",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Math.abs(item.quantity_required - item.quantity_remained)))])]}},{key:"item.quantity_on_hand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.inventoryTag ? _vm.inventoryTag.quantity_on_hand : '')+" ")]}},{key:"item.length_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.length_name ? item.length_name : '')+" ")]}},{key:"item.uom_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.uom_name ? item.uom_name : '')+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.note ? item.note : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',{staticClass:"pt-7 text-start pl-0"},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"success","outlined":""},on:{"click":function($event){return _vm.getInFull(item)}}},[_vm._v(" GET IN FULL ")])],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"auto"}},[_c('v-form',{ref:"quantityForm"},[_c('v-text-field',{staticStyle:{"max-width":"100px"},attrs:{"type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})],1)],1),_c('v-col',{staticClass:"pt-7 pr-5",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"ml-1 mt-1 mb-1",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.submit(item)}}},[_vm._v(" Add ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-end pt-7"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"normal"},on:{"click":_vm.closeForm}},[_vm._v(" Close ")])],1)],1)],1)],1),_c('confirm-form',{ref:"confirmForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      scrollable
      persistent
    >
      <v-form
        ref="form"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>
                <span class="headline">{{ item.po_number ? 'Update' : 'Create' }} Inventory PO </span> <span
                  v-if="item.po_number"
                  style="font-weight: bold; font-size: 24px;"
                >#{{ item.po_number }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col
                cols="2"
              >
                <inventory-class-select-element
                  v-model="item.inventory_class_id"
                  :is-required="true"
                  :is-add-all="false"
                  :is4500="false"
                  :is4800="false"
                  :is-railings="false"
                  :is-inst="false"
                  :is-glass="false"
                  :is-commercial="false"
                  :is-vwd-glass="false"
                  :is-panels="false"
                  :is-sid="false"
                  :is-rail-part="false"
                />
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="item.supplier_id"
                  label="Supplier"
                  :items="suppliers"
                  :rules="[(v) => !!v || 'Supplier is required']"
                  :item-text="item => `${item.code} ${item.name}`"
                  item-value="id"
                  clearable
                  :single-select="false"
                >
                  <template #selection="{ item }">
                    <span>
                      <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span>
                      <span class="font-weight-bold">{{ item.code }}</span> {{ item.name }}
                    </span>
                  </template>
                  <template slot="label">
                    Supplier<RedAsterisk />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="item.job_id"
                  label="Job"
                  :items="jobs"
                  :rules="[(v) => !!v || 'Job is required']"
                  :item-text="item => `${item.code} ${item.name}`"
                  item-value="id"
                  clearable
                  :single-select="false"
                  :loading="jobsLoading"
                >
                  <template #selection="{ item }">
                    <span>
                      <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }} <span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                    </span>
                  </template>

                  <template #item="{ item }">
                    <span>
                      <span style="font-weight: bold;">{{ item.code ? `${item.code}` : '' }}</span> - {{ item.name ? item.name : '' }}<span style="color: #1976D2;">{{ item.reference ? `; ${item.reference}` : '' }}</span>
                    </span>
                  </template>
                  <template slot="label">
                    Job<RedAsterisk />
                  </template>
                  <template #append-outer>
                    <v-btn
                      class="mrp-btn-add"
                      color="primary"
                      outlined
                      small
                      @click="openJobForm"
                    >
                      <v-icon small>
                        fas fa-plus
                      </v-icon>
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="text-end">
                <v-btn
                  color="primary"
                  outlined
                  :disabled="!item.job_id || !item.supplier_id || !item.inventory_class_id"
                  @click="openItem"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    fas fa-plus
                  </v-icon>
                  New Item
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="pt-0"
              >
                <v-card
                  elevation="0"
                  outlined
                >
                  <v-data-table
                    :headers="headers"
                    :items="poItems"
                    hide-default-footer
                    :items-per-page="-1"
                    dense
                    item-key="temp_id"
                  >
                    <template #[`item.part`]="{ item }">
                      <span style="font-weight: bold;">{{ item.item_code }} - {{ item.item_name }} {{ item.clone_index ? `(${item.clone_index})` : '' }}</span>
                    </template>
                    <template #[`item.price`]="{ item }">
                      ${{ parseFloat(item.price) }}
                    </template>
                    <template #[`item.subtotal`]="{ item }">
                      ${{ parseFloat(item.price * item.quantity).toFixed(2) }}
                    </template>
                    <template #[`item.tax`]="{ item }">
                      ${{ parseFloat(item.price * item.quantity * tax).toFixed(2) }}
                    </template>
                    <template #[`item.total`]="{ item }">
                      ${{ parseFloat((item.price * item.quantity) + (item.price * item.quantity * tax)).toFixed(2) }}
                    </template>
                    <template #[`item.actions`]="{ item }">
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="purple"
                        outlined
                        small
                        @click="clone(item)"
                      >
                        Clone
                      </v-btn>
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="primary"
                        outlined
                        small
                        @click="openEdit(item)"
                      >
                        EDIT
                      </v-btn>
                      <v-btn
                        class="ml-1 mt-1 mb-1"
                        color="error"
                        outlined
                        small
                        @click="softDelete(item)"
                      >
                        DELETE
                      </v-btn>
                    </template>
                    <template slot="body.append">
                      <tr>
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th />
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">${{ parseFloat(getSubtotal()).toFixed(2) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">${{ parseFloat(getTotalTax()).toFixed(2) }}</span>
                        </th>
                        <th class="text-end pr-1">
                          <span style="color: #000000; font-weight: bold; font-size: 14px;">${{ parseFloat(getTotal()).toFixed(2) }}</span>
                        </th>
                        <th />
                      </tr>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <inventory-po-other-form
                  ref="inventoryPoOtherForm"
                  @success="success"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-end pr-0"
                  >
                    <span style="font-weight: bold; color: black; font-size: 16px;">Subtotal:</span>
                  </v-col>
                  <v-col
                    cols="1"
                    class="text-end pl-0"
                    style="min-width: 150px !important;"
                  >
                    <span style="font-weight: bold; color: black; font-size: 16px;">${{ parseFloat(getAllSubtotal()).toFixed(2) }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-end pr-0"
                  >
                    <span style="font-weight: bold; color: black; font-size: 16px;">Tax:</span>
                  </v-col>
                  <v-col
                    cols="1"
                    class="text-end pl-0"
                    style="min-width: 150px !important;"
                  >
                    <span style="font-weight: bold; color: black; font-size: 16px;">${{ parseFloat(getAllTotalTax()).toFixed(2) }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-end pr-0"
                  >
                    <span style="font-weight: bold; color: black; font-size: 20px;">Total:</span>
                  </v-col>
                  <v-col
                    cols="1"
                    class="text-end pl-0"
                    style="min-width: 150px !important;"
                  >
                    <span style="font-weight: bold; color: black; font-size: 20px;">${{ parseFloat(getAllTotal()).toFixed(2) }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="1" />
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="normal"
              class="mr-2"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              @click="submit"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <job-form
      ref="jobForm"
      :is-supervisor="false"
      :is-client="false"
      @success="jobSuccess"
    />
    <inventory-po-item-form
      ref="inventoryPOItemForm"
      @success="success"
    />
    <confirm-form ref="confirmForm" />
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import JobForm from '@/components/forms/jobs/JobForm';
import InventoryPoItemForm from '@/components/forms/inventoryPos/InventoryPoItemForm';
import InventoryPoOtherForm from '@/components/forms/inventoryPos/InventoryPoOtherForm';

export default {
  components: {
    JobForm,
    InventoryPoItemForm,
    InventoryPoOtherForm,
  },
  props: {

  },
  data: () => ({
    item: null,
    itemDto: {
      id: 0,
      po_number: null,
      inventory_class_id: null,
      job_id: null,
      supplier_id: null,
      status_id: 1,
    },
    poItems: [],
    totalItems: [{
      part: '', note: '', color_code: '', uom_name: '', location_name: '', price: '', quantity: '', subtotal: '', tax: '', total: '',
    }],
    jobs: [],
    suppliers: [],
    glassSpecifications: [],
    headers: [
      { text: 'Part', value: 'part', width: '15%' },
      { text: 'Note', value: 'note', width: '10%' },
      { text: 'Color', value: 'color_code', width: '10%' },
      { text: 'UOM', value: 'uom_name', width: '10%' },
      { text: 'Location', value: 'location_name', width: '10%' },
      {
        text: 'Unit Price', value: 'price', width: '5%', align: 'end',
      },
      {
        text: 'Quantity', value: 'quantity', width: '5%', align: 'end',
      },
      {
        text: 'Subtotal', value: 'subtotal', width: '5%', align: 'end',
      },
      {
        text: 'Tax', value: 'tax', width: '5%', align: 'end',
      },
      {
        text: 'Total', value: 'total', width: '5%', align: 'end',
      },
      {
        text: 'Actions', value: 'actions', align: 'end', width: '20%',
      },
    ],
    dialog: false,
    loading: false,
    jobsLoading: false,
    tax: 0.13,
  }),
  computed: {
    ...mapState({
    }),
    ...mapGetters([

    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'item.inventory_class_id': function () {
      this.getJobs();
    },
  },
  created() {
    this.item = { ...this.itemDto };
    this.init();
  },
  methods: {

    init() {
      this.getJobs();
      this.getSuppliers();
    },

    async submit() {
      if (!await this.$refs.form.validate()) return;
      this.$bus.$emit('loading');

      let res = null;
      let inventoryPoId = this.item.id;

      if (this.item.id) {
        res = await this.$api.inventoryPo.update(this.item);
      } else {
        res = await this.$api.inventoryPo.store(this.item);
        inventoryPoId = res;
      }

      if (!res) {
        this.$bus.$emit('showError');
        this.$bus.$emit('loading');
        return;
      }

      if (this.poItems.length > 0) {
        this.poItems.forEach((x) => {
        // eslint-disable-next-line no-param-reassign
          x.inventory_po_id = inventoryPoId;
        });

        const res2 = await this.$api.inventoryPoItem.store(this.poItems);

        if (!res2) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading');
          return;
        }
      }

      if (this.$refs.inventoryPoOtherForm) {
        const otherExpenses = this.$refs.inventoryPoOtherForm.items;
        otherExpenses.forEach((x) => {
          // eslint-disable-next-line no-param-reassign
          x.inventory_po_id = inventoryPoId;
        });

        const res3 = await this.$api.inventoryPoExpense.store(otherExpenses);

        if (!res3) {
          this.$bus.$emit('showError');
          this.$bus.$emit('loading');
          return;
        }
      }

      this.$bus.$emit('loading');
      this.$emit('success');
      this.dialog = false;
    },

    async getJobs() {
      this.jobsLoading = true;
      const submitModel = {
        filters: { inventory_class_id: this.item.inventory_class_id, is_manual: 1 },
      };
      const res = await this.$api.job.readData(submitModel);
      const cancelCode = 3001;
      if (res === cancelCode) return;
      this.jobs = res;
      this.jobsLoading = false;
    },

    async getSuppliers() {
      this.suppliers = await this.$api.supplier.get();
    },

    async open(id, inventoryClassId) {
      this.$bus.$emit('loading');
      this.poItems = [];
      if (this.$refs.form) this.$refs.form.reset();
      this.loading = true;
      if (id) {
        const submitModel = {
          filters: {
            id,
          },
        };
        const res = await this.$api.inventoryPo.read(submitModel);
        const cancelCode = 3001;
        if (res === cancelCode) return;
        this.loading = false;
        if (!res || !res.data || res.data.length === 0) {
          this.poItems = [];
          this.$bus.$emit('loading');
          return;
        }
        const firstIndex = 0;
        this.item = res.data[firstIndex];

        const submitModel2 = {
          filters: {
            inventory_po_id: id,
          },
        };
        const res2 = await this.$api.inventoryPoItem.read(submitModel2);
        if (!res2) {
          this.poItems = [];
          this.$bus.$emit('loading');
          return;
        }
        this.poItems = res2;

        this.$nextTick(async () => {
          if (this.$refs.inventoryPoOtherForm) {
            this.$refs.inventoryPoOtherForm.get(id);
            this.$refs.inventoryPoOtherForm.isOpen = true;
          }
        });
      } else {
        this.item = { ...this.itemDto };
        this.item.inventory_class_id = inventoryClassId;
        this.poItems = [];
        this.$nextTick(async () => {
          if (this.$refs.inventoryPoOtherForm) {
            this.$refs.inventoryPoOtherForm.items = [];
          }
        });
      }

      this.$bus.$emit('loading');
      this.dialog = true;
    },

    openItem() {
      this.$refs.inventoryPOItemForm.open(null, this.item.inventory_class_id);
    },

    openEdit(item) {
      this.$refs.inventoryPOItemForm.open(item, this.item.inventory_class_id);
    },

    async softDelete(item) {
      const confirm = await this.$refs.confirmForm.open({
        title: 'Are you sure that you want to remove this Glass Item?',
      });
      if (!confirm) return;
      const index = this.poItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index >= 0) {
        this.poItems.splice(index, 1);
      }
    },

    async openJobForm() {
      const res = await this.$api.job.get('generate-manual-code');
      if (!res) return;
      this.$refs.jobForm.open();
      this.$nextTick(() => {
        this.$refs.jobForm.item.code = res;
        this.$refs.jobForm.item.is_manual = 1;
      });
    },

    async jobSuccess() {
      await this.getJobs();
      const latestItemId = Math.max(...this.jobs.map((x) => x.id));
      if (latestItemId) { this.item.job_id = latestItemId; }
    },

    success(item) {
      const index = this.poItems.findIndex((x) => x.temp_id === item.temp_id);
      if (index >= 0) {
        this.poItems.splice(index, 1, item);
      } else {
        this.poItems.push(item);
      }
    },

    async clone(item) {
      if (!item.clone_index) {
        // eslint-disable-next-line no-param-reassign
        item.clone_index = 1;
      }

      const newItem = { ...item };
      newItem.temp_id = Date.now();
      newItem.clone_index += 1;
      this.poItems.push(newItem);
      console.log(this.poItems);
    },

    getSubtotal() {
      let subtotal = 0;
      this.poItems.forEach((item) => {
        subtotal += item.price * item.quantity;
      });
      return subtotal;
    },

    getTotal() {
      let total = 0;
      this.poItems.forEach((item) => {
        total += (item.price * item.quantity) + (item.price * item.quantity * this.tax);
      });
      return total;
    },

    getTotalTax() {
      let totalTax = 0;
      this.poItems.forEach((item) => {
        totalTax += item.price * item.quantity * this.tax;
      });
      return totalTax;
    },

    getAllTotal() {
      const othersTotal = this.$refs.inventoryPoOtherForm ? this.$refs.inventoryPoOtherForm.getTotal() : 0;
      const itemsTotal = this.getTotal();
      return othersTotal + itemsTotal;
    },

    getAllTotalTax() {
      const othersTotalTax = this.$refs.inventoryPoOtherForm ? this.$refs.inventoryPoOtherForm.getTotalTax() : 0;
      const itemsTotalTax = this.getTotalTax();
      return othersTotalTax + itemsTotalTax;
    },

    getAllSubtotal() {
      const othersSubtotal = this.$refs.inventoryPoOtherForm ? this.$refs.inventoryPoOtherForm.getSubtotal() : 0;
      const itemsSubtotal = this.getSubtotal();
      return othersSubtotal + itemsSubtotal;
    },
  },
};
</script>

<style scoped>

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td
{
  padding-right: 4px !important;
}
</style>
